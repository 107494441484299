import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  constructor() { }

  todayClose(id) {

    var isIE=document.all;
    var isNN=!document.all&&document.getElementById;
    

    function setCookie(name, value, expiredays) {
      var todayDate = new Date();
      todayDate.setDate(todayDate.getDate() + expiredays);
      document.cookie = name + "=" + escape(value) + "; path=/; expires=" + todayDate.toUTCString() + ";"
    }


    setCookie("pop" + id, "done", 1);



    if (isIE||isNN) document.getElementById('pop'+id).style.visibility="hidden";

  }

  popClose(id){
    document.getElementById('pop'+id).style.visibility="hidden";
  }

  ngOnInit() {
    var cookiedata = document.cookie;

    
    for(var i = 0; i<cookiedata.length; i++){
      if(cookiedata.indexOf("pop"+i+"=done") < 0 ) {
        //document.all['divpop'+i].style.visibility = "visible";
      }else{
        document.all['pop'+i].style.visibility = "hidden";
      }
    }
      
    
  }

}
