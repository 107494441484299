import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { VisualComponent } from './visual/visual.component';
import { FooterComponent } from './footer/footer.component';
import { CommonHttpService } from '../services/common-http.service';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { PopupComponent } from './popup/popup.component';
import { FloatingBannerComponent } from './floating-banner/floating-banner.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';

@NgModule({
  declarations: [
    HeaderComponent,
    VisualComponent,
    FooterComponent,
    PopupComponent,
    FloatingBannerComponent,
    SidebarComponent,
    BreadcrumbsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  exports: [
    HeaderComponent,
    VisualComponent,
    FooterComponent,
    PopupComponent,
    FloatingBannerComponent,
    SidebarComponent,
    BreadcrumbsComponent
  ],
  providers: [CommonHttpService],
})
export class LayoutsModule { }
