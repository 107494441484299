import { Component, OnInit } from '@angular/core';

declare var $: any;
declare var daum: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var container = document.getElementById('map'); //지도를 담을 영역의 DOM 레퍼런스
    var options = { //지도를 생성할 때 필요한 기본 옵션
      center: new daum.maps.LatLng(35.180313, 129.110650), //지도의 중심좌표. ,  

      level: 3, //지도의 레벨(확대, 축소 정도)
      draggable: false
    };

    var map = new daum.maps.Map(container, options); //지도 생성 및 객체 리턴

    var imageSrc = '/assets/img/sub/marker.png', // 마커 이미지 경로
      imageSize = new daum.maps.Size(160, 60), // 마커 사이즈(가로,세로)
      imageOption = { offset: new daum.maps.Point(80, 60) }; // 중심점에서 마커 좌표(x,y) 혹은 (-x,-y)

    // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
    var markerImage = new daum.maps.MarkerImage(imageSrc, imageSize, imageOption),
      markerPosition = new daum.maps.LatLng(35.180313, 129.110650); // 지도에 표시 될 마커 좌표

    // 마커를 생성합니다
    var marker = new daum.maps.Marker({
      position: markerPosition,
      image: markerImage // 마커이미지 설정 
    });

    // 마커가 지도 위에 표시되도록 설정합니다
    marker.setMap(map);


    function setDraggable(draggable) {
      
      // 마우스 드래그로 지도 이동 가능여부를 설정합니다
      marker.setDraggable(draggable);
    }
  }

}
