<!-- 팝업 시작 -->
<div class="pop-wrap">

    <!-- 팝업 게시일 : 2020-11-30 유대경 -->
    <!-- <div id="pop1" class="pop">
        <div class="scroll">
            <img src="/assets/img/popup/20201208.png" alt="" usemap="#20201130">
            <map name="20201130">
                <area shape="rect" coords="427,383,613,429" href="http://www.wellageing.com/#/science-culture/1611" title="상세페이지 바로가기">
                <area shape="rect" coords="619,383,784,429" href="https://us02web.zoom.us/j/5427851620?pwd=c0hmby9UcFFrbkxXR3BDK29iNGpqZz09" title="ZOOM 바로가기" target="_blank">
            </map>
        </div>
        <div class="pop-btn">
            <button class="today-close" (click)="todayClose('1')"><i class="fas fa-check"></i> 오늘 하루 보지
                않기</button>
            <button class="pop-close" (click)="popClose('1')">닫기 <i class="fas fa-times"></i></button>
        </div>
    </div> -->

    <!-- 팝업 게시일 : 2020-12-02 유대경 -->
    <!-- <div id="pop2" class="pop" style="top:20px; left:20px;">
        <div class="scroll">
            <img src="/assets/img/popup/20201231.png" width="600" alt="">
        </div>
        <div class="pop-btn">
            <button class="today-close" (click)="todayClose('2')"><i class="fas fa-check"></i> 오늘 하루 보지
                않기</button>
            <button class="pop-close" (click)="popClose('2')">닫기 <i class="fas fa-times"></i></button>
        </div>
    </div> -->

     <!-- 팝업 게시일 : 2021-12-31 김다혜 -->
    <!-- <div id="pop1" class="pop" style="top:20px; left:20px;">
        <div class="scroll">
            <a href="http://www.wellageing.com/#/board/1196/111/1" target="_blank">
            <img src="/assets/img/popup/20220106.jpg" width="580" alt="" >
            </a>
        </div>
        <div class="pop-btn">
            <button class="today-close" (click)="todayClose('1')"><i class="fas fa-check"></i> 오늘 하루 보지
                않기</button>
            <button class="pop-close" (click)="popClose('1')">닫기 <i class="fas fa-times"></i></button>
        </div>
    </div> -->

</div>
<!-- 팝업 끝 -->