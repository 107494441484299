<div id="membership">

    <div class="account-tab">
        <button data-tab="id" class="active">아이디 찾기</button>
        <button data-tab="password">비밀번호 찾기</button>
    </div>

    <div id="id" class="form-group-container">
        <!-- 회원 이름 시작 -->
        <div class="form-group">
            <label for="m_name">성명</label>
            <div class="input-group-container">
                <div class="input-group">
                    <span></span>
                    <input type="text" id="m_name" name="m_name" data-name="이름" placeholder="성명을 입력하세요." [ngModel]="userName1" (ngModelChange)="userName1 = $event" >
                </div>
            </div>
        </div>
        <!-- 회원 이름 끝 -->

        <!-- 회원 이메일 시작 -->
        <div class="form-group">
            <label for="m_email1">이메일</label>
            <div class="input-group-container">
                <div class="input-group">
                    <input type="text" id="m_name" name="m_name" data-name="이름" placeholder="ex) test@wellageing.com" [ngModel]="email1" (ngModelChange)="email1 = $event">
                </div>
            </div>
        </div>
        <!-- 회원 이메일 끝 -->

        <div class="btn-group-center">
            <div class="di">
                <button class="btn btn-account" (click)="findId()">
                    아이디 찾기
                </button>
            </div>
        </div>
    </div>



    <div id="password" class="form-group-container">
        <!-- 회원 이름 시작 -->
        <div class="form-group">
            <label for="m_id">아이디</label>
            <div class="input-group-container">
                <div class="input-group">
                    <input type="text" id="m_id" name="m_id" data-name="이름" placeholder="아이디를 입력하세요." [ngModel]="userId2" (ngModelChange)="userId2 = $event" >
                </div>
            </div>
        </div>
        <!-- 회원 이름 끝 -->

        <!-- 회원 이름 시작 -->
        <div class="form-group">
            <label for="m_name">성명</label>
            <div class="input-group-container">
                <div class="input-group">
                    <input type="text" id="m_name" name="m_name" data-name="이름" placeholder="성명을 입력하세요." [ngModel]="userName2" (ngModelChange)="userName2 = $event">
                </div>
            </div>
        </div>
        <!-- 회원 이름 끝 -->

        <!-- 회원 이메일 시작 -->
        <div class="form-group">
            <label for="m_email1">이메일</label>
            <div class="input-group-container">
                <div class="input-group">
                    <input type="text" id="m_name" name="m_name" data-name="이름" placeholder="ex) test@wellageing.com" [ngModel]="email2" (ngModelChange)="email2 = $event" >
                </div>
            </div>
        </div>
        <!-- 회원 이메일 끝 -->


        <div class="btn-group-center">
            <div class="di">
                <button class="btn btn-account" (click)="findPw()">
                    비밀번호 찾기
                </button>
            </div>
        </div>
    </div>

    




</div>