import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonHttpService } from 'src/app/services/common-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  menus: Observable <any[]> = null;
  user: any;

  constructor(private route: ActivatedRoute,
    private commonService: CommonService) {
      
    this.route.params.subscribe(params => { 
      this.menus = this.commonService.getMenu();
      this.user = this.commonService.getSessionItem('currentUser');
    });
  }

  // 햄버거 토글 버튼
  hamburger() {
    if ($('.hamburger').hasClass('active')) {
      $('.hamburger, #mnb').removeClass('active');
      $('.dim').fadeOut(150);
    } else {
      $('.hamburger, #mnb').addClass('active');
      $('.dim').fadeIn(150);
    }
  }

  //  
  gnbEnter() {
    $('#gnb, .dim').addClass('active');
  }

  //
  gnbLeave() {
    $('#gnb, .dim').removeClass('active');
  }

  //
  gnbClose() {
    $('#gnb').removeClass('active');
    $('body,html').animate({ scrollTop: 0 }, 150);
  }

  //
  mnbClose() {
    $('#gnb, .hamburger, #mnb').removeClass('active');
    $('#mnb > ul > li > ul').slideUp(150);
    $('.dim').fadeOut(150);
    $('body,html').animate({ scrollTop: 0 }, 150);
  }


  // 모바일 토글 네비게이셔
  toggleGnb(id) {
    let element = document.getElementById(id) as HTMLElement;

    $(element).parent().addClass('active');
    $(element).parent().siblings('li').removeClass('active');
    $(element).next('ul').slideToggle(150);
    $(element).parent().siblings('li').children('ul').slideUp(150);
  }


  ngOnInit() {
    function responsivGnb() {
      $('#gnb, .dim, .hamburger').removeClass('active');
      $('#mnb > ul > li > ul').slideUp(100);
    }

    $(window).resize(function () {
      responsivGnb();

      $('dim').fadeIn();
      $('dim').removeClass('active');
    });


    $(document).mouseup(function (e) {
      // #header 외 다른 element 클릭 시 함수 호출
      var container = $("#header");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        responsivGnb();
      }
    });
    
  }

  clickMenu(menu){ 
    $('#gnb, .hamburger, #mnb').removeClass('active');
    $('#mnb > ul > li > ul').slideUp(150);
    $('.dim').fadeOut(150);
    $('body,html').animate({ scrollTop: 0 }, 150);
    this.commonService.navigateMenu(menu);
  }
}
