<div class="footer">
  <div class="fnb">
    <ul class="inner">
      <li><a href="#" [routerLink]="['/terms/1223']">이용약관</a></li>
      <li><a href="#" [routerLink]="['/privacy/1224']">개인정보처리방침</a></li>
      <li><a href="#" [routerLink]="['/sitemap/1225']">사이트맵</a></li>
    </ul>
  </div>
  <div class="inner">
    <p class="info">
      <span>부산광역시 연제구 토현로 32 4층</span>
      <span><em>전화번호</em> : <a href="tel:051-624-5025">051-624-5025</a></span>
      <span><em>팩스</em> : <a href="tel:051-751-5025">051-751-5025</a></span>
      <span><em>이메일주소</em> : wellageing12@gmail.com</span>
    </p>
    <p class="copyright">Copyright 2019 사단법인 노인생활과학연구소. ALL RIGHTS RESERVED.</p>
  </div>

  <button class="top" (click)='scrollTop();'><img src="/assets/img/common/top.png" alt=""></button>
</div>