<div class="translate-tab">
  <button data-tab="tab01" class="active">국문</button>
  <button data-tab="tab02">ENG</button>
</div>

<div id="tab01" class="translate">
  <div class="ageing-business">
    <a href="https://manoa.hawaii.edu/acap/" target="_blank">
      <span>ACAP 홈페이지</span>
    </a>
    <strong>
      아시아 태평양 액티브 에이징 컨소시엄(ACAP) <br />
      웹사이트에 오신 여러분을 환영합니다.
    </strong>

    <ul>
      <li>
        <em>
          안녕하세요.<br />
          아시아태평양 지역의 액티브 에이징에 관한 여러 정보를 얻을 수 있는
          웹사이트를 방문하신 여러분을 진심으로 환영합니다.
        </em>

        아시아 태평양 액티브 에이징 컨소시엄은 아시아 태평양 지역에 퍼져있는
        다양한 회원들로 구성되어 있으며 WHO의 의제인 활기찬 노년(Active Aging)에
        관한 각국의 연구, 정책 논의, 실행방법을 교류 협력함으로써 건강하고
        활기찬 사회를 구현하고자 합니다.<br /><br />

        세계적으로 고령화 현상이 대두되면서 우리는 더 이상 노인을
        ‘비생산적’이거나 ‘부담적’ 존재로 인식하여서는 안되며, 모든 사람들이
        연령에 상관없이 생산적으로 활동할 수 있도록 장려하여야 합니다. 이러한
        이념은 결국 고령화 사회를 살아가는 우리들에게 매우 필요한 것이라
        봅니다.<br /><br />

        수명연장과 더불어 길어진 생애에 결쳐 우리의 생산성을 유지하기 위해서는
        다음과 같은 사항이 필수적입니다.

        <ul class="bg">
          <li>
            개인과 가족은 대비하고 긍정적 실천을 행하기 위해 노력해야 한다.
          </li>
          <li>
            사회정책은 건강을 용이하게 만들 수 있는 결정들을 통하여 질좋은
            노인돌봄 실천, 고령친화적 환경을 조성할 수 있어야 한다.
          </li>
          <li>
            일자리, 교육, 자원봉사, 가족생활 등 노인이 사회에 적극 참여할 수
            있도록 사회적 규범이 변화되어야 한다.
          </li>
        </ul>

        서로의 책임을 공유함으로서 우리는 노인의 자원을 지속해서 가족, 지역사회
        및 국가에 환원할 수 있는 환경과 여건을 조성할 수 있습니다.
      </li>
      <li>
        아시아 태평양 액티브 에이징 컨소시엄 회원들은 국가와 지역을 넘어서
        의견을 공유하고 교환하면서 각국의 경험과 실천을 통해 서로 배우고 협력해
        나가고 있습니다.<br /><br />

        구체적인 사례를 들면, 한국의 혁신적인 ICT기술을 이용한 성공적인 노인
        정보화 실현, 일본의 고령친화 시장 개척을 위한 신생 사업체들의 노력,
        하와이의 노인 건강증진 프로그램 추진을 위한 전국적 정책에 대한 협력,
        아세안 국가들의 자국에 맞는 노인돌봄, 지역중심의 노력 등이 있습니다.<br /><br />

        아시아 태평양 액티브 에이징 컨소시엄 회원들은 각국에서 정해진 순서에
        따라 해마다 국제회의를 개최하고 있으며 스카이페 회의, 페이스북,
        뉴스레터, 이메일 등을 통하여 지속적으로 교류와 협력이 이어질
        것입니다.<br />
        또한 심포지움과 공식 및 비공식적 회의를 통하여 서로 협력해 나가고
        있습니다.<br />
        이제 우리는 한국 노인생활과학연구소에서 제작한 본 웹사이트를 통하여
        이러한 교류와 협력이 이어질 것입니다<br /><br />

        우리는 본 웹사이트에 방문하신 여러분이 함께 하도록 초대합니다. 차후
        아시아 태평양 액티브 에이징 회의나 모임에 대해 자세한 정보를 원하시면,
        웹사이트에 기재된 링크를 통하여 연락하시기 바랍니다. 감사합니다.

        <dl>
          <!-- <dt>
                  <img src="/assets/img/sub/aloha.png" alt="알로하, Aloha!">
                </dt> -->
          <dd>
            <img src="/assets/img/sub/doctor.jpg" alt="" /><br /><br />

            캐서린브라운 교수 (Kathryn L. Braun, Dr.Ph)<br />
            아시아 태평양 액티브 에이징 컨소시엄(ACAP)회장
          </dd>
        </dl>
      </li>
    </ul>
  </div>

  <div class="acap">
    <strong>ACAP이란?</strong>
    <img src="/assets/img/sub/acap.jpg" alt="" />
    <em>아시아 태평양 액티브 에이징 컨소시엄</em>
    ACAP(Active Aging Consortium Asia Pacific)은 아시아태평양지역의 액티브
    에이징을 연구하고 실천하는 공동체임.<br /><br />

    야마구치현대학의 오가와다케오교수가 일본 국제기금의 후원으로 2005년에
    설립하여 미국 한국 중국 등 아시아태평양지역의 다양한 교류를 전개해 오고
    있음.<br /><br />

    현재 제 2대 회장인 하와이주립대학교 캐서린브라운 교수가 회장을 맡고 있으며
    한국, 일본, 중국, 미국외에도 캐나다, 인도네시아, 말레지아, 싱가폴, 몽고,
    홍콩, 태국, 호주, 미얀마, 베트남, 인도, 필리핀 등 아시아태평양지역의
    액티브에이징을 연구실천하는 공동체들이 함께 하고 있음.
  </div>

  <div class="acap-map">
    <img src="/assets/img/sub/ageing_mission.jpg" alt="" /><br /><br /><br />

    <h6><strong>ACAP</strong> Members around the World</h6>
    <img src="/assets/img/sub/acap_map.jpg" alt="" />

    <p>
      건강하고 활기찬 노년을 위한 아시아태평양택티브에이징컨소시엄은 2002년
      마드리드에서 있었던 국제 고령자회의에서 <br class="break" />
      노인의 부정적 측면보다는긍정적 측면을 더욱 개발하여 세계적 공통 관심사인
      인구고령화 문제를 보다 <br class="break" />
      적극적으로 해결하고자 표명된 의제와 함께 하고 있음.
    </p>

    <div class="history">
      <ul>
        <li>
          <img src="/assets/img/sub/acap_history01.jpg" alt="" />
          <em>제1회 대회(2005) </em>
          일본 후쿠오카에서 최초로 개최되었으며 주제는<br class="break" />
          지역사회 발전: 고령화에 대한 교류와 준비
        </li>
        <li>
          <img src="/assets/img/sub/acap_history02.jpg" alt="" />
          <em>제2회 대회(2006) </em>
          2005년 2월에 액티브에이징:최고의 실천방향을 주제로 미국의 하와이에서
          실제,<br class="break" />
          노인에게 실천되어야 하는 다양한 프로그램들과 시설견학 등의 회의가<br
            class="break"
          />
          이루어졌으며, 2006년은 첫 프로젝트 수행이 완성되는 해로 일주일동안<br
            class="break"
          />
          일본, 한국, 중국을 잇는 회의를 진행했음
        </li>
        <li>
          <img src="/assets/img/sub/acap_history03.jpg" alt="" />
          <em>제3회 대회(2007) </em>
          한국의 몰티브라고 불리어지는 남해군에 고령사회 재설계 프로그램과
          환경을<br class="break" />
          주제로 마련되었음. 남해는 한국에서 노인인구가 가장 많은 섬이며 인구
          5만이라는<br class="break" />
          작은 섬에 서 국제회의를 유치할 수 있었으며 남해선언을 채택하기도
          하였음
        </li>
        <li>
          <img src="/assets/img/sub/acap_history04.jpg" alt="" />
          <em>제4회 대회(2008)</em>
          중국 상해 민정국에서 유치하였으며 상해노년프로그램 개발 국제컨퍼런스와
          공동개최 하였음
        </li>
        <li>
          <img src="/assets/img/sub/acap_history05.jpg" alt="" />
          <em>제5회 대회(2010) </em>
          일본 후쿠오카에서 AABC센터에서 유치하였음
        </li>
        <li>
          <img src="/assets/img/sub/acap_history06.jpg" alt="" />
          <em>제6회 대회(2011) </em>
          인도네시아 국립대학교 주최로 진행되었음
        </li>
        <li>
          <img src="/assets/img/sub/acap_history07.jpg" alt="" />
          <em>제7회 대회(2012)</em>
          말레지아 쿠알룸프와 일본 키타큐슈에서 동시 개최되었음
        </li>
        <li>
          <img src="/assets/img/sub/acap_history08.jpg" alt="" />
          <em>제8회 대회(2013)</em>
          IAGG 공식 후속회의로 국민항노화 발전 방향을 주제로 부산에서 마련됨
        </li>
        <li>
          <img src="/assets/img/sub/acap_history09.jpg" alt="" />
          <em>제9회 대회(2014)</em>
          일본 키타큐슈에서 개최되었음
        </li>
        <li>
          <img src="/assets/img/sub/acap_history10.jpg" alt="" />
          <em>제10회 대회(2016)</em>
          일본 후쿠오카에서 개최되었음
        </li>
        <li>
          <img src="/assets/img/sub/acap_history11.jpg" alt="" />
          <em>제11회 대회(2017)</em>
          홍콩 골든에이지와 함께 개최되었음
        </li>
        <li>
          <img src="/assets/img/sub/acap_history12.jpg" alt="" />
          <em>제12회 대회(2018)</em>
          인도네시아 자카르타와 족자카르타에서 개최되었음
        </li>
      </ul>
    </div>

    <p class="tac">
      각 대회의 주제는 그 시대와 지역사회에 가장 필요한 주제를 선택하여 국가,
      도시, 가족 노인의 새로운 변화를 요구하였음<br /><br />

      이를 요약하면 아래와 같음
    </p>

    <div class="tal emp">
      <ul>
        <li>
          첫째, 요양이 필요한 노인들을 위한 케어인력의 전문화, 양성프로그램
          그리고 유입과 보급관계에 있는 국가 간의 협력체구축
        </li>
        <li>둘째, 노년과 관련된 양질의 일자리 창출</li>
        <li>셋째, 긍정적 노년을 위한 교육의 중요성</li>
        <li>넷째, 과학기술의 접목을 통한 신노년</li>
        <li>다섯째, 액티브에이징을 통한 에이징 비즈니스의 활성화</li>
        <li>여섯째, 고령친화 도시를 위한 도시재생</li>
      </ul>
    </div>
  </div>
</div>

<div id="tab02" class="translate">
  <div class="ageing-business">
    <a href="https://manoa.hawaii.edu/acap/" target="_blank">
      <span>ACAP Website</span>
    </a>
    <strong>
      Welcome to our website<br />for the ACTIVE AGING CONSORTIUM ASIA PACIFIC
      (ACAP) !
    </strong>

    <ul>
      <li>
        <em>
          I welcome you to our website, where you can learn more about ACAP.
        </em>

        ACAP members come from around the Asia-Pacific region.<br />
        We are dedicated to advancing the Active Aging agenda of WHO by sharing
        research, policy ideas, and best practices for Active Aging in Asia
        Pacific.<br /><br />

        The world's population is aging. We believe that we cannot afford to
        look at elders as "nonproductive" or "burdensome" We believe that all
        people, regadless of age, should be encouraged and supported to be as
        productive as possible. In an aging society, we cannot afford to do
        otherwise.<br /><br />

        To keep us productive over are increasing lifespans, we believe that:

        <ul class="bg">
          <li>
            Individuals and families must prepare for old age and adopt positive
            practices for long life.
          </li>
          <li>
            Social policy must help “make healthy choices easy choice, elderly
            care for the better quality of life, and build age friendly
            environments.
          </li>
          <li>
            We must change societal norms to encourage and reward participation
            of elders in work, education, volunteering, and family life.
          </li>
        </ul>

        Through shared responsibility, we can assure that older adults are
        READY, ABLE, and ALLOWED to continues contributing their resources to
        families, communities, and society.
      </li>
      <li>
        As members, we come together to incubate and share ideas across the
        region.<br />
        Every country has something to share and something to learn. For
        example, South Korea has excellent programs to train older adults in
        Internet and computer-based technology, Japan is supporting new
        businesses to meet the needs of the senior market, Hawai is part of the
        nationwide initiative to promote and seek reimbursement for health
        promotion programs for older adults, efforts for family and community
        care for the elderly in ASEAN.<br /><br />

        ACAP members take turn hosting conferences in their home countries, the
        communicate by skype, newsletter and email they deliver symposia and
        hold meetings at gerontological conferences hosted by other groups.<br /><br />

        We also communicate through this Website, hosted by the Research
        Institute of Science for the Better Living of the Elderly in Busan
        Korea.<br /><br />

        We invite you to join us. For details on our next gathering, contact any
        ACAP member through the links provided in this Website.

        <dl>
          <!-- <dt>
                  <img src="/assets/img/sub/aloha.png" alt="알로하, Aloha!">
                </dt> -->
          <dd>
            <img src="/assets/img/sub/doctor.jpg" alt="" /><br /><br />

            Kathryn L. Braun, Dr.Ph<br />
            ACAP President
          </dd>
        </dl>
      </li>
    </ul>
  </div>

  <div class="acap">
    <strong>What is ACAP?</strong>
    <img src="/assets/img/sub/acap.jpg" alt="" />

    ACAP(Active Aging Consortium Asia Pacific) is a group of like-minded
    individuals from across Asia Pacific dedicated to empower older adults to
    maintain their physical, social, civic, economic, and environmental fitness
    so that they can continue to contribute to their families, their
    communities, and society.<br /><br />

    Membership is inclusive of people of all ages, abilities, and
    disciplines.<br /><br />

    The Policy Framework for Active Aging, developed at the 2002 Madrid World
    Congress on Aging, serves as a foundation for our work.<br />
    We believe that Active Aging requires an Individual-Family-Social Policy
    Partnership.<br /><br />

    <ul>
      <li>
        Individuals and families must prepare for old age and adopt positive
        practices for long life.
      </li>
      <li>
        Social policy must make healthy choices easy choices and build age
        friendly environments.
      </li>
      <li>
        Societal norms must encourage and reward participation of elders in
        work, education, volunteering, and family life.
      </li>
    </ul>

    <p>
      We believe that all people, regardless of age, should be encouraged and
      supported to be as productive as possible. In an aging society, we cannot
      afford to do otherwise.
    </p>
  </div>

  <div class="acap-map-eng">
    <img src="/assets/img/sub/ageing_mission.jpg" alt="" /><br /><br /><br />

    <h6><strong>ACAP</strong> Members around the World</h6>
    <img src="/assets/img/sub/acap_map.jpg" alt="" />

    <ol>
      <li>
        <img src="/assets/img/sub/acap_eng_history01.jpg" alt="" />
      </li>
      <li>
        <em>1st Active Aging Conference in Asia Pacific</em>
        “New Community Development: Correspondence and Preparation for Aging”<br />
        Fukuoka, Japan, February 26~28 and March 1, 2005
      </li>
    </ol>

    <ol>
      <li>
        <img src="/assets/img/sub/acap_eng_history02.jpg" alt="" />
      </li>
      <li>
        <em>2nd Active Aging Conference in Asia Pacific</em>
        “Active Aging in Asia Pacific: Showcasing Best Practices”<br />
        Honolulu, HI, February 26~28, 2006
      </li>
    </ol>

    <ol>
      <li>
        <img src="/assets/img/sub/acap_eng_history03.jpg" alt="" />
      </li>
      <li>
        <em>3rd Active Aging Conference in Asia Pacific</em>
        “Re-Designing Program and Environment for Aged Society”<br />
        Namhae, Korea, May 16~18, 2007
      </li>
    </ol>

    <ol>
      <li>
        <img src="/assets/img/sub/acap_eng_history04.jpg" alt="" />
      </li>
      <li>
        <em>4th Active Aging Conference, in collaboration with</em>
        “2008 Shanghai International Conference on the Development of Old Age
        <br />
        Programs” Shanhai, China, September 2008
      </li>
    </ol>

    <ol>
      <li>
        <img src="/assets/img/sub/acap_eng_history05.jpg" alt="" />
      </li>
      <li>
        <em>5th ACAP in Fukuoka, Japan</em>
      </li>
    </ol>

    <ol>
      <li>
        <img src="/assets/img/sub/acap_eng_history06.jpg" alt="" />
      </li>
      <li>
        <em>6th ACAP in Bali, Indonesia</em>
      </li>
    </ol>

    <ol>
      <li>
        <img src="/assets/img/sub/acap_eng_history07.jpg" alt="" />
      </li>
      <li>
        <em>7th ACAP in Kuala Lumpur, Malaysia</em>
      </li>
    </ol>

    <ol>
      <li>
        <img src="/assets/img/sub/acap_eng_history08.jpg" alt="" />
      </li>
      <li>
        <em>8th ACAP in Busan, Korea</em>
      </li>
    </ol>

    <ol>
      <li>
        <img src="/assets/img/sub/acap_eng_history09.jpg" alt="" />
      </li>
      <li>
        <em>9th ACAP in Kitakushu, Japan</em>
      </li>
    </ol>

    <ol>
      <li>
        <img src="/assets/img/sub/acap_eng_history10.jpg" alt="" />
      </li>
      <li>
        <em>10th ACAP in Fukuoka, Japan</em>
      </li>
    </ol>

    <ol>
      <li>
        <img src="/assets/img/sub/acap_eng_history11.jpg" alt="" />
      </li>
      <li>
        <em>11th ACAP in Hong Kong </em>
      </li>
    </ol>

    <ol>
      <li>
        <img src="/assets/img/sub/acap_eng_history12.jpg" alt="" />
      </li>
      <li>
        <em>12th ACAP in Indonesia</em>
      </li>
    </ol>
  </div>
</div>
