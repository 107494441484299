import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonHttpService } from 'src/app/services/common-http.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

declare var $ : any;

@Component({
  selector: 'app-visual',
  templateUrl: './visual.component.html',
  styleUrls: ['./visual.component.scss']
})
export class VisualComponent implements OnInit {

  parentMenuName = '';
  breadcrumb = '';

  constructor(private commonService: CommonService,
    private route: ActivatedRoute) {

    this.commonService.getMenuObserve().subscribe(menus => {
      let menuId = parseInt(this.route.snapshot.params['menuId'], 0); 
      var menu = null;
      for (let entry of menus) {
        menu = this.commonService.getMenuForId(entry, menuId);
        if (menu) { 
          this.breadcrumb = ' > ' + menu.name;  
          break;
        } 
      }
      for (let entry of menus) {
        if (menu && menu.upperId) {
          var temp = this.commonService.getMenuForId(entry, menu.upperId);
          if (temp) {
            this.parentMenuName = temp.name; 
            this.breadcrumb = ' > ' + this.parentMenuName + this.breadcrumb; 
            if (temp.upperId != 0) {
              var temp2 = this.commonService.getMenuForId(entry, temp.upperId);
              this.breadcrumb = ' > ' + temp2.name + this.breadcrumb;
            }           
            break;
          } 
        }
      }
    });

  }

  // 1depth 슬라이드 다운 및 2depth 슬라이드 업
  depth1(){
    $('.depth1 ul').slideToggle(150);
    $('.depth2 ul').slideUp(100);
  }

  // 2depth 슬라이드 다운 및 1depth 슬라이드 업
  depth2(){
    $('.depth2 ul').slideToggle(150);
    $('.depth1 ul').slideUp(100);
  }

  // Breadcrumbs 'a' 클릭 시 활성화된 슬라이드 업
  closeDepth(){
    $('.depth1 ul, .depth2 ul').slideUp(100);
  }

  ngOnInit(){

    // 비주얼 Parallax 및 Fade 효과
    // var parallaxVisual = $('.visual');
    // var fadeOutTxt = $('.visual h2');

    // $('#wrap').scroll(function () {
    //   parallaxVisual.css('margin-top', ($('#wrap').scrollTop())/3);
    //   fadeOutTxt.css('opacity', 1 - ($('#wrap').scrollTop())/parallaxVisual.height());
    //   parallaxVisual.css('opacity', 1 - ($('#wrap').scrollTop())/parallaxVisual.height());
    // });


    $(document).mouseup(function (e) {
      // Breadcrumbs 이외 element 클릭 시 활성화 된 슬라이드 업
      var container = $('.breadcrumbs');
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $('.depth1 ul, .depth2 ul').slideUp(100);
      }
    });

    // 비주얼 높이값 많큼 스크롤 시 Breadcrumbs 상단 고정
    // $('#wrap').scroll(function () {
    //   var stickyPos = $('.visual').outerHeight();
    //   var stickyElement = $('.breadcrumbs');

    //   if ($('#wrap').scrollTop() > stickyPos) {
    //     stickyElement.addClass('sticky');
    //   } else {
    //     stickyElement.removeClass('sticky');
    //   }
    // });
  }

  
}
