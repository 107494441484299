import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonHttpService } from 'src/app/services/common-http.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

declare var $ : any;

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent implements OnInit {

  menus: any[] = null;

  constructor(private router: Router,
    private commonService: CommonService) {

      this.commonService.getMenuObserve().subscribe(menus => {
        this.menus = menus;
      });
  }

  ngOnInit() {
    //this.menus = this.commonHttpService.findAll('/menu.do?siteId=' + environment.siteId);
  }

}
