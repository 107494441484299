<div class="sitemap">
  <ul>
    <ng-container *ngFor="let menu of menus ; let index = index;">
      <li>
        <a [routerLink]="menu.linkUrl" [attr.data-text]="menu.linkUrl"><span>{{menu.name}}</span></a>
        <ul>
          <li *ngFor="let subMenu of menu.subMenus; let subIndex = index;">
            <button *ngIf="subMenu.menuType == 'F'" ><span>{{subMenu.name}}</span></button>
            <a *ngIf="subMenu.menuType == 'C'" [routerLink]="['/contents/' + subMenu.id]"><span>{{subMenu.name}}</span></a>
            <a *ngIf="subMenu.menuType == 'P'" [routerLink]="[subMenu.programUrl]"><span>{{subMenu.name}}</span></a>
            <a *ngIf="subMenu.menuType == 'B'" [routerLink]="['/board/' + subMenu.id + '/' + subMenu.boardConfigId + '/1']"><span>{{subMenu.name}}</span></a>
            <a href="{{subMenu.linkUrl}}" target="_balnk"  *ngIf="subMenu.menuType == 'L'" (click)="gnbClose()"><span>{{subMenu.name}}</span></a>
            <ul>
              <li *ngFor="let thirdMenu of subMenu.subMenus; let thirdIndex = index;">
                <a *ngIf="thirdMenu.menuType == 'C'" [routerLink]="['/contents/' + thirdMenu.id]"><span>{{thirdMenu.name}}</span></a>
                <a *ngIf="thirdMenu.menuType == 'P'" [routerLink]="[thirdMenu.programUrl]"><span>{{thirdMenu.name}}</span></a>
                <a *ngIf="thirdMenu.menuType == 'B'" [routerLink]="['/board/' + thirdMenu.id + '/' + thirdMenu.boardConfigId + '/1']"><span>{{thirdMenu.name}}</span></a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>
</div>
