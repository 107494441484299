import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonHttpService } from 'src/app/services/common-http.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

declare var $ : any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  parentTitle = '';
  menu: any = null;
  menus: any[] = null;
  subMenus: any[] = [];
  user: any;


  constructor(private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private commonHttpService: CommonHttpService) {

    this.commonService.getMenuObserve().subscribe( menus => {
      let menuId = parseInt(this.route.snapshot.params['menuId'], 0); 
      this.menu = this.commonService.getMenuForIds(menus, menuId);
     
      if (this.menu && this.menu.upperId) {
        var parentMenu = this.commonService.getMenuForIds(menus, this.menu.upperId);
        if (parentMenu) {            
          if(parentMenu.upperId == 0) {            
            this.parentTitle = parentMenu.name;
            this.subMenus = parentMenu.subMenus;
          } else {
            var menu2 = this.commonService.getMenuForIds(menus, parentMenu.upperId);
            this.parentTitle = menu2.name;
            this.subMenus = menu2.subMenus;
          }        
        }
      }
      this.user = this.commonService.getSessionItem('currentUser');

    });
  }

  ngOnInit() {

   
    
  }


  clickMenu(menu){    
    this.commonService.navigateMenu(menu);
  }

}
