<div class="breadcrumbs">
  <!-- PC GNB -->
  <div id="bnb">
    <div class="depth1">
      <button (click)="depth1()">{{parentTitle}}</button>
      <ul>
          <li *ngFor="let menu of menus;">
              <a href="javascript:;" [attr.data-text]="menu.name" (click)="clickDrop(menu, '1')"><span>{{menu.name}}</span></a>
          </li>
      </ul>
    </div>
    <div class="depth2">
      <button (click)="depth2()">{{childTitle}}</button>
      <ul>
          <li [routerLinkActive]="['active']" *ngFor="let menu of subMenus;">
            <a href="javascript:;" [attr.data-text]="menu.name" (click)="clickDrop(menu, '2')"><span>{{menu.name}}</span></a>
          </li>
      </ul>
    </div>
  </div>
  <!-- PC GNB -->
</div>



<div class="tab depth3" *ngIf="thirdTitle"><!-- 3 -->
  <button (click)="depth3()">{{thirdTitle}}</button>
  <ul>
      <li [routerLinkActive]="['active']" *ngFor="let menu of subMenus2;">
        <a href="javascript:;" [attr.data-text]="menu.name" (click)="clickDrop(menu, '2')"><span>{{menu.name}}</span></a>
      </li>
  </ul>
</div>
