<h3>회원정보</h3>
<form [formGroup]="userGroup" (ngSubmit)="saveUser()" novalidate>
<div class="member">
  <div class="field">
    <label for="usrId">아이디</label>
    <input type="text" id="usrId" placeholder="아이디" formControlName="userId" readonly="readonly" />
    <div class="coution">
    </div>
  </div>

  <div class="field">
    <label for="">비밀번호</label>
    <input type="password" placeholder="비밀번호 입력" formControlName="password"/>
    <input type="password" placeholder="비밀번호 다시 입력" formControlName="repeatPassword"/>

    <div class="coution" >
    </div>
  </div>

  <div class="field">
    <label for="">성명</label>
    <input type="text" placeholder="성명" formControlName="userName"/>
    <div class="coution" *ngIf="userGroup.controls.userName.errors?.required">
      <span class="red"><i class="fas fa-exclamation-triangle"></i> 필수 입력 항목입니다.</span>
    </div>
  </div>

  <div class="field">
    <label for="">생년월일</label>
    <input type="text" placeholder="생년월일 (ex.19881225)" formControlName="birthday" />
  </div>

  <div class="field">
    <label for="">연락처</label>
    <input type="text" placeholder="연락처 (ex.010-123-1234)"  formControlName="tel" />
  </div>

  <div class="field">
    <label for="">이메일</label>
    <input type="text" placeholder="이메일" formControlName="email"/>
    <div class="coution">
      <span *ngIf="userGroup.controls.email.errors?.required"><i class="fas fa-exclamation-triangle"></i> 필수 입력 항목입니다.</span>
      <span *ngIf="userGroup.controls.email.errors?.pattern"><i class="fas fa-exclamation-triangle"></i> 이메일 형식이 바르지 않습니다.</span>
    </div>
  </div>

  <div class="btn-group tac">
    <div class="di">
      <button type="submit" class="btn btn-apply" [disabled]="!userGroup.valid">회원정보 수정</button>
    </div>
  </div>
</div>
</form>