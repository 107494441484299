import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SubComponent } from './sub.component';
import { SubRoutes_Provider } from './sub.routes';
import { BoardComponent } from './board/board.component';
import { BoardViewComponent } from './board-view/board-view.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { LayoutsModule } from '../layouts/layouts.module';
import { ContactComponent } from './contact/contact.component';
import { GreetingComponent } from './greeting/greeting.component';
import { PipeModule } from '../pipe/pipe.module';
import { SitemapComponent } from './sitemap/sitemap.component';
import { LoginComponent } from './member/login/login.component';
import { JoinComponent } from './member/join/join.component';
import { VisionComponent } from './vision/vision.component';
import { HistoryComponent } from './history/history.component';
import { ResearchComponent } from './research/research.component';
import { BusinessComponent } from './ageing/business/business.component';
import { ParishBusinessComponent } from './parish/parish-business/parish-business.component';
import { ScienceBusinessComponent } from './science/science-business/science-business.component';
import { ParishProgramComponent } from './parish/parish-program/parish-program.component';
import { AccountFindComponent } from './member/account-find/account-find.component';
import { AccountCheckComponent } from './member/account-check/account-check.component';
import { UseridValidationService } from './member/userid-validation.service';
import { ModifyComponent } from './member/modify/modify.component';
import { OrganizationComponent } from './organization/organization.component';
import { ParishTeacherComponent } from './parish/parish-teacher/parish-teacher.component';
import { AcapComponent } from './ageing/acap/acap.component';
import { DegitalAgeingComponent } from './science/degital-ageing/degital-ageing.component';
import { QnaWriteComponent } from './qna-write/qna-write.component';
import { AgeingMediaComponent } from './ageing/ageing-media/ageing-media.component';
import { ScienceCultureComponent } from './science/science-culture/science-culture.component';

@NgModule({
  declarations: [
    SubComponent,
    BoardComponent,
    BoardViewComponent,
    TermsComponent,
    PrivacyComponent,
    ContactComponent,
    GreetingComponent,
    SitemapComponent,
    LoginComponent,
    JoinComponent,
    VisionComponent,
    HistoryComponent,
    ResearchComponent,
    BusinessComponent,
    ParishBusinessComponent,
    ScienceBusinessComponent,
    ParishProgramComponent,
    AccountFindComponent,
    AccountCheckComponent,
    ModifyComponent,
    OrganizationComponent,
    ParishTeacherComponent,
    AcapComponent,
    DegitalAgeingComponent,
    QnaWriteComponent,
    AgeingMediaComponent,
    ScienceCultureComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SubRoutes_Provider,
    LayoutsModule,
    FormsModule,
    PipeModule
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [UseridValidationService],
})
export class SubModule { }
