import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common.service';

declare var $ : any;

@Component({
  selector: 'app-sub',
  templateUrl: './sub.component.html',
  styleUrls: ['./sub.component.scss']
})
export class SubComponent implements OnInit {

  menuName = '';
  menusObs: Observable<any[]>;

  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute) {

    this.menusObs = this.commonService.getMenuObserve();      
    this.menusObs.subscribe(menus => {
      let menuId = parseInt(this.route.snapshot.params['menuId'], 0); 
      for (let entry of menus) {
        var menu = this.commonService.getMenuForId(entry, menuId);
        if (menu) {
          this.menuName = menu.name;
          break;
        } 
      }  
    });
  }

  ngOnInit(){
    $('#wrap').animate({ scrollTop: 0 }, 0);
    
    $(".translate-tab button").click(function () {
      $(this).addClass('active').siblings().removeClass('active');
      $(".translate").hide();

      var activeTab = $(this).data("tab");
      $("#" + activeTab).show();
    });
  }

}
