<div class="parish">
  <h5>노인교구지도사란?</h5>
  <div class="intro">
    <ul>
      <li>
        사단법인 노인생활과학연구소에서 발급되는 민간자격증으로 1급과 2급을
        소지하고 활동
      </li>
      <li>
        노인교구를 통해 노인의 인지기능, 의사소통, 마음 다스리기, 여가 및 놀이
        등을 지원하는 활동 전문가
      </li>
      <li>노인 내면의 생각을 쉽게 표현할 수 있도록 이끌어 주는 역할</li>
      <li>
        노인복지관, 경로당, 치매안심센터, 노인병원, 노인주간보호센터 등
        <br class="break" />
        다양한 지역사회에서 활동하는 전문가
      </li>
    </ul>
    <div class="pic">
      <img src="/assets/img/sub/parish_teacher.jpg" alt="" />
    </div>
  </div>
  <h5 class="mt100">노인교구 지도사 자격정보</h5>
  <!-- <p>자격정보</p> -->

  <div class="responsive">
    <div class="info">좌우로 탭하여 내용을 확인하실 수 있습니다.</div>
    <div class="scroll">
      <table class="tbl">
        <caption></caption>
        <tbody>
          <tr>
            <th>자격명</th>
            <td>노인교구지도사</td>
            <th>자격의 종류</th>
            <td>등록 민간자격증</td>
          </tr>
          <tr>
            <th>등록번호</th>
            <td>2018-002513</td>
            <th>자격발급기관</th>
            <td>(사)노인생활과학연구소</td>
          </tr>
          <tr>
            <th>민간자격 정보서비스</th>
            <td colspan="3">
              <a
                href="https://www.pqi.or.kr/inf/qul/infQulList.do;jsessionid=QLlNhCKYBBRKoxGwg8MtCMmhEWsoMsQWyLKnazCFqEZqRV0uyEvZ3IQVG3XauvQH?searchQulCpCd=0002"
                target="_blank"
                class="link"
                title="한국직업능력개발원 새창으로 바로가기"
              >
                한국직업능력개발원 민간자격정보 서비스 www.pqi.or.kr
              </a>
            </td>
          </tr>
          <tr>
            <th>비용</th>
            <td colspan="3">
              <ul>
                <li>총비용: 48만원</li>
                <li>교육비: 35만원</li>
                <li>시험 응시비: 5만원</li>
                <li>자격증 발급비: 5만원</li>
                <li>교재비: 3만원</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>환불규정</th>
            <td colspan="3">
              <ul>
                <li>
                  응시료 : 접수마감 전까지 100% 환불, 검정 당일 취소시 30% 공제
                  후 환불
                </li>
                <li>
                  자격발급비 : 합격자에 한하며, 자격증 제작 및 발송 이전 취소시
                  100% 환불되나, 이후 취소 시 환급 불가
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <p>자격관리·운영(발급)기관 정보</p>
  <div class="responsive mt20">
    <div class="info">좌우로 탭하여 내용을 확인하실 수 있습니다.</div>
    <div class="scroll">
      <table class="tbl">
        <caption></caption>
        <tbody>
          <tr>
            <th>기관명</th>
            <td>사)노인생활과학연구소</td>
            <th>대표자</th>
            <td>한동희</td>
          </tr>
          <tr>
            <th>연락처</th>
            <td>전화번호 : 051-624-5025 팩스 : 051-751-5025</td>
            <th>이메일</th>
            <td>wellageing12@gmail.com</td>
          </tr>
          <tr>
            <th>소재지</th>
            <td>부산광역시 연제구 토현로 32 4층</td>
            <th>홈페이지</th>
            <td>
              <a href="http://www.wellageing.com/" class="link"
                >http://www.wellageing.com/</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="notice">
    <strong>소비자 알림 사항</strong>
    <ul>
      <li>
        ① “노인교구지도사” 자격은 자격기본법 규정에 따라 등록한 민간자격으로,
        국가로부터 인정받은 공인자격이 아님.
      </li>
      <li>
        ② 민간자격 등록 및 공인 제도에 대한 상세내용은
        민간자격정보서비스(www.pqi.or.kr) 의 ‘민간자격 소개’ 란을 참고.
      </li>
    </ul>
  </div>
  <h5>노인교구지도사 1급 및 2급 자격시험 요건 (2022년 11월기준)</h5>
  <p>*노인교구지도사 1급 자격시험</p>
  <table class="tbl mt20">
    <caption>
      노인교구지도사 1급 자격시험
    </caption>
    <colgroup>
      <col width="*" />
    </colgroup>
    <thead>
      <tr>
        <th scope="col">단계</th>
        <th scope="col">1차</th>
        <th scope="col">2차</th>
        <th scope="col">3차</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>&nbsp; 내 용</td>
        <td>
          <ul>
            <li>
              서류심사 <br />
              (80시간 강의 확인)
            </li>
          </ul>
        </td>
        <td>
          <ul>
            <li>
              심화 교육 <br />
              (20시간 교육)
            </li>
            <li>
              교육비: 35만원 <br />
              (교재 포함)
            </li>
          </ul>
        </td>
        <td>
          <ul>
            <li>
              자격검정시험 <br />
              매년 2회 (상, 하반기)
            </li>
            <li>응시료 5만원</li>
            <li>
              자격증발급비<br />
              5만원
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>시 간 인 정&nbsp;</td>
        <td colspan="3">
          <ul>
            <li>
              주강사 시수: 60% 이상 참여하여 수업을 진행해야 함 (48시간 이상)
            </li>
            <li>보조강사 시수: 1/2 시간 인정</li>
            <li>
              역량강화 시수: 10% 인정 <br />
              (2020년~2021년 COVID-19로 인해 한시적으로 50% 인정하였음)
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>제 출 서 류</td>
        <td colspan="3">
          <ul>
            <li>기관 수업 시 기관장 또는 담당자 확인필</li>
            <li>
              개별 수업 시 매회기 활동사진 2장이 포함된 활동계획서 PDF 파일 제출
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>심 의 위원회</td>
        <td colspan="3">
          <ul>
            <li>자격요건 심의</li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>

  <p>*노인교구지도사 2급 자격시험</p>
  <table class="tbl mt20">
    <caption>
      노인교구지도사 2급 자격시험
    </caption>
    <colgroup>
      <col width="10%" />
      <col width="30%" />
      <col width="30%" />
      <col width="30%" />
    </colgroup>
    <thead>
      <tr>
        <th scope="col">단 계</th>
        <th scope="col">1차</th>
        <th scope="col">2차</th>
        <th scope="col">3차</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>내 용</td>
        <td>
          <ul>
            <li>
              연구소 및 연구소가 인정하는 기관에서 <br />
              40시간의 기본교육(이론과 실기)을 받은 자<br />
              교육비: 35만원, 교재비: 3만원
            </li>
          </ul>
        </td>
        <td>시험응시원서 접수&nbsp;</td>
        <td>
          <ul>
            <li>자격검정시험 매년 4회</li>
            <li>응시료:5만원</li>
            <li>
              자격증발급비<br />
              5만원
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>제 출 서 류</td>
        <td colspan="3">
          <ul>
            <li>
              노인교구지도사 자격시험 신청서 및 이수증(연구소외 타 기관인 경우)
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>심 의 위원회</td>
        <td colspan="3">
          <ul>
            <li>자격요건 심의</li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
  <p>
    *노인교구지도사 2급 자격증을 취득한 후 향후 5년간 일체 활동이 없을 시
    보수교육을 5년이 지난 1년 이내에 받아야만 자격을 유지할 수 있음.
  </p>
  <h5 class="mt50">노인교구지도사2급 교육과정</h5>
  <em>
    노년에 대한 이해, 노인의 의사소통 기술, 노인의 인지기능, 치매노인에 대한
    이해, 노인교구의 이해, 노인교구 활용 실제 등으로 총 40시간 구성됨
  </em>
  <!-- <p>교육과정 및 일정은 변동 가능</p> -->

  <ol>
    <li>
      노년에 대한<br />
      이해
    </li>
    <li>
      노인의<br />
      의사소통 기술
    </li>
    <li>
      노인 인지기능 및<br />
      치매에 대한 이해
    </li>
    <li>
      노인교구의<br />
      이해
    </li>
    <li>
      노인교구의<br />
      활용
    </li>
  </ol>
</div>
