<div class="login-wrap">
  <form (ngSubmit)="submit()">
    <div class="field" data-type="id">
      <input type="text" placeholder="아이디" [(ngModel)]="userId" name="userId">
    </div>
    <div class="field" data-type="password">
      <input type="password" placeholder="비밀번호" [(ngModel)]="password" name="password">
    </div>

    <p>회원이 아니시면 회원가입을 먼저 해주세요.</p>

    <div class="field" data-type="submit">
      <button type="submit">Sign in</button>
    </div>
  </form>
  <a [routerLink]="['/join/1222']">회원가입</a>

  <p [routerLink]="['/account-find/1230']">아이디 및 비밀번호 찾기</p>

</div>