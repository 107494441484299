import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class CommonService {

  private menuSubject = new Subject<any[]>();
  
  constructor(
    protected http: HttpClient,
    private router: Router) {
  }

  getMenu() : Observable <any[]> {
    return this.http.get<any>(environment.serviceUrl + '/menu.do?siteId=' + environment.siteId).pipe(
      map(menus => {
        this.setMenuObserve(menus); 
        return menus;
      })
    );
  }

  setMenuObserve(menus: any[]) {
    this.menuSubject.next(menus);
  }

  getMenuObserve() : Observable <any[]> {
    return this.menuSubject.asObservable();
  }

  getMenuForId(menu: any, id: number) : any {
    if (menu.id == id) {
      return menu;
    } else if (menu.subMenus){
      var i;
      var result = null;
      for(i=0; result == null && i < menu.subMenus.length; i++){
           result = this.getMenuForId(menu.subMenus[i], id);
      }
      return result;
    }
    return null;
  }

  getMenuForIds(menus: any[], id: number) : any {
    for (let entry of menus) {
      var menu = this.getMenuForId(entry, id);
      if (menu) return menu;
    }
    return null;
  }

  navigateMenu(menu) {
    if (menu) {
      if (menu.menuType == 'C') {
        this.router.navigate(['/contents/' + menu.id]);
      } else if (menu.menuType == 'P') {
        this.router.navigate([menu.programUrl]);
      }  else if (menu.menuType == 'B') {
        this.router.navigate(['/board/' + menu.id + '/' + menu.boardConfigId + '/1']);
      } else if (menu.menuType == 'L') {
        window.open(menu.linkUrl, "_blank");
      } else if (menu.menuType == 'F') {
        menu = menu.subMenus[0];
        if (menu.menuType == 'C') {
          this.router.navigate(['/contents/' + menu.id]);
        } else if (menu.menuType == 'P') {
          this.router.navigate([menu.programUrl]);
        }  else if (menu.menuType == 'B') {
          this.router.navigate(['/board/' + menu.id + '/' + menu.boardConfigId + '/1']);
        } else if (menu.menuType == 'L') {
          window.open(menu.linkUrl, "_blank");
        } 
      } 
    }
  }

  setSessionItem(key: string, item: any) {
      let itemStr = JSON.stringify(item);
      sessionStorage.setItem(key, itemStr);
  }

  getSessionItem(key: string) {
      let itemStr = sessionStorage.getItem(key);
      let item    = null;
      if(itemStr) {
          item = JSON.parse(itemStr);
      }
      return item;
  }

  login(userId: string, password: string): Observable<boolean> {
    return this.http.post(environment.serviceUrl + '/user/loginForSite.do', {siteId: environment.siteId, userId: userId, password: password})
      .pipe(
        map(result => {
          this.setSessionItem('currentUser', result);
          return true;
        })
      );
  }

}
