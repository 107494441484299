import { Component, OnInit } from '@angular/core';

declare var $ : any;

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(".translate-tab button").click(function () {
      $(this).addClass('active').siblings().removeClass('active');
      $(".translate").hide();

      var activeTab = $(this).data("tab");
      $("#" + activeTab).show();
    });
  }

}
