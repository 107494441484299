<div id="board-veiw">
  <div class="print-title">{{ boardConfig.title }} 게시판</div>
  <div class="board-view-title">
    {{ board.title }}
  </div>

  <!-- 작성일 및 부가기능(url복사, 프린트, SNS 공유) -->
  <div class="board-view-publish">
    <div class="date">작성일 : {{ board.updateTime | date: "yyyy-MM-dd" }}</div>
    <div class="ability">
      <div class="di">
        <button (click)="copyClipBoard()" title="주소복사">
          <i class="fas fa-clipboard"></i>
        </button>
        <button (click)="boardPrint()" title="인쇄하기">
          <i class="fas fa-print"></i>
        </button>
        <!-- <a href="mailto:{{email}}?subject={{board.title}}&body={{originUrl}}/#/boardView/{{menuId}}/{{boardConfigId}}/{{boardId}}/{{page}}"><i class="fas fa-envelope"></i></a>
        <button (click)="snsShare($event)"  data-service="twitter" data-title="{{board.title}}"><i class="fab fa-twitter"></i></button>
        <button (click)="snsShare($event)"  data-service="facebook" data-title="{{board.title}}"><i class="fab fa-facebook-f"></i></button> -->
      </div>
    </div>
  </div>

  <!-- 링크가 있을 경우 -->
  <div class="board-link" *ngIf="board.urlInfo">
    <a href="{{ board.urlInfo }}" target="_blank" title="새창으로 보기">{{
      board.urlInfo
    }}</a>
  </div>

  <!-- 내용 -->
  <ng-container *ngIf="board?.boardFiles">
    <img
      *ngIf="
        board.boardFiles[0]?.fileInfo.fileType == 'image/png' ||
        board.boardFiles[0]?.fileInfo.fileType == 'image/jpeg'
      "
      [src]="serviceUrl + board.boardFiles[0]?.fileId"
    />
  </ng-container>

  <div class="board-view-content">
    <div [innerHTML]="board?.content | safeHtml: 'html'"></div>

    <ng-container *ngIf="board?.boardReplys">
      <!-- 답변 -->
      <div class="answer" *ngFor="let obj of board?.boardReplys">
        <em>답변</em>

        <p>{{ obj.content }}</p>
      </div>
    </ng-container>
  </div>

  <!-- 유튜브 영상이 있을경우 (반드시 safe 파이프와 함께 사용하여야 합니다.) -->
  <div class="board-youtube" *ngIf="board.videoUrl">
    <iframe
      [src]="youtube + board.videoUrl | safe"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <!-- 첨부파일이 없을경우 -->
  <!-- <ng-container *ngIf="!boardFiles">
    <div class="board-view-file">
      <strong>첨부파일 : </strong>
      <span>첨부파일이 없습니다.</span>
    </div>
  </ng-container> -->

  <!-- 첨부파일이 있을경우 -->
  <ng-container>
    <div class="board-view-file" *ngFor="let file of board.boardFiles">
      <strong>첨부파일 : </strong>
      <a
        *ngIf="user?.id || menuId == 1206"
        [href]="serviceUrl + file.fileId"
        target="_blank"
        title="첨부파일 다운로드"
        download
        >{{ file.fileInfo.logFileName }} <i class="fas fa-download"></i
      ></a>
      <button (click)="member()" *ngIf="user?.id === null && menuId != 1206">
        {{ file.fileInfo.logFileName }}
      </button>
    </div>
  </ng-container>

  <div *ngIf="boardConfig.title === '연구소 칼럼'" class="illust">
    <img src="/assets/img/common/illust.png" alt="" />
  </div>
</div>

<!-- 이전 및 다음 게시글 보기 시작 -->
<div class="board-list">
  <div class="board-list-prev" (click)="pre()">
    <button *ngIf="prevBoard?.id != null" title="{{ prevBoard.title }}">
      {{ prevBoard.title }}
    </button>
    <span *ngIf="prevBoard?.id === null">이전글이 없습니다.</span>
  </div>
  <div class="board-list-btn">
    <a
      [routerLink]="['/board/' + menuId + '/' + boardConfigId + '/' + page]"
      title="목록보기"
      ><i class="fas fa-bars"></i
    ></a>
  </div>
  <div class="board-list-next" (click)="next()">
    <button *ngIf="nextBoard?.id != null" title="{{ nextBoard.title }}">
      {{ nextBoard.title }}
    </button>
    <span *ngIf="nextBoard?.id === null">다음글이 없습니다.</span>
  </div>
</div>
<!-- 이전 및 다음 게시글 보기 끝 -->

<!-- 버튼 -->
<ng-container *ngIf="user?.id && boardConfig?.boardType == 'QNA'">
  <div class="btn-group tac" *ngIf="user?.id == board?.updateUserId">
    <div class="di">
      <button class="btn btn-update">수정</button>
      <button class="btn btn-delete" (click)="delete()">삭제</button>
    </div>
  </div>
</ng-container>
