import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from './common.service';

@Injectable()
export class CommonHttpService {

    protected httpOptions = {headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(protected httpClient: HttpClient, private commonService: CommonService) { }
    
    public findAll(url: string) {
        return this.httpClient.get<any[]>(environment.serviceUrl + encodeURI(url));
    }

    public findObject(url: string) {
        return this.httpClient.get<any>(environment.serviceUrl + encodeURI(url));
    }

    public findObjectExternal(url: string) {
        return this.httpClient.get<any>(url);
    }

    public findObjectForPost(obj:any, url: string) {
        return this.httpClient.post<any>(environment.serviceUrl + url, JSON.stringify(obj), this.httpOptions);
    }

    public saveObject(obj: any, url: string) {
        return this.httpClient.post(environment.serviceUrl + url, JSON.stringify(obj), this.httpOptions);
    }

    public isAbleCode(userId: String): Observable<boolean> {
        return this.httpClient.get<boolean>(environment.serviceUrl + '/user/isAbleUserId/' + environment.siteId + '/' + userId + '.do')
            .pipe(
            map(result => {
                return result;
            })
        );
    }
}
