import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonHttpService } from 'src/app/services/common-http.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

declare var $ : any;

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  parentTitle = '';
  childTitle = '';
  thirdTitle = '';

  menus: any[] = [];
  subMenus: any[] = [];
  subMenus2: any[] = [];

  constructor(private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute) {

    this.commonService.getMenuObserve().subscribe(menus => {
      this.menus = menus;
      
      let menuId = parseInt(this.route.snapshot.params['menuId'], 0); 
      var menu = this.commonService.getMenuForIds(menus, menuId);
     
      if (menu && menu.upperId) {
        var parentMenu = this.commonService.getMenuForIds(menus, menu.upperId);
        if (parentMenu) {
          if(parentMenu.upperId == 0) {
            this.parentTitle = parentMenu.name;
            this.subMenus = parentMenu.subMenus;
            this.childTitle = menu.name;
          } else {
            var menu2 = this.commonService.getMenuForIds(menus, parentMenu.upperId);
            this.parentTitle = menu2.name;
            this.subMenus = menu2.subMenus;
            this.childTitle = parentMenu.name;
            this.subMenus2 = parentMenu.subMenus;
            this.thirdTitle = menu.name;
          }
        }
      }
      
    });
  }

  // 1depth 슬라이드 다운 및 2depth 슬라이드 업
  depth1(){
    $('.depth1 ul').slideToggle(150);
    $('.depth2 ul, .depth3 ul').slideUp(100);
  }

  // 2depth 슬라이드 다운 및 1depth 슬라이드 업
  depth2(){
    $('.depth2 ul').slideToggle(150);
    $('.depth1 ul, .depth3 ul').slideUp(100);
  }

  depth3(){
    $('.depth3 ul').slideToggle(150);
    $('.depth1 ul, .depth2 ul').slideUp(100);
  }

  // Breadcrumbs 'a' 클릭 시 활성화된 슬라이드 업
  clickDrop(menu, depth){ 
    $('.depth1 ul, .depth2 ul').slideUp(100);
    var menuName = '';      
    var subMenu = null;
    if (depth == '1') {
      menuName = this.parentTitle;
      subMenu = menu.subMenus[0];
    } else {
      menuName = this.childTitle;
      subMenu = menu;
    }
    if (menu.name != menuName) {      
      this.commonService.navigateMenu(subMenu);      
    }
  }

  

  ngOnInit() {
    $(document).mouseup(function (e) {
      // Breadcrumbs 이외 element 클릭 시 활성화 된 슬라이드 업
      var container = $('.breadcrumbs');
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $('.depth1 ul, .depth2 ul').slideUp(100);
      }
    });

    // 비주얼 높이값 많큼 스크롤 시 Breadcrumbs 상단 고정
    $('#wrap').scroll(function () {
      var stickyPos = $('.visual').outerHeight();
      var stickyElement = $('.breadcrumbs');

      if ($('#wrap').scrollTop() > stickyPos) {
        stickyElement.addClass('sticky');
      } else {
        stickyElement.removeClass('sticky');
      }
    });
  }

}
