<div id="map"></div>

<div class="contact">
    <strong>부산광역시 연제구 토현로 32 4층</strong>

    <table>
        <caption></caption>
        <tbody>
            <tr>
                <th>버스</th>
                <td>
                    <span>망미주공아파트 하차 도보 5분 (87번 버스는 망미주공아파트 종점 하차 도보 3분)</span>
                    <dl>
                        <dt class="bus">일반버스</dt>
                        <dd>42번, 49번, 54번, 57번, 87번, 210번</dd>
                    </dl>
                </td>
            </tr>
            <tr>
                <th>지하철</th>
                <td>
                    <span>망미주공아파트 하차 도보 5분</span>
                    <dl>
                        <dt class="subway-line3">3호선</dt>
                        <dd>3호선 망미역 4번 출구(환승) → 금호주유소 57번, 54번, 49번, 210번 승차 → 망미주공아파트 하차 도보 5분</dd>
                    </dl>
                </td>
            </tr>
        </tbody>
    </table>
</div>