import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  userId: string;
  password: string;
  
  constructor(private commonService: CommonService, private router: Router) { }

  ngOnInit() {
  }

  submit() {
    this.commonService.login(this.userId, this.password)
      .pipe(first())
      .subscribe(
        result => {
          this.router.navigate(['/']);
        },
        err => {
          alert('사용자 정보가 없습니다.')
        }
      );
  }

}
