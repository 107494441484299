<!-- 


  * --------------------------------------------------------------------------
  * descript : 게시판 타입
  * --------------------------------------------------------------------------
  * author	: Yoodaekyung
  * update	: 2019.06.18
  * --------------------------------------------------------------------------



  * ==========================================================================
  * Type of board
  * ==========================================================================
  * Type : [NORMAL]     -   [일반]
  * Type : [PHOTO]      -   [갤러리]
  * Type : [BLOG]       -   [공지사항]
  * --------------------------------------------------------------------------


 -->

 <div class="writer" *ngIf="menuId === 1196">
  <div class="inner">
    <strong>"길에서 삶을 묻다"</strong>
    <span>2024년 새 필진 소개</span>

    <ul>
      <li>
        <img src="/assets/img/sub/writer2024_01.jpg" alt="" />
        <em>고경우 변호사</em>
        <sub>법무법인로엔케이대표</sub>
      </li>
      <li>
        <img src="/assets/img/sub/writer2024_02.jpg" alt="" />
        <em>박창호 위원장</em>
        <sub>게임콘텐츠등급분류위원회</sub>
      </li>
      <li>
        <img src="/assets/img/sub/writer2024_03.jpg" alt="" />
        <em>박진규 사장</em>
        <sub>하나 인터내셔날 </sub>
      </li>
      <li>
        <img src="/assets/img/sub/writer05.jpg" alt="" />
        <em>이봉구교수</em>
        <sub>동의대학교 국제관광경영학과</sub>
      </li>
      <li>
        <img src="/assets/img/sub/writer2024_04.jpg" alt="" />
        <em>이재일 원장</em>
        <sub>그랜드자연요양병원장</sub>
      </li>
      <li>
        <img src="/assets/img/sub/writer2024_05.jpg" alt="" />
        <em>정미영 교수</em>
        <sub>동명대학교 미디어커뮤니케이션학과</sub>
      </li>
    </ul>

    <mark>
      <em>사단법인 노인생활과학연구소</em>
      <span
        >Research Institute of Science for the Better Living of the
        Elderly</span
      >
    </mark>
  </div>
</div>


<!-- 게시판 시작 -->
<div id="board">
  <div class="count">
    총 게시글 : <strong>{{ boardCnt }}</strong
    >건
  </div>

  <!-- Type : [NORMAL] 시작 -->
  <ng-container *ngIf="boardConfig?.boardType == 'NORMAL'">
    <div id="normal">
      <div class="list" *ngFor="let bbs of boards">
        <div class="date">
          <strong>{{ bbs.updateTime | date: "dd" }}</strong>
          <span
            >{{ bbs.updateTime | date: "yyyy" }}.{{
              bbs.updateTime | date: "MMM"
            }}</span
          >
        </div>
        <a
          [routerLink]="[
            '/boardView/' +
              menuId +
              '/' +
              boardConfig.id +
              '/' +
              bbs.id +
              '/' +
              page
          ]"
          title=" {{ bbs.title }}"
        >
          <span class="news-subject" [innerHTML]="bbs.title | stripHtml"></span>
          <span
            class="news-contents"
            [innerHTML]="bbs.content | stripHtml"
          ></span>
        </a>
        <div class="view">
          <span><i class="fas fa-eye"></i> {{ bbs.readCnt }}</span>
        </div>
      </div>

      <div *ngIf="boards?.length == 0" class="no-file">
        <i class="fas fa-exclamation-triangle"></i>
        게시글이 없습니다.
      </div>
    </div>
  </ng-container>
  <!-- Type : [NORMAL] 끝 -->

  <!-- Type : [PHOTO] 시작 -->
  <ng-container *ngIf="boardConfig?.boardType == 'PHOTO'">
    <div id="gallery">
      <ul>
        <li
          *ngFor="let bbs of boards"
          (mouseenter)="bbs.hover = true"
          (mouseleave)="bbs.hover = false"
          [ngClass]="bbs.hover ? 'on' : 'off'"
        >
          <a
            [routerLink]="[
              '/boardView/' +
                menuId +
                '/' +
                boardConfigId +
                '/' +
                bbs.id +
                '/' +
                page
            ]"
          >
            <ng-container *ngIf="bbs.thumbFileId || bbs.boardFiles">
              <div
                *ngIf="bbs.thumbFileId"
                class="thumb"
                [style.backgroundImage]="
                  'url(' + serviceUrl + bbs.thumbFileId + ')'
                "
              ></div>
              <div
                *ngIf="!bbs.thumbFileId && bbs.boardFiles"
                class="thumb no-image"
              ></div>
            </ng-container>
            <div
              *ngIf="!bbs.thumbFileId && !bbs.boardFiles"
              class="thumb no-image"
            ></div>
            <div class="info">
              <span class="tit" [innerHTML]="bbs.title | stripHtml"></span>
              <span class="con" [innerHTML]="bbs.content | stripHtml"></span>
              <span class="date">{{
                bbs.updateTime | date: "yyyy.MM.dd"
              }}</span>
            </div>
          </a>
        </li>
        <li *ngIf="boards?.length == 0" class="no-gallery">
          <i class="fas fa-exclamation-triangle"></i>
          게시글이 없습니다.
        </li>
      </ul>
    </div>
  </ng-container>
  <!-- Type : [PHOTO] 끝 -->

  <!-- Type : [BLOG] 시작 -->
  <ng-container *ngIf="boardConfig?.boardType == 'BLOG'">
    <div id="blog">
      <div class="list" *ngFor="let bbs of boards">
        <a
          [routerLink]="[
            '/boardView/' +
              menuId +
              '/' +
              boardConfigId +
              '/' +
              bbs.id +
              '/' +
              page
          ]"
          title="{{ bbs.title }}"
        >
          <div
            *ngIf="bbs.thumbFileId"
            class="thumb"
            [style.backgroundImage]="
              'url(' + serviceUrl + bbs.thumbFileId + ')'
            "
          ></div>
          <div *ngIf="!bbs.thumbFileId" class="thumb no-image"></div>
          <div class="info">
            <span class="tit" [innerHTML]="bbs.title | stripHtml"></span>
            <span class="con" [innerHTML]="bbs.content | stripHtml"></span>
            <span class="date">{{ bbs.updateTime | date: "yyyy.MM.dd" }}</span>
          </div>
          <div class="view">
            <span><i class="fas fa-eye"></i> {{ bbs.readCnt }}</span>
          </div>
        </a>
      </div>
      <div *ngIf="boards?.length == 0" class="no-file">
        <i class="fas fa-exclamation-triangle"></i>
        게시글이 없습니다.
      </div>
    </div>
  </ng-container>
  <!-- Type : [BLOG] 끝 -->

  <!-- Type : [YOUTUBE] 시작 -->
  <ng-container *ngIf="boardConfig?.boardType == 'YOUTUBE'">
    <div id="gallery">
      <ul>
        <li
          *ngFor="let bbs of boards"
          (mouseenter)="bbs.hover = true"
          (mouseleave)="bbs.hover = false"
          [ngClass]="bbs.hover ? 'on' : 'off'"
        >
          <a
            href="https://www.youtube.com/watch?v={{ bbs.urlInfo }}"
            target="_balnk"
          >
            <div
              *ngIf="bbs.urlInfo"
              class="thumb"
              [style.backgroundImage]="
                'url(' + serviceUrl + bbs.thumbFileId + ')'
              "
            ></div>
            <div *ngIf="!bbs.urlInfo" class="thumb no-image"></div>
            <div class="info">
              <span class="tit" [innerHTML]="bbs.title | stripHtml"></span>
              <span class="con" [innerHTML]="bbs.content | stripHtml"></span>
              <span class="date">{{
                bbs.updateTime | date: "yyyy.MM.dd"
              }}</span>
            </div>
          </a>
        </li>
        <li *ngIf="boards?.length == 0" class="no-gallery">
          <i class="fas fa-exclamation-triangle"></i>
          게시글이 없습니다.
        </li>
      </ul>
    </div>
  </ng-container>
  <!-- Type : [YOUTUBE] 끝 -->

  <!-- Type : [ QNA ] 시작 -->
  <ng-container *ngIf="boardConfig?.boardType == 'QNA'">
    <div id="qna">
      <div class="list" *ngFor="let bbs of boards">
        <div class="date">
          <strong>{{ bbs.updateTime | date: "dd" }}</strong>
          <span
            >{{ bbs.updateTime | date: "yyyy" }}.{{
              bbs.updateTime | date: "MMM"
            }}</span
          >
        </div>
        <a
          [routerLink]="[
            '/boardView/' +
              menuId +
              '/' +
              boardConfig.id +
              '/' +
              bbs.id +
              '/' +
              page
          ]"
          title=" {{ bbs.title }}"
          >{{ bbs.title }}</a
        >
        <div class="answer">
          <span class="ready" *ngIf="bbs.boardReplys == null">답변대기</span>
          <span class="complete" *ngIf="bbs.boardReplys != null">답변완료</span>
        </div>
      </div>

      <!-- 게시글이 없을 경우 -->
      <div *ngIf="boards?.length == 0" class="no-file">
        <i class="fas fa-exclamation-triangle"></i>
        게시글이 없습니다.
      </div>

      <!-- 글쓰기 버튼 -->
      <div class="btn-group tac" *ngIf="user?.id">
        <div class="di">
          <button class="btn btn-write" [routerLink]="['/qna-write/1247']">
            글쓰기
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Type : [ QNA ] 끝 -->

  <!-- Type : [BOOK] 시작 -->
  <ng-container *ngIf="boardConfig?.boardType == 'BOOK'">
    <div id="book">
      <div class="list" *ngFor="let bbs of boards">
        <div
          *ngIf="bbs.thumbFileId"
          class="thumb"
          [style.backgroundImage]="'url(' + serviceUrl + bbs.thumbFileId + ')'"
        ></div>
        <div *ngIf="!bbs.thumbFileId" class="thumb no-image"></div>
        <div class="info">
          <strong class="tit" [innerHTML]="bbs.title | stripHtml"></strong>
          <ul>
            <li>
              <em>발행일</em>
              {{ bbs.updateTime | date: "yyyy.MM" }}
            </li>
            <li>
              <em>{{ bbs.nickName }}</em>
              국문
            </li>
            <li>
              <em>저서</em>
              <span *ngFor="let item of bbs.boardTags">{{ item.name }} </span>
            </li>
          </ul>

          <div class="file">
            <p *ngIf="!bbs.boardFiles[0]">
              <i class="fas fa-download"></i> 첨부파일이 없습니다.
            </p>
            <a
              *ngIf="bbs.boardFiles[0] && user?.id"
              [href]="serviceUrl + bbs.boardFiles[0].fileId"
              target="_blank"
              title="첨부파일 다운로드"
              download
              ><i class="fas fa-download"></i>
              {{ bbs.boardFiles[0].fileInfo.logFileName }}
            </a>

            <span *ngIf="user?.id === null" (click)="member()"
              ><i class="fas fa-download"></i>
              {{ bbs.boardFiles[0].fileInfo.logFileName }}</span
            >
          </div>
        </div>
      </div>
      <div *ngIf="boards?.length == 0" class="no-file">
        <i class="fas fa-exclamation-triangle"></i>
        게시글이 없습니다.
      </div>
    </div>
  </ng-container>
  <!-- Type : [BOOK] 끝 -->

  <!-- Type : [FILE] 시작 -->
  <ng-container *ngIf="boardConfig?.boardType == 'FILE'">
    <div id="file">
      <div class="list" *ngFor="let bbs of boards">
        <div
          *ngIf="bbs.thumbFileId"
          class="thumb"
          [style.backgroundImage]="'url(' + serviceUrl + bbs.thumbFileId + ')'"
        ></div>
        <div *ngIf="!bbs.thumbFileId" class="thumb no-image"></div>

        <div class="info">
          <span class="date">{{ bbs.updateTime | date: "yyyy.MM.dd" }}</span>

          <a
            [routerLink]="[
              '/boardView/' +
                menuId +
                '/' +
                boardConfigId +
                '/' +
                bbs.id +
                '/' +
                page
            ]"
            ><strong class="tit" [innerHTML]="bbs.title | stripHtml"></strong
          ></a>
          <span class="con" [innerHTML]="bbs.content | stripHtml"></span>

          <div class="file" *ngIf="user?.id">
            <p *ngIf="!bbs.boardFiles[0]">
              <i class="fas fa-download"></i> 첨부파일이 없습니다.
            </p>
            <a
              *ngIf="bbs.boardFiles[0]"
              [href]="serviceUrl + bbs.boardFiles[0].fileId"
              target="_blank"
              title="첨부파일 다운로드"
              download
              ><i class="fas fa-download"></i>
              {{ bbs.boardFiles[0].fileInfo.logFileName }}
            </a>
          </div>
        </div>
      </div>

      <div *ngIf="boards?.length == 0" class="no-file">
        <i class="fas fa-exclamation-triangle"></i>
        게시글이 없습니다.
      </div>
    </div>
  </ng-container>
  <!-- Type : [FILE] 끝 -->

  <!-- 검색 시작 -->
  <!-- <div id="search">
    <div class="search">
      <input type="text" name="searchKeyword" id="searchKeyword" placeholder="검색어를 입력하세요" title="검색어 입력"
        class="search_input" [(ngModel)]="searchTxt" />
      <button (click)="search();"><i class="fas fa-search"></i></button>
    </div>
  </div> -->
  <!-- 검색 끝 -->

  <!-- 페이징 시작 -->
  <div class="board-pagenation">
    <div class="di">
      <button class="page-prev" (click)="prevPageChanged()" title="이전 페이지">
        <i class="fas fa-angle-left"></i>
      </button>
      <ng-container *ngFor="let blockPage of blockPages">
        <button *ngIf="blockPage == page" class="num active">
          {{ blockPage }}
        </button>
        <button
          *ngIf="blockPage != page"
          class="num"
          (click)="pageChanged(blockPage)"
        >
          {{ blockPage }}
        </button>
      </ng-container>
      <button class="page-next" (click)="nextPageChanged()" title="다음 페이지">
        <i class="fas fa-angle-right"></i>
      </button>
    </div>
  </div>
  <!-- 페이징 끝 -->
</div>
<!-- 게시판 끝 -->
