import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { CommonHttpService } from './services/common-http.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { SubModule } from './sub/sub.module';
import { LayoutsModule } from './layouts/layouts.module';
import { PipeModule } from './pipe/pipe.module';
import { CommonService } from './services/common.service';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SubModule,
    LayoutsModule,
    PipeModule
  ],
  exports: [
  ],
  providers: [CommonHttpService, CommonService],
  bootstrap: [AppComponent]
})
export class AppModule { }
