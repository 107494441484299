import { Component, OnInit } from '@angular/core';

declare var $ : any;
declare var google : any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  // 탑 버튼 클릭 시 상단으로 부드럽게 이동
  scrollTop(){
    $('#wrap').animate({
      scrollTop: 0
    }, 350);
  }

  ngOnInit() {

    // 탑 버튼 페이드 처리
    $('#wrap').scroll(function () {
      if ($('#wrap').scrollTop() > 200) {
        $(".top").fadeIn();
      } else {
        $(".top").fadeOut();
      }
    });
    
  }

}
