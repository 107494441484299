<h3>개인정보활용동의</h3>
<div class="agreement-contents">
  <strong>개인정보보호 및 수집·이용에 동의</strong>

  <em>1. 개인정보의 수집 및 이용 목적</em><br />
  가.본 사이트는 회원가입 또는 서비스이용 신청시 회원이 제공하는 정보를 통하여 회원에 관한 정보를 수집하며, 회원의 개인정보는 본 이용계약의 이행과 본 이용계약상의 서비스제공을 위한 목적으로 이용하며 그 내용은
  다음과 같습니다.
  - 본 사이트 회원정보 관리<br />
  - 서비스 이용에 관한 만족도 조사, 공지사항 전달 등<br /><br />

  나.본 사이트는 서비스 제공과 관련하여 취득한 회원의 정보를 본인의 승낙 없이 제3자에게 누설 또는 배포할 수 없으며 상업적 목적으로 사용할 수 없습니다. 다만, 다음의 각 호의 경우에는 그러하지 아니합니다.
  <br />
  -관계 법령에 의하여 수사상의 목적으로 관계기관으로부터 요구가 있는 경우<br />
  -정보통신윤리위원회의 요청이 있는 경우<br />
  -기타 관계법령에서 정한 절차에 따른 요청이 있는 경우<br /><br />

  다.계약사항의 변경<br />
  -회원은 회원정보관리를 통해 언제든지 자신의 정보를 열람하고 수정할 수 있습니다. 회원은 이용신청 시 기재한 사항이 변경되었을 때에는 수정을 하여야 하며, 수정하지 아니하여 발생하는 문제의 책임은 회원에게
  있습니다.<br /><br />

  <em>2. 수집하는 개인정보의 항목</em><br />
  가. 필수항목: 성명, 전화번호,이메일 <br />
  나. 선택항목: 생년월일<br />
  다. 자동수집항목: IP(Internet Protocol)주소, 이용내용의 기록 <br />
  - 부정한 방법으로 타인명의를 사용하는 경우에 대비하기 위해 정보이용내역 등을 자동수집 합니다. <br />
  ※ 다른 사람의 주민등록번호 또는 아이디/비밀번호를 부정하게 사용한 경우는 5년 이하의 징역이나 5000만원 이하의 벌금이 부과됩니다. <br /><br />

  <em>3. 개인정보의 보유 및 이용기간</em><br />
  본 사이트는 원칙적으로 개인정보 보존기간이 경과하거나, 처리목적이 달성된 경우에는 지체 없이 개인정보를 파기합니다. 다만, 다른 법령에 따라 보존하여야 하는 경우에는 그러하지 않을 수 있습니다. <br />
  가. 회원정보 : 탈퇴 즉시 파기 <br />
  나. 자동수집항목 중 IP주소 : 1년 <br />

  4. 동의를 거부할 권리가 있다는 사실 및 동의 거부에 따른 불이익 내용 민원 신청 시 수집하는 필요한 최소한의 정보 외의 개인정보 수집에 동의를 거부할 권리가 있으나 최소한의 개인정보 수집동의 거부 시에는 민원
  신청 서비스가 제한됩니다.<br /><br />

  5. 본 사이트는 관련법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관해서는 관련법령 및 제17조에 제시된 내용을 지킵니다.
</div>


<div class="agreement-chk">
  <input type="checkbox" name="agree" id="agree" [(ngModel)]="agree">
  <label for="agree">개인정보 활용 동의합니다.</label>
</div>



<h3>회원정보</h3>
<form [formGroup]="userGroup" (ngSubmit)="saveUser()" novalidate>
<div class="member">
  <div class="field">
    <label for="usrId">아이디</label>
    <input type="text" id="usrId" placeholder="아이디" formControlName="userId"/>
    <div class="coution">
      <span class="red" *ngIf="userGroup.controls.userId.errors?.required" ><i class="fas fa-exclamation-triangle"></i> 아이디는 영문자,숫자의 조합(6 ~ 14)이어야 합니다.</span>
      <span class="red" *ngIf="userGroup.controls.userId.errors?.pattern"><i class="fas fa-exclamation-triangle"></i> 아이디는 영문자,숫자의 조합(6 ~ 14)이어야 합니다.</span>
      <span *ngIf="!userGroup.controls.userId.errors?.pattern && userGroup.controls.userId.errors?.code">
        <i class="fas fa-exclamation-triangle"></i> 사용 사용할 수 없는 아이디는입니다. </span>
      <span class="green" *ngIf="!userGroup.controls.userId.errors?.required && !userGroup.controls.userId.errors?.pattern && 
        !userGroup.controls.userId.errors?.code"><i class="fas fa-check-circle"></i> 사용 가능한 아이디는입니다. </span>
    </div>
  </div>

  <div class="field" [formGroup]="passwordFormGroup">
    <label for="">비밀번호</label>
    <input type="password" placeholder="비밀번호 입력" formControlName="password"/>
    <input type="password" placeholder="비밀번호 다시 입력" formControlName="repeatPassword"/>

    <div class="coution" >
      <span *ngIf="passwordFormGroup.controls.password.errors?.required"><i class="fas fa-exclamation-triangle"></i> 패스워드는 숫자와 영문자 조합으로 6~20자리를 사용해야 합니다.</span>
      <span *ngIf="passwordFormGroup.controls.password.errors?.pattern"><i class="fas fa-exclamation-triangle"></i> 패스워드는 숫자와 영문자 조합으로 6~20자리를 사용해야 합니다.</span>
      <span  *ngIf="passwordFormGroup.errors?.doesMatchPassword"><i class="fas fa-exclamation-triangle"></i> 패스워드 확인이 맞지 않습니다.</span>
    </div>
  </div>

  <div class="field">
    <label for="">성명</label>
    <input type="text" placeholder="성명" formControlName="userName"/>
    <div class="coution" *ngIf="userGroup.controls.userName.errors?.required">
      <span class="red"><i class="fas fa-exclamation-triangle"></i> 필수 입력 항목입니다.</span>
    </div>
  </div>

  <div class="field">
    <label for="">생년월일</label>
    <input type="text" placeholder="생년월일 (ex.19881225)" formControlName="birthday" />
  </div>

  <div class="field">
    <label for="">연락처</label>
    <input type="text" placeholder="연락처 (ex.010-123-1234)"  formControlName="tel" />
    <div class="coution" *ngIf="userGroup.controls.tel.errors?.required">
      <span class="red"><i class="fas fa-exclamation-triangle"></i> 필수 입력 항목입니다.</span>
    </div>
  </div>

  <div class="field">
    <label for="">이메일</label>
    <input type="text" placeholder="이메일" formControlName="email"/>
    <div class="coution">
      <span *ngIf="userGroup.controls.email.errors?.required"><i class="fas fa-exclamation-triangle"></i> 필수 입력 항목입니다.</span>
      <span *ngIf="userGroup.controls.email.errors?.pattern"><i class="fas fa-exclamation-triangle"></i> 이메일 형식이 바르지 않습니다.</span>
    </div>
  </div>


  <div class="field">
    <label for="">주소</label>
    <input type="text" placeholder="기본주소" id="address" readonly="readonly" (click)="addr()"/>
  </div>
  <div class="field">
      <label for="">상세주소</label>
      <input type="text" placeholder="상세주소" formControlName="address2"/>
    </div>

  <div class="btn-group tac">
    <div class="di">
      <button type="submit" class="btn btn-apply" [disabled]="!userGroup.valid">회원가입</button>
    </div>
  </div>
</div>
</form>