import { Component } from '@angular/core';

declare var $ : any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'template';

  ngOnInit(){
    $(document).bind("contextmenu", function(e) {
      return false;
    });

    $(document).bind('selectstart',function() {return false;}); 
    $(document).bind('dragstart',function(){return false;}); 
  }

}
