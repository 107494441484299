<div class="science">
  <h5>과학기술문화와 에이징이란?</h5>
  <ul>
    <li>과학기술문화의 소외계층인 장노년층에게 과학기술에 대한 이해를 높이고 생활 속 과학문화를 실천할 수 있는 역량을 키워 <br class="break" />건강하고 활기찬 노년을 보낼 수 있도록 함
    </li>
    <li>지능정보화시대의 과학기술과 노년의 삶을 위하여 국내 최고 과학자들의 특강을 통해 액티브에이징의 구현방안을 모색함</li>
    <li>과학기술문화와 액티브에이징과 관련한 교육 실시 및 뇌 활동의 이해를 돕기 위해 간이뇌파측정기를 활용한 체험을 통하여 적극적인 적극적인 인지건강생활을 실천함</li>
  </ul>

  <div class="science-intro">
    <div class="tit">
      <img src="/assets/img/sub/science_txt.png" alt="과학기술문화와 함께하는 Active Aging">
    </div>
    <div class="bar"></div>

    <div class="list">
      <div class="class">
        <strong>특강</strong>
        <ul>
          <li>로봇의 진화와 의료로봇의 활용</li>
          <li>뇌과학 및 뇌신경</li>
          <li>인공지능과 예술</li>
          <li>과학기술과 액티브에이징</li>
        </ul>
      </div>
    </div>

    <div class="list double">
      <div class="edu">
        <strong>교육</strong>
        <ul>
          <li>과학기술문화와 Active Aging</li>
        </ul>
      </div>
      <div class="act">
        <strong>체험</strong>
        <ul>
          <li>인지기능, 뇌훈련 노인교구, 간이 뇌파 측정기</li>
        </ul>
      </div>
    </div>

    <strong>장노년층의 과학기술 이해증진</strong>

  </div>



  <h5>특강 안내</h5>

  <ul>
    <li>지능정보화 시대에 노년의 삶을 증진시킬 수 있는 과학기술 영역의 이해</li>
    <li>21세기 새롭게 조명 받는 인공지능, 로봇, 뇌과학을 통한 건강하고 활기찬 장노년을 구성할 수 있는 역량</li>
    <li>도시뿐만 아니라 군단위 지역에서 생활하는 노인에게도 세상의 변화를 공유함</li>
    <li>전문가, 시민, 노인 모두가 함께 공유할 수 있는 내용의 특강</li>
    <li>과학기술문화와 노년의 보편적, 친화적 접근을 지향하도록 노력함</li>
  </ul>

  <p>연사 소개</p>

  <div class="professor">
    <ul>
      <li>
        <em>“로봇의 진화와<br/>의료로봇 활용”</em>
        <img src="/assets/img/sub/professer01.jpg" alt="">
        권동수 교수 <span>카이스트</span>
      </li>
      <li>
        <em>“뇌과학 및<br />뇌신경”</em>
        <img src="/assets/img/sub/professer02.jpg" alt="">
        최진영 교수 <span>서울대학교</span>
      </li>
      <li>
        <em>“과학기술과<br/>액티브에이징”</em>
        <img src="/assets/img/sub/professer03.jpg" alt="">
        캐서린 브라운 <span>하와이 주립대학교</span>
      </li>
      <li>
        <em>“장노년층을 위한<br />인공지능과 예술”</em>
        <img src="/assets/img/sub/professer04.jpg" alt="">
        김태희 교수 <span>영상대학교</span>
      </li>
      <li>
        <em>“과학기술문화와<br/>함께 하는 노년”</em>
        <img src="/assets/img/sub/professer05.jpg" alt="">
        한동희 대표 <span>노인생활과학연구소</span>
      </li>
      <li>
        <em>“간이뇌파 측정과<br />뇌운동”</em>
        <img src="/assets/img/sub/professer06.jpg" alt="">
        황혁 대표
      </li>
    </ul>
  </div>



  <h5 class="mt100">체험 안내</h5>

  <ul>
      <li>노인교구와 뇌운동 체험</li>
      <li>노인교구의 다양한 활용과 주의 집중력 및 이완 훈련</li>
      <li>장노년의 새로운 문화를 과학적 에비던스를 근거로 활동</li>
      <li>노인생활과학연구소가 개발한 마음의 영양소를 체험함</li>
  </ul>

  <img src="/assets/img/sub/activity.jpg" alt="">



  <p class="mt20">특강</p>

  <div class="responsive">
    <div class="info">좌우로 탭하여 내용을 확인하실 수 있습니다.</div>

    <div class="scroll">
      <table class="tbl">
        <caption></caption>
        <thead>
          <tr>
            <th width="100"></th>
            <th>주제</th>
            <th>강연자</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>1차</th>
            <td>노년과 인공지능</td>
            <td>김태희 교수 (영산대학교)</td>
          </tr>
          <tr>
            <th>2차</th>
            <td>로봇의 진화와 의료로봇 활용</td>
            <td>권동수 교수 (카이스트)</td>
          </tr>
          <tr>
            <th>3차</th>
            <td>과학기술과 액티브에이징</td>
            <td>최진영 교수 (서울대학교)<br />캐서린 브라운 교수 (하와이 주립대학교)</td>
          </tr>
          <tr>
            <th>4차</th>
            <td>과학기술문화와 함께하는 노년</td>
            <td>한동희 대표 (노인생활과학연구소)</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>



  <p class="mt20">체험</p>

  <div class="responsive">
    <div class="info">좌우로 탭하여 내용을 확인하실 수 있습니다.</div>

    <div class="scroll">
      <table class="tbl">
        <caption></caption>
        <thead>
          <tr>
            <th width="100"></th>
            <th>일정</th>
            <th>내용</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>1차</th>
            <td>6월</td>
            <td class="tal" rowspan="4">
              - 뇌운동의 필요성<br />
              - 노인교구와 뇌운동<br />
              - 뇌의 집중과 이완 관찰 등
            </td>
          </tr>
          <tr>
            <th>2차</th>
            <td>9월</td>
          </tr>
          <tr>
            <th>3차</th>
            <td>10월</td>
          </tr>
          <tr>
            <th>4차</th>
            <td>11월</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <dl class="mt20">
    <dt>주최 : </dt>
    <dd>
      <img src="/assets/img/common/logo.png" alt="">
    </dd>
  </dl>

  <dl>
    <dt>후원 : </dt>
    <dd>
        <img src="/assets/img/common/support01.png" alt="">
        <img src="/assets/img/common/support02.png" alt="">
        <img src="/assets/img/common/support03.png" alt="">
    </dd>
  </dl>

</div>