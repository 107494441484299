<ul>
  <li>
    <strong>
      환영합니다<br />
      노년기 삶의 질을 높이는<br/>
      실천연구기관<br />
      노인생활과학연구소입니다
    </strong>

    <span><img src="/assets/img/common/ceo.jpg" alt=""></span>

    1997년에 개소된 노인생활과학연구소는 노인의 삶의 질을 높이기 위한 연구, 프로그램개발, 교육 등을 수행하고 있습니다. 여러 차례 홈페이지를 개편하였지만 금번에는 스마트폰에서 손쉽게 정보와 검색이 가능하도록 홈페이지를
    고령친화적 관점에서 개편해 보았습니다.<br/><br/>

    나이듦은 시대에 따라 다양한 해석이 필요하다 봅니다. 21세기 우리는 노년을 보다 생산적이고 활기차게 보낼 수 있도록 그 방향을 긍정적으로 모색해야 합니다.<br/>
    길어진 노년과 인구 고령화는 우리 사회 곳곳의 변화를 요구하고 있습니다.<br/><br/>

    사단법인 노인생활과학연구소에서는 20년간의 구축된 노하우를 바탕으로 노년이 행복한 나라! 건강하고 활기찬 노년을 만들어가는 도시! 나이 듦의 보람이 가득한 사회를 만들기 위해 노력하겠습니다.<br/><br/>

    국내외 노년학자에서부터 노인에 이르기까지 다양하게 연구소 홈페이지를 활용할 수 있도록 노력하겠습니다.<br/>
    여러분의 귀한 동참과 아낌없는 관심을 부탁드립니다.<br/><br/><br/>

    <em>
      사단법인 노인생활과학연구소장<br />
      한동희
    </em>

  </li>
  <li>
    <span><img src="/assets/img/common/ceo.jpg" alt=""></span>
    Research Institute of Science for the Better Living of the Elderly(RISBLE) was founded 1997. We have done action researches and programs for the elderly and their community to improve the quality of life for the late life.<br/><br/>

    RISBLE won Korean government awards for “Informatization and Digital Aging for the elderly”. Cyber family, Cyber neighbors, Internet navigator and Narrative Aids are representative programs. RISBLE has done lots of action researches; active aging, elderly care, elder abuse, empowerment caregivers, digital aging and rethinking ageing.<br/><br/>

    According to National Statistic Korea, the older population reached 7,684,919(14.9%) in Korea now. Korea became an aging society in 2000, when the percentage of persons 65 and over was 7.2% and an aged society in 2017, when the percentage reached 14.3%. Korea is expected to become a super aged society in 2026, when the percentage of older adults reaches 21.4%. The ratio will reach 41.0% in 2060.<br/>
    With population aging in Korea, there are needs for the elderly care will continually to be high and that Korea should prepare for it. The quality of late life in aged society will be a critical issue.<br/><br/>

    RISBLE continually efforts to lead active aging, digital aging and informal care services for the elderly. They will lead to rethink of aging and challenge to wellageing.<br/><br/>

    We will share missions of active aging to come true wellageing with you.<br/><br/><br/>
    
    <em>
      President/Director<br/>
      Donghee Han<br/>
      Research Institute of Science for the Better Living of the Elderly
    </em>
  </li>
</ul>
