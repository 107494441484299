import { Routes, RouterModule } from '@angular/router';
import { SubComponent } from './sub.component';
import { BoardComponent } from './board/board.component';
import { BoardViewComponent } from './board-view/board-view.component';
import { ContactComponent } from './contact/contact.component';
import { GreetingComponent } from './greeting/greeting.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { LoginComponent } from './member/login/login.component';
import { JoinComponent } from './member/join/join.component';
import { VisionComponent } from './vision/vision.component';
import { HistoryComponent } from './history/history.component';
import { ResearchComponent } from './research/research.component';
import { BusinessComponent } from './ageing/business/business.component';
import { ParishBusinessComponent } from './parish/parish-business/parish-business.component';
import { ParishProgramComponent } from './parish/parish-program/parish-program.component';
import { ScienceBusinessComponent } from './science/science-business/science-business.component';
import { AccountFindComponent } from './member/account-find/account-find.component';
import { AccountCheckComponent } from './member/account-check/account-check.component';
import { ModifyComponent } from './member/modify/modify.component';
import { OrganizationComponent } from './organization/organization.component';
import { ParishTeacherComponent } from './parish/parish-teacher/parish-teacher.component';
import { AcapComponent } from './ageing/acap/acap.component';
import { DegitalAgeingComponent } from './science/degital-ageing/degital-ageing.component';
import { QnaWriteComponent } from './qna-write/qna-write.component';
import { AgeingMediaComponent } from './ageing/ageing-media/ageing-media.component';
import { ScienceCultureComponent } from './science/science-culture/science-culture.component';

export const SubRoutes: Routes = [
    {
        path: 'board/:menuId/:boardConfigId/:page/:searchTxt', component: SubComponent,
        children: [{ path: '', component: BoardComponent }]
    },
    {
        path: 'board/:menuId/:boardConfigId/:page', component: SubComponent,
        children: [{ path: '', component: BoardComponent }]
    },
    {
        path: 'boardView/:menuId/:boardConfigId/:boardId/:page', component: SubComponent,
        children: [{ path: '', component: BoardViewComponent }]
    },
    {
        // 연구소 소개 > 비전과 목표
        path: 'vision/:menuId', component: SubComponent,
        children: [{ path: '', component: VisionComponent }]
    },
    {
        // 연구소 소개 > 연혁
        path: 'history/:menuId', component: SubComponent,
        children: [{ path: '', component: HistoryComponent }]
    },
    {
        // 연구소 소개 > 연구분야
        path: 'research/:menuId', component: SubComponent,
        children: [{ path: '', component: ResearchComponent }]
    },
    {
        // 연구소 소개 > 찾아 오시는 길
        path: 'contact/:menuId', component: SubComponent,
        children: [{ path: '', component: ContactComponent }]
    },
    {
        // 연구소 소개 > 인사말
        path: 'greeting/:menuId', component: SubComponent,
        children: [{ path: '', component: GreetingComponent }]
    },
    {
        // 연구소 소개 > 조직도
        path: 'organization/:menuId', component: SubComponent,
        children: [{ path: '', component: OrganizationComponent }]
    },
    {
        // 액티브 에이징 > 액티브 에이징이란?
        path: 'active-ageing/:menuId', component: SubComponent,
        children: [{ path: '', component: BusinessComponent }]
    },
    {
        // 액티브 에이징 > ACAP
        path: 'acap/:menuId', component: SubComponent,
        children: [{ path: '', component: AcapComponent }]
    },
    {
        // 액티브 에이징 > Media
        path: 'ageing-media/:menuId', component: SubComponent,
        children: [{ path: '', component: AgeingMediaComponent }]
    },
    {
        // 노인교구 > 사업소개
        path: 'narrativeaids/:menuId', component: SubComponent,
        children: [{ path: '', component: ParishBusinessComponent }]
    },
    {
        // 노인교구 > 프로그램
        path: 'narrativeaids-program/:menuId', component: SubComponent,
        children: [{ path: '', component: ParishProgramComponent }]
    },
    {
        // 노인교구 > 노인교구지도사
        path: 'narrativeaids-trinner/:menuId', component: SubComponent,
        children: [{ path: '', component: ParishTeacherComponent }]
    },
    {
        // 과학기술과 노년 > 웰에이징 과학문화
        path: 'science-culture/:menuId', component: SubComponent,
        children: [{ path: '', component: ScienceCultureComponent }]
    },
    {
        // 과학기술과 노년 > 과학기술문화와 에이징
        path: 'agingtech/:menuId', component: SubComponent,
        children: [{ path: '', component: ScienceBusinessComponent }]
    },
    {
        // 과학기술과 노년 > 디지털 에이징
        path: 'degital-ageing/:menuId', component: SubComponent,
        children: [{ path: '', component: DegitalAgeingComponent }]
    },
    {
        // 열린광장 > 묻고답하기 글쓰기
        path: 'qna-write/:menuId', component: SubComponent,
        children: [{ path: '', component: QnaWriteComponent }]
    },
    {
        // 맴버쉽 > 이용약관
        path: 'terms/:menuId', component: SubComponent,
        children: [{ path: '', component: TermsComponent }]
    },
    {
        // 맴버쉽 > 개인정보처리방침
        path: 'privacy/:menuId', component: SubComponent,
        children: [{ path: '', component: PrivacyComponent }]
    },
    {
        // 맴버쉽 > 사이트맵
        path: 'sitemap/:menuId', component: SubComponent,
        children: [{ path: '', component: SitemapComponent }]
    },
    {
        // 맴버쉽 > 로그인
        path: 'login/:menuId', component: SubComponent,
        children: [{ path: '', component: LoginComponent }]
    },
    {
        // 맴버쉽 > 회원가입
        path: 'join/:menuId', component: SubComponent,
        children: [{ path: '', component: JoinComponent }]
    },
    {
        // 맴버쉽 > 아이디 및 비밀번호 찾기
        path: 'account-find/:menuId', component: SubComponent,
        children: [{ path: '', component: AccountFindComponent }]
    },
    {
        // 맴버쉽 > 아이디 및 비밀번호 확인
        path: 'account-check/:menuId', component: SubComponent,
        children: [{ path: '', component: AccountCheckComponent }]
    },
    {
        // 맴버쉽 > 회원정보수정
        path: 'modify/:menuId', component: SubComponent,
        children: [{ path: '', component: ModifyComponent }]
    }
];

export const SubRoutes_Provider = RouterModule.forRoot(SubRoutes, { useHash: true });  
