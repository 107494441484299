<div class="translate-tab">
  <button data-tab="tab01" class="active">국문</button>
  <button data-tab="tab02">ENG</button>
</div>


<div id="tab01" class="translate">

  <div class="active-ageing">

    <!-- <strong>액티브에이징이란?</strong> -->
    <img src="/assets/img/sub/ageing.jpg" alt="">
    노인문제는 노인 스스로의 인식변화와 노년기를 적극적으로 생활해 나갈 수 있는 사회적 배경없이는 긍정적 노년기를 기대할 수 없음을 인식하기 시작하였음.<br /><br />

    이에 2002년 스페인 마드리드에서 열린 세계고령자회의에서는 ‘액티브 에이징
    (Active Aging)’을 주요한 의제로 다루었음.<br /><br />

    활기찬 노년(Active Aging)이란 생산적 노년과 성공적 노년의 개념을 모두 포함하고 있으며 노년생활의 주체인 노인들을 보다 적극적으로 사회에 참여하게 하고 건강을 오래 지킬 수 있는 노인개인의 노력과
    사회정책적 배경을 만들며 안전한 생활을 유지시킬수 있도록 하는 노년기 삶의 최적 환경을 만들어 내는 것을 의미함.<br /><br />

    이는 단순히 신체적 측면만을 고려한 안티에이징의 개념이 아니라 노인 스스로 적극적 노년을 살아갈 수 있는 의지를 토대로 노인이 살기 편한 고령친화적 환경과 노인이 부담이 아닌 자원으로서 사회에 이바지 할 수
    있으며 생이 다할 때까지 건강한 삶을 유지하는 것임. 또한 디지털 환경과 과학기술을 활용하여 적극적 노년을 만들어 나갈 수 있도록 의미함.
  </div>

  <h5>액티브 에이징을 위해서는?</h5>
  <ul class="bg">
    <li>건강하고 활기찬 노년을 유지하도록 노력</li>
    <li>고령친화환경 제공</li>
    <li>노년기에도 적극적으로 사회에 지속적으로 참여</li>
    <li>자신이 오랫동안 살아온 가정과 지역사회에서의 돌봄 </li>
  </ul>

</div>


<div id="tab02" class="translate">

  <div class="active-ageing">
    <!-- <strong>What is Active Aging?</strong> -->
    <img src="/assets/img/sub/ageing_eng.jpg" alt="">
    Active Aging, a concept supported by the World Health Organization (WHO), is "the process of optimizing
    opportunities for health, participation, and security in order to enhance quality of life as people
    age."<br /><br />

    As a response to global population aging, this means that we can no longer afford to think of elders as
    "non-contributors" or burdensome.<br /><br />

    We must encourage and support elders to participate in life and continue to share their resources.<br /><br />

    We do this by promoting individual and family practices and social policies that promote personal participation,
    health, and security throughout the life course.
  </div>

  <h5>Recommendations for Active Aging</h5>
  <ul class="bg">
    <li>Support healthy aging</li>
    <li>Build age-friendly communities</li>
    <li>Promote participation of older adults</li>
    <li>Favor home and community care over institutional care</li>
  </ul>

</div>