import { Component, OnInit } from '@angular/core';

declare var $ : any;

@Component({
  selector: 'app-ageing-media',
  templateUrl: './ageing-media.component.html',
  styleUrls: ['./ageing-media.component.scss']
})
export class AgeingMediaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $.get(
      "https://www.googleapis.com/youtube/v3/playlistItems",{
        part : 'snippet',
        maxResults : 50,
        playlistId : 'PLd2-g96ELOCi_HUnaIwPH5MhpFpuYpvjL', // You can get one from Advanced settings on YouTube
        type : 'video',
        key: 'AIzaSyDabzOho_SNdaDPoFkjSEDtPzwI6x4rwgs'
      },
        function(data) {
          $.each( data.items, function( i, item ) {
        var id = item.snippet.resourceId.videoId;
        var title = item.snippet.title;
        var url = 'https://www.youtube.com/watch?v=' + item.id;
        var thumb = item.snippet.thumbnails.default.url;
        var time = item.snippet.publishedAt;
  
  
  
  
            $('#results1').append('<li>https://www.youtube.com/watch?v=' + id + '</li>');
        $("#results1").append("<div><img src='" + thumb + "' /></div>");
        $("#results1").append("<div>" + thumb + "</div>");
        $("#results1").append("<div>" + title + "</div>");
        $("#results1").append("<div>" + time + "</div>");
          });
        }
    );
  }

}
