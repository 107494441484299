import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { CommonHttpService } from 'src/app/services/common-http.service';
import { Router } from '@angular/router';
import { PasswordValidation } from '../password-validation';
import { environment } from 'src/environments/environment';
import { UseridValidationService } from '../userid-validation.service';

declare var $: any;
declare var daum: any;

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})
export class JoinComponent implements OnInit {

  agree: boolean = false;
  userGroup: FormGroup;
  passwordFormGroup: FormGroup;
  user: any = {};

  constructor(fb: FormBuilder,
    private commonService: CommonService,
    private commonHttpService: CommonHttpService,
    private useridValidationService: UseridValidationService,

    private router: Router,
  ) {

    this.passwordFormGroup = fb.group({
      password: ['', Validators.compose([Validators.required, Validators.pattern(/[a-zA-Z0-9]{6,20}$/)])],
      repeatPassword: ['', Validators.required]
    }, {
        validator: PasswordValidation.validate.bind(this)
      });

    this.userGroup = fb.group({
      userId: ['', Validators.compose([Validators.required, Validators.pattern("^(?=.*[0-9]+)[a-zA-Z][a-zA-Z0-9]{5,13}$")]),
        this.useridValidationService.validate.bind(this.useridValidationService)],
      passwordFormGroup: this.passwordFormGroup,
      userName: [null, Validators.compose([Validators.required])],
      birthday: [null, Validators.compose([])],
      tel: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required, Validators.pattern(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)])],
      address2: [null, Validators.compose([])],
    });
  }

  ngOnInit() {

  }

  saveUser() {
    if (!this.agree) {
      alert('개인정보 활용에 동의가 필요합니다.');
      return false;
    }

    this.user.userId = this.userGroup.value.userId.trim();
    this.user.userName = this.userGroup.value.userName;
    this.user.password = this.passwordFormGroup.value.password;
    this.user.tel = this.userGroup.value.tel;
    this.user.email = this.userGroup.value.email;
    this.user.birthday = this.userGroup.value.birthday;
    this.user.isDel = 'N';
    this.user.siteId = environment.siteId;
    this.user.address1 = $('#address').val();
    this.user.address1 = this.userGroup.value.address2;

    this.commonHttpService.saveObject(this.user, '/user/save.do').subscribe(usr => {
      this.user = usr;
      alert('성공적으로 회원가입 하셨습니다.');
      this.router.navigate(['/']);
    });
  }


  addr() {
    new daum.Postcode({
      oncomplete: function (data) {
        // 우편번호와 주소 정보를 해당 필드에 넣는다.
        (document.getElementById("address") as HTMLInputElement).value = data.roadAddress;
        // 커서를 상세주소 필드로 이동한다.
        document.getElementById("detail-address").focus();
      }
    }).open();
  }

}
