<div id="header">
  <!-- bg -->
  <div class="dim"></div>

    <!-- 햄버거 버튼 시작 -->
    <div class="hamburger" (click)="hamburger()">
      <div class="box">
        <div class="bar"></div>
      </div>
    </div>
    <!-- 햄버거 버튼 끝 -->

    <!-- 로고 -->
    <a [routerLink]="['/']" id="logo" class="logo" title="로고"><img src="/assets/img/common/logo.png" alt=""></a>


    <!-- 퀵버튼(사이트맵, 오시는 길, 회원가입, 로그인) -->
    <div class="qnb">
      <ul class="quick">
        <!-- <li><a [routerLink]="['/sitemap/1225']"><span>사이트 맵</span></a></li> -->
        <li><a [routerLink]="['/contact/1194']"><span>오시는 길</span></a></li>
      </ul>
      <ul class="member" *ngIf="!user?.id" >
        <li><a [routerLink]="['/join/1222']"><span>회원가입</span></a></li>
        <li><a [routerLink]="['/login/1221']"><span>로그인</span></a></li>
      </ul>
      <ul class="member" *ngIf="user?.id" >
        <li><a [routerLink]="['/modify/1231']"><span>회원정보 수정</span></a></li>
        <li><a [routerLink]="['/']" (click)="logout()">{{user.userName}}님 환영합니다.</a></li>
      </ul>
    </div>

    <!-- PC GNB 시작 -->
    <div id="gnb">
      <ul (mouseenter)="gnbEnter()" (mouseleave)="gnbLeave()">
        <ng-container *ngFor="let menu of menus | async ; let index = index;">
          <li *ngIf="menu.id != 1220">
            <a [routerLink]="menu.linkUrl" [attr.data-text]="menu.linkUrl"><span>{{menu.name}}</span></a>
            <ul>
              <li *ngFor="let subMenu of menu.subMenus; let subIndex = index;" >
                <button *ngIf="subMenu.menuType == 'F'" (click)="toggleGnb('toggleGnbId_' + subIndex)" [id]="'toggleGnbId_' + subIndex"><span>{{subMenu.name}}</span></button>
                <a href="javascript:;" *ngIf="subMenu.menuType != 'F'" (click)="clickMenu(subMenu)"><span>{{subMenu.name}}</span></a>
                <ul>
                  <li *ngFor="let thirdMenu of subMenu.subMenus; let thirdIndex = index;">
                    <a *ngIf="thirdMenu.target != 'N'" href="javascript:;" (click)="clickMenu(thirdMenu)"><span>{{thirdMenu.name}}</span></a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
    <!-- PC GNB 끝 -->

    <!-- Mobile GNB 시작 -->
    <div id="mnb">
      <!-- GNB Bg -->
      <button class="mnb-close" (click)="mnbClose()"></button>
      <ul>
        <ng-container *ngFor="let menu of menus | async ; let index = index;">
          <li *ngIf="menu.id != 1220">
            <button (click)="toggleGnb('toggleMnbId_' + index)" [id]="'toggleMnbId_' + index"><span>{{menu.name}}</span></button>
            <ul>
              <li *ngFor="let subMenu of menu.subMenus; let subIndex = index;">
                <button *ngIf="subMenu.menuType == 'F'"><span>{{subMenu.name}}</span></button>
                <a href="javascript:;" *ngIf="subMenu.menuType != 'F'" (click)="clickMenu(subMenu)"><span>{{subMenu.name}}</span></a>
                <ul>
                  <li *ngFor="let thirdMenu of subMenu.subMenus; let thirdIndex = index;">
                    <a href="javascript:;" (click)="clickMenu(thirdMenu)"><span>{{thirdMenu.name}}</span></a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>

      <div class="mnb-member" *ngIf="!user?.id" >
        <a [routerLink]="['/join/1222']">회원가입</a>
        <a [routerLink]="['/login/1221']">로그인</a>
      </div>
      <div class="mnb-member" *ngIf="user?.id" >
        <a [routerLink]="['/join/1222']">회원정보 수정</a>
        <a [routerLink]="['/']" (click)="logout()">{{user.userName}}님 환영합니다.</a>
        
      </div>
    </div>
    <!-- Mobile GNB 끝 -->

</div>
