<div class="degital-ageing">
    <h5>디지털 에이징은</h5>
    <ul>
        <li>노인생활과학연구소는 디지털 에이징을 노년기 신노년 문화로 펼치고 있음</li>
        <li>디지털 에이징은 온라인과 오프라인을 연결하는 스마트 기기를 활용하여 노인의 생활을 더욱 윤택하게 하는데 목적이 있음</li>
        <li>지식정보화 시대를 살아가는 장노년을 위한 디지털 정보화 교육, 다양한 프로그램 개발을 위한 연구 실천을 수행하고 있음</li>
    </ul>

    <div class="intro">
        <strong>디지털 에이징이란?</strong>
        <ul>
            <li>디지털 에이징은 장노년층의 신체적, 정신적, 사회적 활동성을 증진하고 장노년을 보다 독립적으로 살아갈 수 있도록 과학기술(로봇, 인공지능, 빅데이터, 스마트 기기 등)과 디지털 문화(놀이,
                여가, 여행, 교육, 게임, 건강, 예술, 예술치료 등)를 활용하여 생산적이고 활기찬 노후를 보낼 수 있도록 함. 즉 디지털정보기기를 통해 신중년의 삶의 질을 높이는 모든 활동을 의미함</li>
        </ul>
    </div>


    <h5 class="mt100">주요 활동</h5>
    <ul>
        <li>장노년층을 위한 정보문화 선도</li>
        <li>인구고령화의 대안으로 디지털 에이징 문화를 보급하고, 특히 노인을 사회 통합시킬 수 있는 방안으로 사이버가족,<br class="break"/> 세대통합 토론회 등 건전정보문화 확산에 기여</li>
        <li>인구고령화의 적극적인 대안인 디지털 에이징(과학과 기술로 긍정적 노년 만들기)의 필요성을 <br class="break"/>국내외 학회발표, 사설기고, 언론기획, 토론회 등에서 발표 및 행사기획</li>
        <li>해외(일본, 태국, 미국, 이스라엘, 몽고, 인도네시아, 호주, 싱가포르 등)에서 한국을 방문하여 디지털에이징과 건전정보문화 보급에 관련된<br class="break"/> 견학, 워크샵 제공</li>
        <li>2015년부터 미국 시니어넷 해외이사로 활동함</li>
        <li>사단법인 노인생활과학연구소는 제18회 정보문화대상, 제31회 대통령표창을 받았음</li>
    </ul>

    <div class="performance">
        <img src="/assets/img/sub/performance01.jpg" alt="">
        <img src="/assets/img/sub/performance02.jpg" alt="">
        <img src="/assets/img/sub/performance03.jpg" alt="">
        <img src="/assets/img/sub/performance04.jpg" alt="">
        <img src="/assets/img/sub/performance05.jpg" alt="">
        <img src="/assets/img/sub/performance06.jpg" alt="">
        <img src="/assets/img/sub/performance07.jpg" alt="">
        <img src="/assets/img/sub/performance08.jpg" alt="">
        <img src="/assets/img/sub/performance09.jpg" alt="">
    </div>


</div>