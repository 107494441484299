import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { CommonHttpService } from 'src/app/services/common-http.service';

@Component({
  selector: 'app-modify',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.scss']
})
export class ModifyComponent implements OnInit {

  userGroup: FormGroup;
  passwordFormGroup: FormGroup;
  user: any;

  constructor(fb: FormBuilder,     
    private commonService: CommonService,
    private commonHttpService: CommonHttpService,
  ) {

    this.userGroup = fb.group({
      userId: [null],      
      userName: [null],
      birthday: [null], 
      password: [null],
      repeatPassword: [null],   
      tel: [null, Validators.compose([])],   
      email: [null, Validators.compose([Validators.required, Validators.pattern(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)])],
    });
  }

  ngOnInit() {
    this.user = this.commonService.getSessionItem('currentUser');
    this.userGroup.patchValue(this.user);
  }

  saveUser() {    
    //this.user.userName = this.userGroup.value.userName;
    //this.user.password = this.passwordFormGroup.value.password;
    var re = /[a-zA-Z0-9]{6,20}$/;
    if (this.userGroup.value.password && !re.test(this.userGroup.value.password)) {
      alert('패스워드는 숫자와 영문자 조합으로 6~20자리를 사용해야 합니다.');
      return false;
    }
    if (this.userGroup.value.password && this.userGroup.value.password != this.userGroup.value.repeatPassword) {
      alert('패스워드 확인이 맞지 않습니다.');
      return false;
    }
    this.user.tel = this.userGroup.value.tel;
    this.user.email = this.userGroup.value.email;
    this.user.birthday = this.userGroup.value.birthday;
    
    this.commonHttpService.saveObject(this.user, '/user/update.do').subscribe(usr => {
        this.user = usr;      
        alert('정상처리 되었습니다.');
    });
  }

}
