import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonHttpService } from 'src/app/services/common-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {

  serviceUrl : string = environment.serviceUrl + '/fileInfo/downloadFile.do?id=';
  menuId: any;
  page : number = 1;
  maxPage : number;
  blockSize : number;
  block : number = 0;
  blockStartPage : number;
  blockEndPage : number;

  boardConfigId : number;
  boardConfig : any = {};
  boards : any[] = [];
  boardCnt : number = 0;

  blockPages = [];


  searchType : string = 'title';
  searchTxt : string = '';

  user: any;

  constructor(private commonHttpService: CommonHttpService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,) {

      this.route.params.subscribe(params => {
        this.menuId = parseInt(this.route.snapshot.params['menuId'], 0);
        this.boardConfigId = parseInt(this.route.snapshot.params['boardConfigId'], 0);
        this.page = parseInt(this.route.snapshot.params['page'], 0);
        this.user = this.commonService.getSessionItem('currentUser');

        this.commonHttpService.findObject('/boardConfig/getBoardConfigOne.do?id=' + this.boardConfigId).subscribe(
          boardConfig => {
            this.boardConfig = boardConfig;            
            this.blockSize = this.boardConfig.pagesize;
            this.findBoard();
          }
        );
    });  

  }

  ngOnInit() {    

  }


  member(){
    alert("노인생활과학연구소 회원만 다운로드 하실 수 있습니다.");
  }

  prevPageChanged() {
    if (this.page == this.blockStartPage) {
      alert('처음 페이지 입니다');
      return false;
    } else {
      this.page = this.page - 1;
      this.pageChanged(this.page);
    }
  }

  nextPageChanged() {
    if (this.page == this.blockEndPage) {
      alert('마지막 페이지 입니다');
      return false;
    } else {
      this.page = this.page + 1;
      this.pageChanged(this.page);
    }
  }

  pageChanged(event : any) {
    this.page = event;

    if( this.searchTxt == '' ) {
      this.router.navigate(['/board/' + this.menuId + '/' + this.boardConfigId + '/' + this.page]);      
    } else {
      this.findBoard();
    }
    window.scrollTo(0, 0);
  }


  search() {
    this.page = 1;
    if( this.searchTxt == '' && this.page != 1) {
       this.router.navigate(['/board/' + this.menuId + '/' + this.boardConfigId + '/1']);
    } else {
      this.findBoard();
    }
  }

  findBoard() {
    this.commonHttpService.findObject('/getSimpleBoardListForTotalCnt.do?boardConfigId=' + this.boardConfig.id).subscribe(
      cnt => {
        this.boardCnt = cnt;
        this.maxPage  = Math.ceil(this.boardCnt / this.blockSize);
        if (this.maxPage < 1) {
          this.maxPage = 1;
        }

        this.block = (this.page-(this.page % this.blockSize)) / this.blockSize;
        this.blockStartPage = (this.block * this.blockSize) + 1;
        this.blockEndPage = (this.block + 1) * this.blockSize;

        if (this.blockEndPage > this.maxPage) {
          this.blockEndPage = this.maxPage;
        }
        
        this.blockPages = [];
        for (let i = this.blockStartPage; i <= this.blockEndPage; i++) {
          this.blockPages.push(i);
        }
      }
    );
    this.commonHttpService.findAll('/getSimpleBoardListForPage.do?boardConfigId=' + this.boardConfig.id + '&page=' + this.page).subscribe(
      boards => this.boards = boards,
    );
  }

}
