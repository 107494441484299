<div class="info">
  <ul>
    <li>노인생활과학연구소 홈페이지 사용에 있어 최적의 환경을 제공하고자합니다.</li>
    <li>홈페이지에서 제공하는 서비스에 대한 문의사항은 담당자에게 문의하시기 바랍니다.</li>
  </ul>
</div>


<div class="service">
  <strong>홈페이지를 보기 위한 최적의 컴퓨터 환경</strong>
  <ul>
    <li>브라우저 internet Explorer 8.0 이상 / 파이어폭스 / 크롬 / Safari를 지원하며, 화면해상도 1024 X 768 pixel 이상을 권합니다.</li>
    <li>그 이상의 해상도에서 보시면 보다 쾌적화면에서 더 많은 내용을 화면 스크롤 없이 보실 수 있습니다.</li>
  </ul>
</div>


<div class="service">
  <strong>홈페이지 회원이 되시면</strong>
  <ul class="bg">
    <li>노인생활과학연구소 홈페이지는 깨끗하고 건강한 인터넷 문화를 위하여 회원제로 운영되고 있습니다.</li>
    <li>회원님들께는 생생한 노인생활과학연구소 소식지과 마이페이지 등의 혜택이 주어집니다.</li>
  </ul>
</div>



<div class="service">
  <strong>홈페이지 메뉴구성</strong>
  <table class="tbl">
    <caption></caption>
    <thead>
      <tr>
        <th>메뉴</th>
        <th>구성</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th>연구소소개</th>
        <td>인사말, 비전과 목표, 연혁, 연구분야, 찾아오시는 길, 주요실적 콘텐츠를 제공합니다.</td>
      </tr>
      <tr>
        <th>연구소소식</th>
        <td>공지사항, 연구소칼럼, 보도자료, 포토갤러리, 자료실 콘텐츠를 제공합니다.</td>
      </tr>
      <tr>
        <th>주요사업</th>
        <td>액티브 에이징, 마음의 영양소 노인교구, 과학기술과 노년 콘텐츠를 제공합니다.</td>
      </tr>
      <tr>
        <th>열린광장</th>
        <td>노인교구지도사 네트워크 콘텐츠를 제공합니다.</td>
      </tr>
    </tbody>
  </table>
</div>




<div class="service">
    <strong>홈페이지 이용에 필요한 부가서비스</strong>
    <table class="tbl">
      <caption></caption>
      <thead>
        <tr>
          <th>아이콘</th>
          <th>기능</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th><span class="red">*</span></th>
          <td>필수항목을 의미합니다.</td>
        </tr>
      </tbody>
    </table>
  </div>