import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// Youtube iframe을 사용하기 위한 파이프
// 사용 예시 : <iframe width="100%" height="300" [src]="url | safe"></iframe>
// " | safe " 로 사용
// 현재는 sub 모듈에서 사용하기 위해 sub.module.ts에 선언

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
