<app-header></app-header>
<app-popup></app-popup>

<div id="container">
    <!-- 슬라이더 시작 -->
    <div class="swiper-container">  
        <!-- 슬라이더 리스트 -->
        <div class="swiper-wrapper">
            <div class="swiper-slide bg01"><a [routerLink]="['/narrativeaids/1208']" title="인지 정서 신체 활동을 지원하는 마음의 영양소 노인교구"><img src="/assets/img/main/visual_img04.png" alt=""></a></div>
            <div class="swiper-slide bg03"><img src="/assets/img/main/visual_img03.png" alt=""></div>
            <div class="swiper-slide bg02"><a [routerLink]="['/agingtech/1245']" title="과학, 노년 그리고 미래"><img src="/assets/img/main/visual_img02.png" alt=""></a></div>
        </div>

        <!-- 슬라이더 콘트롤 버튼 -->
        <div class="swiper-control">
            <button class="swiper-prev"></button>
            <button class="swiper-next"></button>
        </div>

        <div class="count">
            <span class="current"></span><span>/</span><span class="total"></span>
            <button class='pause'></button>
            <!-- <button class='play'></button> -->
        </div>

    </div>
    <!-- 슬라이더 끝 -->



    <!-- 공지사항 및 연구소 칼럼 시작 -->
    <div class="news">
        <div class="inner">
            <div class="notice">
                <ng-container *ngFor="let notice of notices; let i = index;" ngxSlickItem>
                    <div class="list">
                        <div class="date">
                            <strong>{{notice.updateTime | date:'dd'}}</strong>
                            <span>{{notice.updateTime | date:'yyyy.MM'}}</span>
                        </div>
                        <a [routerLink]="['/boardView/1195/110/' + notice.id + '/1']">
                            <strong>{{notice.title}}</strong>
                            <span [innerHTML]="notice.content | stripHtml"></span>
                        </a>
                    </div>
                </ng-container>
                <!-- <div class="list">
                    <div class="date">
                        <strong>29</strong>
                        <span>2019.08</span>
                    </div>
                    <a href="">
                        <strong>2019 제 2회 노인교구지도(2급) 자격검정시험(부산)공고</strong>
                        <span>10년 전 노인정보화교육을 시작했을 때 많은 살마들이 노인에게 컴퓨터 사용법을 가르치는 일은 의미가 없다고 했다. 그러나 지금은 곳곳에서 노인정보화
                            강좌가</span>
                    </a>
                </div>
                <div class="list">
                    <div class="date">
                        <strong>29</strong>
                        <span>2019.08</span>
                    </div>
                    <a href="">
                        <strong>2019 제 2회 노인교구지도(2급) 자격검정시험(부산)공고</strong>
                        <span>10년 전 노인정보화교육을 시작했을 때 많은 살마들이 노인에게 컴퓨터 사용법을 가르치는 일은 의미가 없다고 했다. 그러나 지금은 곳곳에서 노인정보화
                            강좌가</span>
                    </a>
                </div> -->
                <a href="" class="more" [routerLink]="['/board/1195/110/1']"></a>
            </div>
            <div class="column">
                <ng-container *ngFor="let coulumn of coulumns; let i = index;" ngxSlickItem>
                    <div class="list">
                        <a [routerLink]="['/boardView/1196/111/' + coulumn.id + '/1']">
                            <strong>{{coulumn.title}}</strong>
                            <span [innerHTML]="coulumn.content | stripHtml"></span>
                            <em class="date">{{coulumn.updateTime | date:'yyyy-MM-dd'}}</em>
                        </a>
                    </div>
                </ng-container>

                <a href="" class="more" [routerLink]="['/board/1196/111/1']"></a>
            </div>
        </div>
    </div>
    <!-- 공지사항 및 연구소 칼럼 끝 -->


    <!-- 배너 (액티브 에이징, 노인교구, 과학기술과 노년, 관련기관) 시작 -->
    <div class="banner">
        <div class="quick-banner">
            <ul>
                <li>
                    <a href="#" [routerLink]="['/active-ageing/1204']">
                        <strong>액티브 에이징</strong>
                        <span>건강하고 활기찬 노후</span>
                    </a>
                </li>
                <li>
                    <a href="#" [routerLink]="['/narrativeaids/1208']">
                        <strong>노인 교구</strong>
                        <span>인지, 정서 신체활동을 지원하는<br/>마음의 영양소</span>
                    </a>
                </li>
                <li>
                    <a href="#" [routerLink]="['/agingtech/1245']">
                        <strong>과학기술과 노년</strong>
                        <span>기술을 통한 새로운 도전</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/watch?v=yuSg0wZ0_cc" target="_blank">
                        <strong>채널</strong>
                        <span>장노년층을 위한 웰에이징<br/> 과학문화 활동편</span>
                    </a>
                </li>
            </ul>
        </div>

<!-- 
        <div class="support-banner">
            <div class="title">
                <strong>관련기관</strong>
                <div class="control">
                    <button class="support-prev"></button>
                    <button class="support-next"></button>
                </div>
            </div>
            <div class="list">
                <ul>
                    <li>
                        <span>
                            <a href="http://www.busan.go.kr/index" target="_blank"><img src="/assets/img/main/support01.jpg" alt=""></a>
                            부산광역시
                        </span>
                    </li>
                    <li>
                        <span>
                            <a href="https://www.kofac.re.kr/" target="_blank"><img src="/assets/img/main/support02.jpg" alt=""></a>
                            한국과학창의재단
                        </span>
                    </li>
                    <li>
                        <span>
                            <a href="http://www.yangsan.go.kr/main.do" target="_blank"><img src="/assets/img/main/support03.jpg" alt=""></a>
                            양산시
                        </span>
                    </li>
                    <li>
                        <span>
                            <a href="http://yscswc.or.kr/main/index.do" target="_blank"><img src="/assets/img/main/support04.jpg" alt=""></a>
                            양산시 노인복지관
                        </span>
                    </li>
                    <li>
                        <span>
                            <a href="https://sb50cam.blog.me/" target="_blank"><img src="/assets/img/main/support05.jpg" alt=""></a>
                            서울시 50플러스 재단
                        </span>
                    </li>
                    <li>
                        <span>
                            <a href="https://www.gwgs.go.kr/health/" target="_blank"><img src="/assets/img/main/support06.jpg" alt=""></a>
                            고성군 보건소
                        </span>
                    </li>
                    <li>
                        <span>
                            <a href="http://www.hadong.go.kr/health.web" target="_blank"><img src="/assets/img/main/support07.jpg" alt=""></a>
                            하동군 보건소
                        </span>
                    </li>
                    <li>
                        <span>
                            <a href="http://www.namhae.go.kr/health/Index.do" target="_blank"><img src="/assets/img/main/support08.jpg" alt=""></a>
                            남해군 보건소
                        </span>
                    </li>
                </ul>
            </div>
        </div> -->


    </div>
    <!-- 배너 (액티브 에이징, 노인교구, 과학기술과 노년, 관련기관) 끝 -->
</div>

<app-footer></app-footer>