<div class="parish">
  <!-- <h5>노인교구란?</h5> -->

  <div class="intro">
    <div class="pic"><img src="/assets/img/sub/parish1.png" alt=""></div>
    
    <ul>
      <li>노인의 사고력을 점진적으로 증진시킬 수 있으며, 내면의 생각을 보다 용이하게 표현하고, 심화 확대시켜 나갈 수 있음. 생각의 방향을 정리할 수 있으며, 노인과 소통하기 위해서 사용되는
        도구임</li>
      <li>노인의 생각을 만들고 표현하는 것을 돕고 여가와 소통을 가능하게 하는 도구임</li>
      <li>노인의 생활 및 건강증진을 위해 활용되며, 에피소드화 하는 활용 수단으로 사용함</li>
      <li>노인교구를 통해 소근육 활동, 시각, 촉각 등 감각기능 증진</li>
      <li>건강한 노인, 치매 노인, 노인과 함께 생활하고 있는 가족, 집단, 개인 등 다양하게 사용됨</li>
    </ul>

  </div>

  <p>노인교구 프로그램의 단계</p>

  <dl class="step1">
    <dt>1단계</dt>
    <dd>놀이도구(각종 놀이와 창의적 흥미 유발)</dd>
  </dl>
  <dl class="step2">
    <dt>2단계</dt>
    <dd>인지기능을 위한 도구(주의 집중력, 언어관련 능력, 학습 및 기억능력 등)</dd>
  </dl>
  <dl class="step3">
    <dt>3단계</dt>
    <dd>의사소통 역량 개발 등</dd>
  </dl>

  <div class="parish-program">
    <img src="/assets/img/sub/parish_program_pc.jpg" class="pc" alt="">
    <img src="/assets/img/sub/parish_program_mobile.jpg" class="mobile" alt="">
  </div>

  <h3>CEP프로그램</h3>
  <p>마음의 영양소 노인교구는 CEP프로그램으로 운영됩니다.</p>

  <div class="cep">
    <div class="cep-img">
    <img src="/assets/img/sub/parish_program.png">
    </div>
    <div class="circle">
      <span>C</span>

      <div class="cont">
        <h4>인지기능 (Cognitive Function)</h4>
        <ul>
          <li>노인의 인지능력 : 기억력, 지남력, 수리력, 공간적 지각, 전두엽 관리 기능 증진</li>
          <li>정서적, 신체적 기능과 함께 증진하는 인지기능</li>
        </ul>
      </div>
    </div>

    <div class="circle">
      <span>E</span>

      <div class="cont">
        <h4>정서기능 (Emotional Function)</h4>
        <ul>
          <li>놀이 : 즐거움, 창조적 사고 관계증진, 사회성 훈련</li>
          <li>이야기 : 언어관련 능력, 표현, 회상, 공감, 자아통합 훈련</li>
        </ul>
      </div>
    </div>

    <div class="circle">
      <span>P</span>

      <div class="cont">
        <h4>신체기능 (Physical Function)</h4>
        <ul>
          <li>신체활동 :  소근육, 대근육 훈련을 통한 신체 활동 향상</li>
        </ul>
      </div>
    </div>

  </div>

</div>