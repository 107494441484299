import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { CommonHttpService } from 'src/app/services/common-http.service';

@Component({
  selector: 'app-qna-write',
  templateUrl: './qna-write.component.html',
  styleUrls: ['./qna-write.component.scss']
})
export class QnaWriteComponent implements OnInit {

  user: any;

  group: FormGroup;
  
  constructor(public fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private commonHttpService: CommonHttpService,) {

    this.group = fb.group(
      {
        title:[null, Validators.compose([Validators.required])],
        content:[null],
      }
    );   
  }

  ngOnInit() {
    this.user = this.commonService.getSessionItem('currentUser');
  }

  save() {
    let user = this.commonService.getSessionItem('currentUser');
    var board: any = {};
    board.boardConfigId = 125;
    board.isDel = 'N';
    board.updateUserName = user.adminName;
    board.updateUserId = user.id;
    board.title = this.group.value.title;
    board.content = this.group.value.content;
    
    
    this.commonHttpService.saveObject(board, '/board/save.do').subscribe(obj => {
      window.alert('정상처리 되었습니다.');      
      this.router.navigate(['/board/1244/125/1']); 
    });
  }

  back() {
    this.router.navigate(['/board/1244/125/1']);
  }

}
