<!-- 연혁 탭 시작 -->
<div class="history-tab">
  <button data-tab="tab01" class="active">연혁</button>
  <button data-tab="tab02">수상내역</button>
  <button data-tab="tab03">심포지움 개최</button>
  <button data-tab="tab04">특강 및 발표</button>
</div>
<!-- 연혁 탭 끝 -->

<!-- 연혁 시작 -->
<div id="tab01" class="history">
  <div class="time">
    <strong>2020~</strong>
    <div class="thumb">
      <img src="/assets/img/sub/history12.jpg" alt="">
    </div>
    <ul>
      <li>
        <em>2020.12.07.~2020.12.14.</em>
        장노년층을 위한 웰에이징 과학문화 강연 실시 (한국과학창의재단 지원사업)
      </li>
      <li>
        <em>2020.11.16.~2020.11.30.</em>
        장노년층을 위한 웰에이징 과학문화 교육 실시 (한국과학창의재단 지원사업)
      </li>
      <li>
        <em>2020.11.02.</em>
        부산여성리더클럽을 위한 특강 실시
      </li>
      <li>
        <em>2020.10.12.~11.09.</em>
        장노년층을 위한 스마트폰 활용 교육 실시 (한국과학창의재단 지원사업)
      </li>
      <li>
        <em>2020.08.18.~08.26.</em>
        2020 제2회 노인교구지도사(2급) 역량강화 교육 실시
      </li>
      <li>
        <em>2020.08.01.~08.29.</em>
        2020 제 3회 노인교구지도사(2급) 양성교육 실시
      </li>
      <li>
        <em>2020.07.26.</em>
        ACAP(Active Aging Consortium Asia Pacific) 발표
      </li>
      <li>
        <em>2020.06</em>
        노인생활과학연구소 이전 (부산광역시 연제구 토현로 32 4층)
      </li>
      <li>
        <em>2020.05.18.~ 05.29.</em>
        제 2회 노인교구지도사(2급) 양성 교육 실시
      </li>
      <li>
        <em>2020.05.03.~05.31.</em>
        진주시 에나 기억 채움터 보건기관 종사자 역량 강화 교육 실시
      </li>
      <li>
        <em>2020.05</em>
        하동군 보건소 치매안심센터 치매 관리 사업 활성화를 위한 보건기관 직원 치매관리 역량 강화 교육 실시
      </li>
      <li>
        <em>2020.02.07.~04.10.</em>
        2020 제 1회 노인교구지도사(2급) 양성교육 실시
      </li>
    </ul>
  </div>
  
  <div class="time">
    <strong>2019~2015</strong>
    <div class="thumb">
      <img src="/assets/img/sub/history01.jpg" alt="">
    </div>
    <ul>
      <li>
        <em>2019.11.19.~2019.11.20.</em>
        제 4차 과학기술문화와 함께하는 Active Aging 특강 실시 (한국과학창의재단 지원사업)
      </li>
      <li>
        <em>2019.10.22.~2019.11.09.</em>
        하동군 보건소 치매안심센터 치매 관리 사업 활성화를 위한 보건기관 직원 치매관리 역량 강화 교육 실시
      </li>
      <li>
        <em>2019.10.04.</em>
        제 3차 과학기술문화와 함께 하는 Active Aging 특강 실시 (한국과학창의재단 지원사업)
      </li>
      <li>
        <em>2019.09.19. - 11.28.</em>
        2019년 제 3회 노인교구지도사(2급) 양성 교육
      </li>
      <li>
        <em>2019.08.07. - 08.28.</em>
        2019년 제 2회 노인교구지도사 역량강화 교육
      </li>
      <li>
        <em>2019.06.18. - 06.25.</em>
        과학기술문화와 함께하는 Active Aging 체험실시<br />
        노인교구과 뇌운동 / 뇌의 집중과 이완 관찰 / 뇌-마음-행동 훈련
      </li>
      <li>
        <em>2019.06.11.</em>
        제 1차 과학기술문화와 함께하는 Active Aging 특강 실시 (한국과학창의재단 지원사업)
      </li>
      <li>
        <em>2019.06.12.-07.10.</em>
        양산시 웅산보건지소 마음의 영양소 노인교구프로그램 수행
      </li>
      <li>
        <em>2019.05.04.-05.25.</em>
        2019 제1회 노인교구지도사 역량강화교육 실시
      </li>
      <li>
        <em>2019.04.23.-06.25.</em>
        2019 제2회 노인교구지도사(2급) 양성교육 실시
      </li>
      <li>
        <em>2019.04.15.</em>
        마음의 영양소 노인교구 도서 발간
      </li>
      <li>
        <em>2019.04.10.-06.19.</em>
        부산광역시 중구청 원로의 집 노인교구프로그램 수행
      </li>
      <li>
        <em>2019.03.21.-05.09.</em>
        서울 50플러스 재단 서부캠퍼스 노인교구지도사 양성교육 실시
      </li>
      <li>
        <em>2019.03.08.-05.17.</em>
        고성군 치매안심센터 직원 및 자원봉사자 역량강화교육 실시
      </li>
      <li>
        <em>2019.03.05.-03.26.</em>
        2019 원로의 집(경로당) 노인교구지도사 역량강화프로그램 실시
      </li>
      <li>
        <em>2019.02.22.-현재</em>
        고성군 치매안심센터 노인교구프로그램 수행
      </li>
      <li>
        <em>2019.01.18.-01.19.</em>
        태국 타마셋 대학 연구소 견학
      </li>
      <li>
        <em>2019.01.03.</em>
        2019 제1회 노인교구지도사(2급) 양성교육 실시
      </li>
      <li>
        <em>2018.11</em>
        노인 정보화 교육 공로 대통령 표창 수상
      </li>
      <li>
        <em>2018.09</em>
        한국문화예술진흥원 장년층을 위한 디지털에이징 기초조사 수행
      </li>
      <li>
        <em>2018.05</em>
        한국직업능력개발원 민간자격 등록(노인교구지도사 1급, 2급)
      </li>
      <li>
        <em>2018.02</em>
        국회국제보건의료포럼 “초고령사회 대비를 위한 액티브에이징연구” 수행
      </li>
      <li>
        <em>2018.01.12.</em>
        남해군 보건소 치매안심센터 보건기관 직원 치매관리 역량 강화 교육
      </li>
      <li>
        <em>2017.11.25.-12.02.</em>
        이스라엘 텔레비대학교 의과대학 교수 및 노화학자 버나교수 연구소방문
      </li>
      <li>
        <em>2017.10-12</em>
        야마구치대학교 75세 이상 노인들의 장수관련 연구 인터뷰조사
      </li>
      <li>
        <em>2017.08.29-11.28.</em>
        부산광역시 중구청지원 노인용교구지도사 양성(용두산공원학당운영)
      </li>
      <li>
        <em>2017.05</em>
        청암포스코 재단 노인돌봄 관련 연구 수행
      </li>
      <li>
        <em>2016.09.01.</em>
        일본 사사카와재단 연구소 방문
        (사사카와재단지원: 비공식적 돌봄체계 연구)
      </li>
      <li>
        <em>2016.07.29.</em>
        그랜드자연요양병원과 협약식
      </li>
      <li>
        <em>2016.04-현재</em>
        노인용 교구적용 프로그램 개발 및 지도사 양성
      </li>
      <li>
        <em>2015.05-2015.12</em>
        2015년 우수 평생학습 액티브 에이징 남구 프로그램 운영
      </li>
    </ul>
  </div>

  <div class="time">
    <strong>2014~2010</strong>
    <div class="thumb">
      <img src="/assets/img/sub/history02.jpg" alt="">
    </div>
    <ul>
      <li>
        <em>2014.08.1-31</em>
        일본 야마구치대학 공동 100세 노인 부산조사 수행
      </li>
      <li>
        <em>2013.06.27-29</em>
        제8회 아시아태평양액티브에이징 주관
      </li>
      <li>
        <em>2013.06.22</em>
        서울시와 한국노년학회가 함께하는 서울시노년학대회 사무국업무
        수행(8개국 참가)
      </li>
      <li>
        <em>2012.11.23</em>
        한국노년학회 추계학술대회 사무국 역할 (액티브에이징)
      </li>
      <li>
        <em>2012.07.01-2013.06.30</em>
        한국노년학회 사무국업무 수행
      </li>
      <li>
        <em>2012.06.11</em>
        태국 경제 산업진흥부 소속 연구원 연구소 방문
      </li>
      <li>
        <em>2012.06.01</em>
        부산교통방송 특집 노인교통안전 특별방송 출연
      </li>
      <li>
        <em>2010.01-12</em>
        노인일자리사업 인터넷 네비게이터 운영
      </li>
    </ul>
  </div>

  <div class="time">
    <strong>2009~2005</strong>
    <div class="thumb">
      <img src="/assets/img/sub/history03.jpg" alt="">
    </div>
    <ul>
      <li>
        <em>2009.12.29</em>
        제5회 치매가족수기발표회 및 집담회 개최
      </li>
      <li>
        <em>2009.11.04</em>
        중국 상해시인민우호협회 연구소 방문
      </li>
      <li>
        <em>2009.04.29</em>
        후쿠오카시 공무원과 AABC직원 직원 연구소 방문
      </li>
      <li>
        <em>2009.03.26</em>
        웰에이징센터 3호점 개설
      </li>
      <li>
        <em>2009.03.16</em>
        사이버 가족 일본 건국중학교 학생 및 교직원 방문
      </li>
      <li>
        <em>2009.01~12</em>
        부산광역시 치매가족지원망 사업 실시
      </li>
      <li>
        <em>2008.03.~11</em>
        노인일자리사업 "인터넷 네비게이트" 실시
      </li>
      <li>
        <em>2006.04.~12</em>
        “사이버가족”사업 실시
      </li>
      <li>
        <em>2006.04.~07</em>
        “은빛-브라보”협력기관으로 선정
      </li>
      <li>
        <em>2006.02.~현재</em>
        “고령자정보화교육장”사업 실시
      </li>
      <li>
        <em>2005.10.~현재</em>
        “어르신 IT봉사단”사업실시
      </li>
      <li>
        <em>2005.05.~2007.10</em>
        “노인콘텐츠”기획사업 실시
      </li>
      <li>
        <em>2005.03.~현재</em>
        "치매가족 프로그램" 사업 실시
      </li>
    </ul>
  </div>


  <div class="time">
    <strong>2004~1997</strong>
    <div class="thumb">
      <img src="/assets/img/sub/history04.jpg" alt="">
    </div>
    <ul>
      <li>
        <em>2004.05.~2005.12</em>
        "정보나눔실천공동협력"사업 실시
      </li>
      <li>
        <em>2004.04.~2005.12</em>
        “비영리민간단체공익사업”실시
      </li>
      <li>
        <em>2004.02.~2006.02</em>
        “고령자 사회적일자리”사업 수행
      </li>
      <li>
        <em>2004.02.~12</em>
        보건복지부지원 한국노인학대방지망(KINPEA) 사업 실시
      </li>
      <li>
        <em>2003.05.~2006.02</em>
        “소외계층평생교육”사업 실시
      </li>
      <li>
        <em>2001.03.08</em>
        부산광역시 비영리민간단체 등록
      </li>
      <li>
        <em>2000.10.~현재</em>
        노인정보화교육 실시
      </li>
      <li>
      <li>
        <em>1998.11.10.-12.16</em>
        동래구청의뢰 ’98 장수체육대학 프로그램 작성 및 강연, 운영
      </li>
      <li>
        <em>1998.09.16.-09.18</em>
        부산 YMCA 일하는 여성의 집, 제1기 치매환자 간병인 양성 교육 “치매노인의 가족 부양 부담감”
      </li>
      <li>
        <em>1998.07.20.~1999.01.20.</em>
        인제대학교 백병원 초청 건강상담 관리자 양성 교육 “노년기 가족생활교육 및 노인상담”
      </li>
      <li>
        <em>1998.03</em>
        노인대학 교육 프로그램 활성화를 위한 모임
      </li>
      <li>
        <em>1997.03 03</em>
        노인생활과학연구소 개소
      </li>
    </ul>
  </div>
</div>
<!-- 연혁 끝 -->

<!-- 수상내역 시작 -->
<div id="tab02" class="history">
  <div class="time">
    <strong>2018~2005</strong>
    <div class="thumb">
      <img src="/assets/img/sub/history06.jpg" alt="">
    </div>
    <ul>
      <li>
        <em>2018.06</em>
        제31회 정보문화의달 기념 디지털에이징 공로 – 대통령 포상
      </li>
      <li>
        <em>2015.08.27</em>
        고령친화산업 발전 및 진흥 표창장
      </li>
      <li>
        <em>2009.09</em>
        제2회 치매극복의 날 표창장
      </li>
      <li>
        <em>2005.06</em>
        제17회 정보문화상 정보문화대상
      </li>
    </ul>
  </div>
</div>
<!-- 수상내역 끝 -->

<!-- 심포지움 개최 시작 -->
<div id="tab03" class="history">
  <div class="time">
    <strong>2017~2010</strong>
    <div class="thumb">
      <img src="/assets/img/sub/history07.jpg" alt="">
    </div>
    <ul>
      <li>
        <em>2017. 10. 28</em>
        고베여자대학교 건강복지학부 요코야마교수 초청 일본의 노인돌봄과 도전 노년학특강
      </li>
      <li>
        <em>2016.10.18</em>
        바람직한 노인돌봄의 방향모색
      </li>
      <li>
        <em>2015.03</em>
        웰에이징학교 개강
      </li>
      <li>
        <em>2014.12.- 현재</em>
        아시아태평 양액티브에이징 컨소시엄 편집국
      </li>
      <li>
        <em>2013.06.27.-06.28</em>
        아시아태평양액티브에이징 공동주최 (공식 IAGG post conference로 운영)
      </li>
      <li>
        <em>2010.09.11</em>
        1080우린한가족게임한마당 개최
      </li>
    </ul>
  </div>

  <div class="time">
    <strong>2009~1998</strong>
    <div class="thumb">
      <img src="/assets/img/sub/history08.jpg" alt="">
    </div>
    <ul>
      <li>
        <em>2009.10.24</em>
        1080 우린 한가족 게임한마당 개최
      </li>
      <li>
        <em>2009.09.17</em>
        치매극복의 날 기념 한일국제세미나 개최
      </li>
      <li>
        <em>2009.09.04</em>
        캐나다 노인학대 현장전문가 알렌그로 집담회 개최
      </li>
      <li>
        <em>2009.06.08</em>
        제4회 세계 노인학대인식의 날 기념식 및 토론회개최
      </li>
      <li>
        <em>2007.06.~12</em>
        3세대가 함께하는 건강한 온라인 토론문화"사업 실시
      </li>
      <li>
        <em>2007.06.11</em>
        제2회 세계노인학대인식의 날 기념식 및 워크숍 개최
      </li>
      <li>
        <em>2007.03.~06</em>
        실버세대 게임문화 인식개선 공동협력사업 실시
      </li>
      <li>
        <em>2006.09.~2007.05</em>
        2007 남해 아시아 태평양 액티브 에이징 컨퍼런스 사무국 운영
      </li>
      <li>
        <em>2006.10.23</em>
        3세대가 참가하는 따뜻한 인터넷만들기 2006 시민대토론회개최
      </li>
      <li>
        <em>2006.10.10~10.11</em>
        2007 부산 아시아 태평양 액티브 에이징 포럼 개최
      </li>
      <li>
        <em>2004.12.21</em>
        노인학대 국제심포지움 개최
      </li>
      <li>
        <em>2004.06.26</em>
        디지털 실버 발언대회 개최
      </li>
      <li>
        <em>2003.06</em>
        3세대가 불건전사이트 정화 대토론회 개최
      </li>
      <li>
        <em>2000.11.02~03</em>
        Busan Well-Ageing Festival 개최
      </li>
      <li>
        <em>1999.11</em>
        동서화합 한국노년학연구회 학술대회 개최
      </li>
      <li>
        <em>1999.10.08</em>
        세계 노인의 해 기념 부산 국제심포지움 개최
      </li>
      <li>
        <em>1998.11.26.~11.30</em>
        노인개호에 관한 초청 간담회
      </li>
      <li>
        <em>1998.06.24~26</em>
        한,중,일 국제노인학술세미나 개최
      </li>
      <li>
        <em>1998.03.31</em>
        노인대학 활성화 방안을 위한 간담회
      </li>
    </ul>
  </div>
</div>
<!-- 심포지움 개최 끝 -->

<!-- 특강 및 발표 시작 -->
<div id="tab04" class="history">
  <div class="time fourth">
    <strong>2020</strong>
    <div class="thumb">
      <img src="/assets/img/sub/history13.jpg" alt="">
    </div>
    <ul>
      <li>
        <em>2020.12.14.</em>
        장노년층을 위한 웰에이징 과학문화 강연 실시 (한국과학창의재단 지원사업)<br/>
        ‘뇌과학과 웰에이징’ - 서진수 교수(대구경북과학기술원 뇌인지과학부)<br/>
        ‘청각학과 웰에이징’ - 이정학 총장(한림국제대학교 대학원)
      </li>
      <li>
        <em>2020.12.07.</em>
        장노년층을 위한 웰에이징 과학문화 강연 실시 (한국과학창의재단 지원사업)<br/>
        ‘4차 산업혁명과 웰에이징’ - 김병진 원장(부산과학산업혁신원)<br/>
        ‘과학문화와 생활건강법’ - 고광욱 교수(고신대학교 의과대학)
      </li>
      <li>
        <em>2020.11.30.</em>
        장노년층을 위한 웰에이징 과학문화 교육 실시 (한국과학창의재단 지원사업)<br/>
        ‘스마트에이징 체험과 이해’ - 김삼문 대표(토미타피트너스)
      </li>
      <li>
        <em>2020.11.23.</em>
        장노년층을 위한 웰에이징 과학문화 교육 실시 (한국과학창의재단 지원사업)<br/>
        ‘4차 산업혁명의 이해’ - 고정현 수석(한국정보화진흥원)
      </li>
      <li>
        <em>2020.11.16.</em>
        장노년층을 위한 웰에이징 과학문화 교육 실시 (한국과학창의재단 지원사업)<br/>
        ‘찾아가는 세계 과학 박물관’ - 권수진 실장(국립부산과학관)
      </li>
      <li>
        <em>2020.10.12.~11.09.</em>
        장노년층을 위한 스마트폰 활용 교육 실시 (한국과학창의재단 지원사업)
      </li>
      <li>
        <em>2020.07.26.</em>
        ACAP(Active Aging Consortium Asia Pacific) 발표
      </li>
    </ul>
  </div>

  <div class="time fourth">
    <strong>2019</strong>
    <div class="thumb">
      <img src="/assets/img/sub/history09.jpg" alt="">
    </div>
    <ul>
      <li>
        <em>2019.11.19.~2019.11.20.</em>
        제 4차 과학기술문화와 함께하는 Acitve Aging 특강 (한국과학창의재단 지원사업)<br/>
        ‘과학기술문화와 함게하는 액티브에이징’ - 한동희 소장(노인생활과학연구소)</li>
      <li>
        <em>2019.10.04.</em>
        제 3차 과학기술문화와 함께 하는 Active Aging 특강 (한국과학창의재단 지원사업)<br/>
        ‘과학기술과 액티브 에이징’ - 캐서린 브라운 교수(하와이주립대학교)<br/>
        ‘한국인의 인지노화와 치매’ - 최진영 교수(서울대학교)
      </li>
      <li>
        <em>2019.09.03.</em>
        제 2차 과학기술문화와 함께하는 Active Aging 특강 (한국과학창의재단 지원사업)<br/>
        ‘의료의 진화와 의료로봇의 활용’ – 권동수 교수(카이스트)
      </li>
      <li>
        <em>2019.06.11</em>
        과학기술문화와 함께하는 Active Aging 특강<br/>
        ‘장노년층을 위한 인공지능의 이해와 활용’ - 김태희 교수(영산대학교)
      </li>
      <li>
        <em>2019.06.01.-06.05</em>
        태국 타마셋대학교 초청 “액티브에이징” 기조강연
      </li>
      <li>
        <em>2019.5.23</em>
        울산대학교 생활과학대학 명사초청 특강 “웰에이징과 생활과학”
      </li>
      <li>
        <em>2019.05.16</em>
        동아대학교 간호학과 “노인상담” 특강
      </li>
    </ul>
  </div>

  <div class="time">
    <strong>2014~1997</strong>
    <div class="thumb">
      <img src="/assets/img/sub/history11.jpg" alt="">
    </div>
    <ul>
      <li>
        <em>2014.09.30-10.02</em>
        UN ESCAP 본부 워크샵 초대 발표
      </li>
      <li>
        <em>2014.09.19</em>
        주) 유한킴벌리 50-60학교 워크샵 특강
      </li>
      <li>
        <em>2014.03.27</em>
        싱가폴 아시아태평양액티브에이징컨소시엄 발표
      </li>
      <li>
        <em>2013.12.22</em>
        OECD주최 외교부, 지식경제부후원 실버경제와 ICT역할 주제발표
      </li>
      <li>
        <em>2013.12.20</em>
        스위스재보험회사 주관 아시아헬스에이징 주제발표
      </li>
      <li>
        <em>2013.10.29</em>
        미래창조과학부 주최 디지털에이징심포지엄 주제발표
      </li>
      <li>
        <em>2012.11.09-12</em>
        일본 키타큐슈 고령친화엑스포참여
      </li>
      <li>
        <em>1998.10.</em>
        신라대학교 사회복지학과 창설 10주년 및 제2회 노인의 날 기념 심포지엄<br />
        “고령화 사회와 노인개호비용” 토론
      </li>
      <li>
        <em>1998.04.</em>
        노인건강관리법(가칭) 시안 개발에 관한 연찬회 발표 및 토론
      </li>
      <li>
        <em>1997.05.</em>
        YMCA 청소년 학원폭력 예방과 대책 마련을 위한 토론회 참석
      </li>
    </ul>
  </div>
</div>
<!-- 특강 및 발표 끝 -->