import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonHttpService } from 'src/app/services/common-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';

declare var window: any;
declare var $ : any;


@Component({
  selector: 'app-board-view',
  templateUrl: './board-view.component.html',
  styleUrls: ['./board-view.component.scss']
})
export class BoardViewComponent implements OnInit {

  serviceUrl: string = environment.serviceUrl + '/fileInfo/downloadFile.do?id=';
  boardConfig: any = {};
  board: any = {};

  menuId: number;
  boardConfigId: number;
  boardId: number;

  page: number;
  nextBoard: any = {};
  prevBoard: any = {};

  originUrl = location.origin;
  email = 'ydk@sgcnc.kr';

  youtube = 'https://www.youtube.com/embed/';

  user: any;

  constructor(
    private commonHttpService: CommonHttpService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    public sanitizer: DomSanitizer
  ) {
    this.route.params.subscribe(params => {
      document.body.scrollTop = 0;
      this.menuId = parseInt(this.route.snapshot.params['menuId'], 0);
      this.boardConfigId = parseInt(this.route.snapshot.params['boardConfigId'], 0);
      this.boardId = parseInt(this.route.snapshot.params['boardId'], 0);
      this.page = parseInt(this.route.snapshot.params['page'], 0);
      this.user = this.commonService.getSessionItem('currentUser');

      this.commonHttpService.findObject('/boardConfig/getBoardConfigOne.do?id=' + this.boardConfigId).subscribe(
        boardConfig => this.boardConfig = boardConfig
      );

      this.commonHttpService.findObject('/getBoardView.do?boardConfigId=' + this.boardConfigId + '&boardId=' + this.boardId).subscribe(
        board => this.board = board
      );

      this.commonHttpService.findObject('/getPreBoardId.do?boardConfigId=' + this.boardConfigId + '&id=' + this.boardId).subscribe(
        prevBoard => this.prevBoard = prevBoard
      );
      this.commonHttpService.findObject('/getNextBoardId.do?boardConfigId=' + this.boardConfigId + '&id=' + this.boardId).subscribe(
        nextBoard => this.nextBoard = nextBoard
      );
    });

  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  pre() {
    this.router.navigate(['/boardView/' + this.menuId + '/' + this.boardConfigId + '/' + this.prevBoard.id + '/' + this.page]);
  }

  next() {
    this.router.navigate(['/boardView/' + this.menuId + '/' + this.boardConfigId + '/' + this.nextBoard.id + '/' + this.page]);
  }

  copyClipBoard() {
    var url  = window.location.href;

    if(navigator.userAgent.indexOf("MSIE") != -1 || navigator.userAgent.indexOf("Trident") != -1){
      // IE
      window.clipboardData.setData("Text", url);
      alert("복사되었습니다.\n원하시는 곳에 Ctrl+V로 붙여서 사용하세요.");
    } else {
      // 크롬
      var temp = prompt("이 글의 트랙백 주소입니다. Ctrl+C를 눌러 클립보드로 복사하세요", url);
    }
  }

  member(){
    alert("노인생활과학연구소 회원만 다운로드 하실 수 있습니다.");
  }

  boardPrint(){
    let popPrint;
    let printContents = document.getElementById('board-veiw').innerHTML;

    popPrint = window.open();

    popPrint.document.open();
    popPrint.document.write(`
          <html>
            <head>
              <link rel="stylesheet" type="text/css" href="/assets/css/common/style.reset.css">
              <link rel="stylesheet" type="text/css" href="/assets/css/common/style.common.css">
              <link rel="stylesheet" type="text/css" href="/assets/css/common/style.fonts.css">
              <link rel="stylesheet" type="text/css" href="/assets/css/print/style.board.print.css">
            </head>
            <body>
              <div id="board-view">
                ${printContents}
              </div>
              <div class="print-btn">
                <button class="btn-print" onClick="boardViewPrint();">인쇄</button>
                <button class="btn-close" onClick="window.close();">취소</button>
              </div>
              <script>
                function boardViewPrint(){
                  window.print();
                  window.close();
                }
              </script>
            </body>
          </html>`
    );
  }


  snsShare(e){
    var sns_type = $(e.target).data('service');
    var href = window.location.href ;
    var title = $(e.target).data('title');
		var loc = "";
		
		if( sns_type == 'facebook' ) {
      loc = '//www.facebook.com/sharer/sharer.php?u='+href+'&t='+title;
		}
		else if ( sns_type == 'twitter' ) {
      loc = 'https://twitter.com/intent/tweet?url='+href+'&text='+title;
    }
		else {
			return false;
		}
		
		window.open(loc);
  }

  delete() {
    if (window.confirm('정말 삭제 하시겠습니까?')) {
      this.commonHttpService.findObject('/deleteBoard.do?boardConfigId=' + this.boardConfig.id + '&id=' + this.board.id).subscribe(obj => {
        this.router.navigate(['/board/' + this.menuId + '/' + this.boardConfigId + '/' + this.page]);
      });
    }
  }

  

}
