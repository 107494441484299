import { Component, OnInit } from '@angular/core';
import { __asyncDelegator } from 'tslib';
import { CommonHttpService } from '../services/common-http.service';

declare var Swiper: any;
declare var slick: any;
declare var $: any;


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  public swiperOptions : any;

  constructor(private commonHttpService: CommonHttpService) {

    // 공지사항 
    this.commonHttpService.findAll('/getBoardListForPageOnMain.do?boardConfigId=110&page=1&pageSize=2').subscribe(val => {
      this.notices = val;
    });

    // 연구소 칼럼
    this.commonHttpService.findAll('/getBoardListForPageOnMain.do?boardConfigId=111&page=1&pageSize=1').subscribe(val => {
      this.coulumns = val;
    });

    // 메인 슬라이드 옵션
    this.swiperOptions = {
      loop: true,
      slidesPerView: 1,
      speed: 850,
      autoplayDisableOnInteraction: false,
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev'
      },
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    };

  }
  
  notices : any[] = [];
  coulumns : any[] = [];



  ngOnInit() {

    var mainSwiper = new Swiper(".swiper-container", this.swiperOptions);

    mainSwiper.update();

    $('.total').text(mainSwiper.slides.length - 2);
    $('.current').text(mainSwiper.activeIndex);


    mainSwiper.on('slideChange', function () {
      $('.current').text(mainSwiper.realIndex + 1);
    });

    $(".pause").click(function () {
      mainSwiper.autoplay.stop();
    });

    $(".play").click(function () {
      mainSwiper.autoplay.start();
    });

  }


  ngAfterViewInit() {


    
    // 웹 세팅
    const settingsPc = {
      rows: 2,
      slidesPerRow: 1,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 250,
      autoplaySpeed: 3500,
      autoplay: false,
      dots: true,
      arrows: false,
      cssEase: 'cubic-bezier(1, 0, 0.7, 1)',
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      draggable: false
    };

    // 모바일 세팅
    const settingsMobile = {
      rows: 1,
      slidesPerRow: 1,
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 250,
      autoplaySpeed: 3500,
      autoplay: false,
      dots: true,
      arrows: false,
      cssEase: 'cubic-bezier(1, 0, 0.7, 1)',
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      draggable: false,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

    // 로드 시 슬라이드
    if($(window).width() > 1080){
      $('.support-banner .list ul').slick(settingsPc);
    } else {
      $('.support-banner .list ul').slick(settingsMobile);
    }

    // 리사이즈 시 슬라이드
    $(window).on('resize', function() {
        if( $(window).width() > 1080 ) {
          $(".support-banner .list ul.slick-initialized").slick("unslick");

          $('.support-banner .list ul').slick(settingsPc);
        } else if ($(window).width() < 1080) {
          $(".support-banner .list ul.slick-initialized").slick("unslick");

          $('.support-banner .list ul').not('.slick-initialized').slick(settingsMobile);
        }
    });

    // 버튼
    $(".support-prev").click(function () {
      $('.support-banner .list ul').slick('slickPrev');
    });

    $(".support-next").click(function () {
      $('.support-banner .list ul').slick('slickNext');
    });




  }

}
