import { Component, OnInit } from '@angular/core';
import { CommonHttpService } from 'src/app/services/common-http.service';
import { environment } from 'src/environments/environment';

declare var $ : any;

@Component({
  selector: 'app-account-find',
  templateUrl: './account-find.component.html',
  styleUrls: ['./account-find.component.scss']
})
export class AccountFindComponent implements OnInit {

  userName1 = '';
  email1 = '';

  userName2 = '';
  email2 = '';
  userId2 = '';

  constructor(private commonHttpService: CommonHttpService) { }

  ngOnInit() {
    $(".account-tab button").click(function () {
      $(this).addClass('active').siblings().removeClass('active');
      $(".form-group-container").hide();

      var activeTab = $(this).data("tab");
      $("#" + activeTab).show();
    });
  }

  findId() {
    this.commonHttpService.findObjectForPost({siteId: environment.siteId, userName: this.userName1, email: this.email1}, '/user/findIdForSite.do')
      .subscribe(
        data => {          
          alert('회원님의 아이디는 [' + data.userId + '] 입니다.');
        },
        error => {
          alert(error.error);
        }
      );
  }

  findPw() {
    this.commonHttpService.findObjectForPost({siteId: environment.siteId, userName: this.userName2, email: this.email2, userId: this.userId2 }, '/user/findPwforSite.do')
      .subscribe(
        data => {
          alert('회원님의 패스워드는 [' + data.password + '] 입니다.');
        },
        error => {
          alert(error.error);
        }
      );
  }

}
