import { Injectable } from '@angular/core';
import { AsyncValidator, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CommonHttpService } from 'src/app/services/common-http.service';

@Injectable()
export class UseridValidationService implements AsyncValidator  {

  user: any;

    constructor(
      private commonHttpService: CommonHttpService) {
    }

    validate(ctrl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return this.commonHttpService.isAbleCode(ctrl.value).pipe(
            map(isTaken => (isTaken ? { code: true } : false)),
            catchError(() => null)
        );
    }
}
