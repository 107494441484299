<strong>이곳은 노인생활과학과 관련된 내용을 문의하는 곳입니다.</strong>

<ol>
    <li>1. 가능한 한 빠르게 답변을 드리도록 하겠습니다.</li>
    <li>2. 저속한 표현, 타인에 대한 명예 훼손, 불건전한 내용, 기타 게시판의 성격에 맞지 않는 내용을 담은 글은 이용자의 편의를 위하여 예고 없이 삭제될 수 있습니다.</li>
    <li>
        3. 게시판이 건전하게 운영될 수 있도록 아래에 적은 내용을 참고하여 글을 쓰시기 바랍니다.
        <ol>
            <li>가. 특정인을 비방하는 글이나 근거 없는 내용을 담은 글은 삭제될 수 있습니다.</li>
            <li>나. 여러분이 기재하신 사항은 다른 사람들이 조회 또는 열람할 수도 있으니 개인 정보가 게시되지 않도록 주의하여 주시기 바랍니다.</li>
        </ol>
    </li>
</ol>

<p><span>*</span> 표시 항목은 필수사항 입니다.</p>
<form [formGroup]="group" (ngSubmit)="save()" novalidate>
<table class="tbw">
    <caption></caption>
    <tbody>
        <tr>
            <th><span>*</span> 제목</th>
            <td>
                <input type="text" placeholder="제목을 입력하세요." formControlName="title">
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <textarea placeholder="내용을 입력하세요." name="" id="" cols="30" rows="10" formControlName="content"></textarea>
            </td>
        </tr>
    </tbody>
</table>


<!-- 버튼 -->
<div class="btn-group tac">
    <div class="di">
        <button class="btn btn-list" type="button" (click)="back()">목록보기</button>
        <button class="btn btn-write" type="submit" [disabled]="!group.valid">작성</button>
    </div>
</div>
</form>