<div id="vision">

  <div class="vision">
    <div>
      <span>VISION</span>
      <p>웰에이징을 통한<br />신 노년</p>
    </div>
  </div>

  <div class="mission">
    <span>MISSION</span>
    <strong>새롭게 맞는 노년, 행복한 노년</strong>
    <ul>
      <li>
        <span><img src="/assets/img/sub/mission_bg01.png" alt=""></span>
        <dl>
          <dt>활기찬 노년</dt>
          <dd>사회참여</dd>
          <dd>건강증진</dd>
          <dd>안전</dd>
        </dl>
      </li>
      <li>
          <span><img src="/assets/img/sub/mission_bg02.png" alt=""></span>
        <dl>
          <dt>과학기술을 통한 노년</dt>
          <dd>디지털 에이징</dd>
          <dd>에비던스 구축</dd>
        </dl>
      </li>
      <li>
          <span><img src="/assets/img/sub/mission_bg03.png" alt=""></span>
        <dl>
          <dt>건강한 노년</dt>
          <dd>마음의 영양소</dd>
          <dd>노인 돌봄</dd>
          <dd>치매, 인지기능 훈련 예방</dd>
        </dl>
      </li>
    </ul>
  </div>

  <div class="action">
    <strong>실천 연구(액션 리서치)</strong>
  </div>

</div>