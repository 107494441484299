<div class="parish">
  <h5>노인교구의 종류</h5>

  <ul>
    <li>마음의 영양소 노인교구는 총 9가지이며, 균형의자 이야기, 담벼락 이야기Ⅰ, 담벼락 이야기Ⅱ, 바느질 이야기Ⅰ, 바느질 이야기Ⅱ, 자석 한글 이야기, 자석 숫자 이야기, 세상 이야기, 칠자 이야기로
      구성됨</li>
  </ul>

  <div class="list">
    <ul>
      <li>
        <img src="/assets/img/sub/story01.jpg" alt="">
        <span>균형의자 이야기</span>
      </li>
      <li>
        <img src="/assets/img/sub/story02.jpg" alt="">
        <span>담벼락 이야기</span>
      </li>
      <li>
        <img src="/assets/img/sub/story03.jpg" alt="">
        <span>바느질 이야기I</span>
      </li>
      <li>
        <img src="/assets/img/sub/story04.jpg" alt="">
        <span>바느질 이야기Ⅱ</span>
      </li>
      <li>
        <img src="/assets/img/sub/story05.jpg" alt="">
        <span>세상 이야기</span>
      </li>
      <li>
        <img src="/assets/img/sub/story06.jpg" alt="">
        <span>자석한글 이야기</span>
      </li>
      <li>
        <img src="/assets/img/sub/story07.jpg" alt="">
        <span>자석숫자 이야기</span>
      </li>
      <li>
        <img src="/assets/img/sub/story08.jpg" alt="">
        <span>칠자 이야기</span>
      </li>
    </ul>
  </div>




  <h5>마음의 영양소</h5>

  <div class="parish-teacher">
    <div class="thumb">
      <img src="/assets/img/sub/teacher.jpg" alt="">
    </div>
    <ul>
      <li>노인교구 프로그램은?</li>
      <li>노년기의 다양한 놀이문화 및 학습통로</li>
      <li>인지기능(주의 집중력, 언어관련 능력, 학습 및 기억능력 등) 증진을 위한 활동 지원</li>
      <li>소통과 노년기 자아통합 훈련</li>
      <li>의사소통 역량 훈련</li>
      <li>노인복지관, 치매안심센터, 주간보호센터, 노인병원, 경로당, 지역사회 등 다양한 기관에서 훈련하고 있음</li>

    </ul>
  </div>

  <div class="responsive">
    <div class="info">좌우로 탭하여 내용을 확인하실 수 있습니다.</div>

    <div class="scroll">
      <table class="tbl">
        <caption></caption>
        <tbody>
          <tr>
            <th colspan="2">기관</th>
            <th colspan="2">노인교구 프로그램 사례</th>
          </tr>
          <tr>
            <th colspan="2">치매안심센터</th>
            <td><img src="/assets/img/sub/center01.jpg" alt=""></td>
            <td><img src="/assets/img/sub/center02.jpg" alt=""></td>
          </tr>
          <tr>
            <th colspan="2">요양병원</th>
            <td><img src="/assets/img/sub/center03.jpg" alt=""></td>
            <td><img src="/assets/img/sub/center04.jpg" alt=""></td>
          </tr>
          <tr>
            <th colspan="2">노인복지관</th>
            <td><img src="/assets/img/sub/center05.jpg" alt=""></td>
            <td><img src="/assets/img/sub/center06.jpg" alt=""></td>
          </tr>
          <tr>
            <th colspan="2">주간보호센터</th>
            <td><img src="/assets/img/sub/center07.jpg" alt=""></td>
            <td><img src="/assets/img/sub/center08.jpg" alt=""></td>
          </tr>
          <tr>
            <th colspan="2">경로당</th>
            <td><img src="/assets/img/sub/center09.jpg" alt=""></td>
            <td><img src="/assets/img/sub/center10.jpg" alt=""></td>
          </tr>
          <tr>
            <th colspan="2">지역사회</th>
            <td><img src="/assets/img/sub/center11.jpg" alt=""></td>
            <td><img src="/assets/img/sub/center12.jpg" alt=""></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


  <p>노인교구 프로그램의 효과</p>
  <div class="cep">
    <span>※ 마음의 영양소 노인교구는 CEP프로그램으로 운영됩니다.</span>
    <div class="parish-img"><img src="/assets/img/sub/parish_program.png" alt=""></div>
    <div class="parish-cep">
      <div class="thumb">
        <span>C</span>
        <dl>
          <dt>인지기능 (Cognitive Function)</dt>
          <dd>노인의 인지능력 : 기억력, 지남력, 수리력, 공간적 지각, 전두엽 관리 기능 증진</dd>
          <dd>정서적, 신체적 기능과 함께 증진하는 인지기능</dd>
        </dl>
      </div>
      <div class="thumb">
        <span>E</span>
        <dl>
          <dt>정서기능 (Emotional Function)</dt>
          <dd>놀이 : 즐거움, 창조적 사고, 관계증진, 사회성 훈련</dd>
          <dd>이야기 : 언어관련 능력, 표현, 회상, 공감, 자아통합 훈련</dd>
        </dl>
      </div>
      <div class="thumb">
        <span>P</span>
        <dl>
          <dt>신체기능 (Physical Function)</dt>
          <dd>신체활동 : 소근육, 대근육 훈련을 통한 신체활동 향상</dd>
        </dl>
      </div>
    </div>
    
    
  </div>

  
  <p>마음의 영양소 노인교구 활동</p>

  <ul class="mb50">
    <li>2021.3.13.-4.13 <span>· 2020년 제1회 노인교구지도사(2급) 양성과정 </span></li>
    <li>2021.2.27 <span>· 2021년 제1회 노인교구지도사(2급) 역량강화 교육(3시간)</span></li>
    <li>2021.02 <span>· 2020년 노인교구지도사 1급 자격증 2호 3호 발급</span></li>
    <li>2020.12.26 <span>· 2020년 12월 사례발표회 및 송년회(줌)</span> </li>
    <li>2020.12 <span>· 서울시 어르신돌봄가족지원센터 프로그램 운영</span></li>
    <li>2020.12.22<span>· 2020년 노인교구지도사 1급 자격 검정시험 실시</span> </li>
    <li>2020.12. <span>· 2020년 노인교구지도사 1급 양성과정</span></li>
    <li>2020.12.15 <span>· 2020년 노인교구지도사 1급 자격증 1호 발급</span></li>
    <li>2020.11.28 <span>· 2020년 11월 월례회 및 사례발표회</span></li>
    <li>2020.10.17 <span>· 2020년 10월 월례회 및 사례발표회</span></li>
    <li>2020.08.18~26 <span>· 2020년 제2회 노인교구지도사(2급) 역량강화 교육(12시간)</span></li>
    <li>2020.08.01.-29<span> · 2020년 제3회 노인교구지도사 (2급) 양성과정</span></li>
    <li>2020.07.12.~25 <span>· 2020년 제1회 노인교구지도사(2급) 역량강화 교육(12시간)</span></li>
    <li>2020.04~05<span>· 2020년 제2회 노인교구지도사(2급) 양성과정 (진주시보건소)</span> </li>
    <li>2020. 02. ~ 03.<span> · 2020년 제1회 노인교구지도사(2급) 양성과정</span> </li>
    <li>2020. 01. ~ 02. <span>· 부산광역시 북구치매안심센터 노인교구지도사(2급) 양성교육</span>  </li>
    <li>2019. 12. 17  <span>· 2019 노인교구지도사 워크숍 및 송년회</span></li>
    <li>2019. 10. ~ 11.<span>· 경상남도 하동군치매안심센터 노인교구지도사(2급) 양성교육</span> </li>
    <li>2019. 10. ~ 12.<span>· 부산광역시 북구치매안심센터 노인교구프로그램</span> </li>
    <li>2019. 09. 27 <span>· 부산광역시 북구치매안심센터 개소식<br/>
      - 마음의 영양소 노인교구 ...</span></li>
    <li>2019. 09. ~ 12. <span>· 부산시민대학(부산인재평생교육진흥원) 노인교구지도사(2급) 양성교육</span></li>
    <li>2019. 09. ~ 11. <span>· 경상남도 통영시치매안심센터 노인교구프로그램<br/>· 2019 하반기 부산시 중구 원로의 집 노인교구프로그램</span></li>
    <li>2019. 08. ~ 10. <span>· 서울시 50플러스재단 서부캠퍼스 노인교구지도사(2급) 양성교육(하반기)</span></li>
    <li>2019. 08. <span>· 경상남도 고성군치매안심센터 노인교구지도사(2급) 양성교육<br/>· 2019 제2회 노인교구지도사 역량강화교육</span></li>
    <li>2019. 07.<span>· 양산시노인복지관 노인교구지도사 역량강화교육</span></li>
    <li>2019. 05. <span>· 2019 제1회 노인교구지도사 역량강화교육</span></li>
    <li>2019. 04. ~ 06. <span>· 2019 상반기 부산시 중구 원로의 집 노인교구프로그램</span></li>
    <li>2019. 04. 17. <span>· 마음의 영양소 노인교구 책 출간</span></li>
    <li>2019. 04 <span>· 2019 한마음 치매극복 걷기 행사(고성군) 노인교구 부스 운영</span></li>
    <li>2019. 03. ~ 05. <span>· 2019 서울시 50플러스재단 서부캠퍼스 노인교구지도사(2급) 양성교육(상반기)</span></li>
    <li>2019. 03. <span>· 2019 부산시 중구 원로의 집 노인교구프로그램 노인교구지도사 역량강화교육</span></li>
    <li>2019. 02. ~ 12. <span>· 경상남도 고성군치매안심센터 노인교구프로그램 진행 및 노인교구지도사 파견<br/>
      - 치매안심센터(고위험군, 경도인지장애군)<br/>
      - 권역별쉼터(보건지소, 보건진료소)</span></li>
    <li>2019. 02. ~ 05. <span>· 경상남도 고성군치매안심센터 치매안심센터 직원(전문가) 역량강화 교육</span></li>
    <li>2019. 01. ~ 02. <span>· 2019년 제1회 노인교구지도사(2급) 양성교육</span></li>
    <li>2018. 11. ~ 12. <span>· 부산시 중구 원로의집 노인교구프로그램 실시</span></li>
    <li>2018. 12. <span>· 2018 노인교구지도사 워크숍 및 송년회</span></li>
    <li>2018. 11. ~ 12. <span>·부산광역시 중구 원로의 집 노인교구 프로그램 실시</span></li>
    <li>2018. 11.<span>· 경상남도 하동군보건소 직원 역량강화 교육<br/>
      ·경상남도 묵계리보건지료소, 진교보건진료소 노인교구프로그램 진행</span></li>
    <li>2018. 10.<span>· 2018 부산광역시 중구 평생학습축제 노인교구 부스 운영<br/>
      - 노인교구와 함께하는 세대통합</span></li>
    <li>2018. 09.<span>· 2018 제1회 노인교구지도사(2급) 양성교육<br/>
      ·노인(용)교구지도사 수료증 취득자 직무교육</span></li>
    <li>2018. 08. ~ 10.<span>· 부산광역시 동래구치매안심센터 노인교구프로그램진행</span></li>
    <li>2018. 06.<span>· 부산광역시 동래구치매안심센터 개소식 노인교구 전시 </span></li>
    <li>2018. 05.<span>· 한국직업능력개발원 민간자격등록 노인교구지도사 1급, 2급</span></li>
  </ul>


  <p>노인교구 프로그램 관련 언론보도</p>

  <ul>
    <li>가톨릭평화방송(2019년 2월 11일) : 서울시 50플러스 재단, ‘노인교구지도사 양성과정’ 개설</li>
    <li>경남도민신문(2018년 11월 22일) : 하동군 “건강한 노년생활 뇌청춘 100세”</li>
    <li>부산일보(2018년 11월 02일) : [세대 공감의 묘미 ‘노인교구’] 쌓고 조립하는 공감의 시간… 스르륵 무너지는 마음의 벽</li>
    <li>부산일보(2018년 07월 05일) : ‘노인 교구 수업’ 종강 현장 “생전 처음 해 본 교구… 동심 돌아간 듯 즐거워”</li>
    <li>경남매일신문(2018년 01월 16일) : 남해군, 치매안심센터 본격 운영<br/>(남해군 치매안심센터가 지난 12일 한동희 노인생활과학연구소 대표를 초빙해 센터 종사자들을 대상으로 역량 강화를 위한 교육프로그램을 실시)</li>
    <li>부산일보(2017년 12월 29일) : [노인 교구 정서지원 프로그램] 펼치고 쌓으며… 닫힌 마음 열어 세상과 소통하지요</li>
    <li>부산일보(2017년 08월 17일) : [교구 활용한 노인 정서 지원 프로그램] 용두산공원 어르신들, 장기 훈수만 말고 블록 쌓기 어때요?</li>
    <li>부산일보(2017년 03월 17일) : 쌓기 놀이, 칠자 놀이… 노인을 위한 ‘놀이 치료’ 배워 보세요(노인용 교구 지도사 양성 활발)</li>
    <li>부산일보(2017년 3월 17일) : [노인이 노인 돌보는 ‘老·老 케어’] 동년배상련… 세월에 지친 마음, ‘공감’으로 치유하다</li>
    <li>연합뉴스(2016년 8월 18일) : 그랜드자연요양병원… 노인생활과학연구소, 노인용 교구 MOU</li>
    <li>의협신문(2016년 7월 6일) : 그랜드자연요양병원, 노인생활과학연구소 ‘악수’(요양병원 입원 환자·가족 위한 노인용 교구 활용 프로그램 도입)</li>
    <li>부산일보(2016년 4월 27일) : 노인용 교구 활용 지도사 양성 교육</li>
  </ul>


</div>