<app-header></app-header>
<app-visual></app-visual>
<app-breadcrumbs></app-breadcrumbs>
<!-- 서브 콘텐츠 시작 -->
<div id="container">
    <div class="inner">
        <div class="sidebar">
            <app-sidebar></app-sidebar>
        </div>
        <div class="container">
            
            <div class="normal-tab" attr.data-title="{{menuName}}">
                <ul>
                    <li><a [routerLink]="'/science-culture/1611'" [routerLinkActive]="['active']"><span>웰에이징 과학문화</span></a></li>
                    <li><a [routerLink]="'/agingtech/1245'" [routerLinkActive]="['active']"><span>과학기술문화와 에이징</span></a></li>
                </ul>
            </div>

            <div class="tit">
                <h3>{{menuName}}</h3>
            </div>

            <!-- 액티브 에이징 > 주요활동 콘텐츠 시작 -->
            <div class="activities" attr.data-title="{{menuName}}">
                <div class="translate-tab">
                    <button data-tab="ageing01" class="active">국문</button>
                    <button data-tab="ageing02">ENG</button>
                </div>

                <div id="ageing01" class="translate">
                    <div class="goal">
                        <p>
                            ACAP의 이념은 아시아 태평양세어 액티브 에이징을 위한 정책과 의견,<br class="line" />
                            최고의 실천방안 연구를 포럼을 통해 공유하는 것을 이념으로 하고 있으며<br class="line" />
                            특히 다음과 같은 사항을 목표로 한다.
                        </p>
                    </div>
                    <ul>
                        <li>
                            ACAP은 액티브 에이징과 관련한 나라와 지역 대표의 인식을
                            증진하기 위하여 강의, 회의, 훈련을 통해 서로를 지원한다.
                        </li>
                        <li>
                            ACAP은 액티브 에이징에 대한 의견을 웹사이트와 국내 및
                            국제적 회의를 통하여 세계적으로 교류한다.
                        </li>
                    </ul>
                </div>

                <div id="ageing02" class="translate">
                    <div class="goal">
                        <p>
                            The mission of ACAP is to provide a forum in Asia Pacific for the sharing of research, policy ideas, and best practices for Active Aging. Specifically
                        </p>
                    </div>
                    <ul>
                        <li>
                            ACAP supports country and community representatives to increase local awareness of Active Aging through lectures, conferences, and training.
                        </li>
                        <li>
                            ACAP contributes to global exchange of Active Aging ideas through its website, through national and international conferences, and by collaborating on projects of mutual interest.
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 액티브 에이징 > 주요활동 콘텐츠 끝 -->




            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<!-- 서브 콘텐츠 끝 -->

<app-footer></app-footer>