import { Component, OnInit } from '@angular/core';

declare var $ : any;

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(".history-tab button").click(function () {
      $(this).addClass('active').siblings().removeClass('active');
      $(".history").hide();

      var activeTab = $(this).data("tab");
      $("#" + activeTab).show();
    });
  }

}
