<div class="science-culture-intro">
    과학기술문화의 소외계층인 장노년층에게 과학기술문화를 보급하여 웰에이징을 실천할 수 있는 역량을 키우고자 합니다.<br />
    특히, 코로나 바이러스 19 감염병으로 움츠려진 장노년층에게 스마트폰 역량 강화 교육을 실시하여 비대면 수업역량을 키워 과학 문화를 쉽게 이해하고 웰에이징을 구현할 수 있도록 지속적으로 여러분과 함께 할
    것입니다.
</div>

<h5>사업모형</h5>
<img src="/assets/img/sub/science_culture.jpg" alt="" class="border">


<h5 class="mt120">스마트폰 교육 일정(대면)</h5>
<div class="responsive">
    <div class="info">좌우로 탭하여 내용을 확인하실 수 있습니다.</div>

    <div class="scroll">
        <table class="tbl">
            <caption></caption>
            <tbody>
                <tr>
                    <th></th>
                    <th>일시</th>
                    <th>강의 내용</th>
                    <th>담당 강사</th>
                </tr>
                <tr>
                    <th>10월</th>
                    <td>12일 (14:00 ~ 17:00)</td>
                    <td>오리엔테이션 웰에이징과 과학문화</td>
                    <td>(사)노인생활과학연구소 한동희소장</td>
                </tr>
                <tr>
                    <th rowspan="2">10월</th>
                    <td>19일 (14:00 ~ 17:00)</td>
                    <td>스마트폰 기기에 대한 이해와 활용(I)</td>
                    <td rowspan="4">㈜비틀 손인석</td>
                </tr>
                <tr>
                    <td>26일 (14:00 ~ 17:00)</td>
                    <td>스마트폰 기기에 대한 이해와 활용(II)</td>
                </tr>
                <tr>
                    <th rowspan="2">11월</th>
                    <td>2일 (14:00 ~ 17:00)</td>
                    <td>스마트폰 기기에 대한 이해와 활용(III)</td>
                </tr>
                <tr>
                    <td>9일 (14:00 ~ 17:00)</td>
                    <td>Zoom 정복하기</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>





<h5>웰에이징을 위한 과학문화 교육(대면+비대면)</h5>
<div class="responsive">
    <div class="info">좌우로 탭하여 내용을 확인하실 수 있습니다.</div>

    <div class="scroll">
        <table class="tbl green">
            <caption></caption>
            <tbody>
                <tr>
                    <th>일시</th>
                    <th>주제</th>
                    <th>담당 강사</th>
                </tr>
                <tr>
                    <th>11월 16일 (14:00 ~ 17:00)</th>
                    <td>찾아가는 세계박물관</td>
                    <td>국립 부산과학관 권수진 실장</td>
                </tr>
                <tr>
                    <th>11월 23일 (14:00 ~ 17:00)</th>
                    <td>4차산업혁명의 이해</td>
                    <td>한국정보문화진흥원 고정현 수석</td>
                </tr>
                <tr>
                    <th>11월 30일 (14:00 ~ 17:00)</th>
                    <td>스마트에이징 체험과 이해</td>
                    <td>토미타피트너스 김삼문 대표</td>
                </tr>
                <tr>
                    <th>11월 30일 (17:00 ~ 18:00)</th>
                    <td>종료식 및 평가</td>
                    <td>(사)노인생활과학연구소 한동희 소장</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>




<h5>강사소개</h5>
<div class="teacher">
    <ul>
        <li>
            <img src="/assets/img/sub/teacher01.jpg" alt="" class="border">
            <span>㈜비틀 손인석</span>
        </li>
        <li>
            <img src="/assets/img/sub/teacher02.jpg" alt="" class="border">
            <span>국립 부산과학관 권수진 실장</span>
        </li>
        <li>
            <img src="/assets/img/sub/teacher03.jpg" alt="">
            <span>한국정보문화진흥원 고정현 수석</span>
        </li>
        <li>
            <img src="/assets/img/sub/teacher04.jpg" alt="">
            <span>토미타피트너스 김삼문 대표</span>
        </li>
    </ul>
</div>





<h5>장노년층을 위한 웰에이징 과학문화 비대면 강연</h5>


<div class="box mb25">
    <div class="inner">
        <div class="txt01">
            디지털 대전환 시대를 맞아 사단법인 노인생활과학연구소에서는 장노년층을 위한<br />
            웰에이징 과학문화 비대면 강연을 준비합니다. <br />
            과학기술문화를 노년생활에 접목시켜 보다 건강하고 활기찬<br />
            웰에이징을 만들어 가시기 바랍니다.
        </div>



        <ul>
            <li class="bold">일시 : 2020년 12월 7일, 14일 (월) 14:00 - 17:00</li>
            <li>참여방법 : 비대면 줌(Zoom)회의</li>
            <li>등록주소 : <a href="https://us02web.zoom.us/j/5427851620?pwd=c0hmby9UcFFrbkxXR3BDK29iNGpqZz09" target="_blank">https://us02web.zoom.us/j/5427851620?pwd=c0hmby9UcFFrbkxXR3BDK29iNGpqZz09</a></li>
            <li class="bold">회의 ID : 542 785 1620 </li>
        </ul>

        <div class="tar mt35 mb35">
            <a href="https://us02web.zoom.us/j/5427851620?pwd=c0hmby9UcFFrbkxXR3BDK29iNGpqZz09" target="_blank" class="zoom"><span>ZOOM 바로가기</span></a>
        </div>



        <em>강연일정 (Ⅰ)</em>
        <div class="responsive" data-text="진행 : 한동희 (사단법인 노인생활과학 연구소장)">
            <div class="info">좌우로 탭하여 내용을 확인하실 수 있습니다.</div>

            <div class="scroll">
                <table class="tbl">
                    <caption></caption>
                    <thead>
                        <tr>
                            <th colspan="3">12월 7일 월요일 오후 2시 ~ 5시</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>14 : 00 ~ 15 : 30</th>
                            <td>부산과학산업혁신원 김병진원장</td>
                            <td>4차 산업혁명과 웰에이징</td>
                        </tr>
                        <tr>
                            <th>15 : 40 ~ 17 : 00</th>
                            <td>고신대학교 의과대학 예방의학과 고광욱교수</td>
                            <td>과학문화와 생활건강법</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>



        <em>강연일정 (Ⅱ)</em>
        <div class="responsive">
            <div class="info">좌우로 탭하여 내용을 확인하실 수 있습니다.</div>

            <div class="scroll">
                <table class="tbl">
                    <caption></caption>
                    <thead>
                        <tr>
                            <th colspan="3">12월 14일 월요일 오후 2시 ~ 5시</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>14 : 00 ~ 15 : 30</th>
                            <td>대구경북과학기술원 뇌인지전공 서진수교수</td>
                            <td>뇌과학과 웰에이징</td>
                        </tr>
                        <tr>
                            <th>15 : 40 ~ 17 : 00</th>
                            <td>한림국제대학원 대학교 이정학총장</td>
                            <td>청각학과 웰에이징</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>





    <div class="lecture">
        <dl>
            <dt>
                <em>4차산업 혁명과 웰에이징</em>
                <sub>12월 7일 (14:00 ~ 15:30)</sub>
            </dt>
            <dd>
                <img src="/assets/img/sub/lecture01.jpg" alt="">
                <span>부산과학산업혁신원 <em>김병진 원장</em></span>
                <ul>
                    <li>부산산업과학혁신원 원장</li>
                    <li>한국멀티미디어학회 특임회장</li>
                    <li>전국연구개발지원단협의회 회장</li>
                </ul>
            </dd>
        </dl>
        <dl>
            <dt>
                <em>과학문화와 생활건강법</em>
                <sub>12월 7일 (15:40 ~ 17:00)</sub>
            </dt>
            <dd>
                <img src="/assets/img/sub/lecture03.jpg" alt="">
                <span>고신대학교 의과대학 예방의학과 <em>고광욱 교수</em></span>
                <ul>
                    <li>고신대학교 의과대학 예방의학과 교수</li>
                    <li>한국보건교육건강증진학회 학회장</li>
                    <li>대한민국건강도시협의회 학술위원장</li>
                </ul>
            </dd>
        </dl>
        <dl>
            <dt>
                <em>뇌과학과 웰에이징</em>
                <sub>12월 14일 (14:00 ~ 15:30)</sub>
            </dt>
            <dd>
                <img src="/assets/img/sub/lecture02.jpg" alt="">
                <span>대구경북과학기술원 뇌인지과학부 <em>서진수 교수</em></span>
                <ul>
                    <li>대구경북과학기술원 뇌인지과학전공 조교수</li>
                    <li>미국 MIT 뇌인지과학 피카워연구소 연구원</li>
                    <li>서울대학교 이학박사</li>
                </ul>
            </dd>
        </dl>
        <dl>
            <dt>
                <em>청각학과 웰에이징</em>
                <sub>12월 14일 (15:40 ~ 17:00)</sub>
            </dt>
            <dd>
                <img src="/assets/img/sub/lecture04.jpg" alt="">
                <span>한림국제대학원 대학교 <em>이정학 총장</em></span>
                <ul>
                    <li>한림국제대학원 대학교 총장</li>
                    <li>국제표준화기구(ISO) 보청기적합관리위원회 의장</li>
                    <li>전 한국청각언어재활학회 학회장</li>
                </ul>
            </dd>
        </dl>
    </div>


</div>


















<p>문의 : 사단법인 노인생활과학연구소 (전화번호) 051-751-5025  (팩스) 051-624-5025</p>
<p>
    <span>주최 : <img src="/assets/img/sub/culture_logo01.jpg" alt=""> 사단법인 노인과학연구소</span><span>후원 : <img
            src="/assets/img/sub/culture_logo02.jpg" alt=""> 한국과학창의재단</span>
</p>
<p>* 이 사업은 정부(과학기술진흥기금/복권기금)의 재원으로 한국과학창의재단의 지원을 받아 수행하고 있습니다.</p>