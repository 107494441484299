<div class="info">
  <ul>
    <li>노인생활과학연구소 대표홈페이지에서 취급하는 모든 개인정보는 개인정보 보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 수집·이용·제공되고 있습니다. </li>
    <li>노인생활과학연구소 대표홈페이지는 개인정보 보호법 제 30조에 따라 정보주체의 개인정보를 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리 방침을 수립·공개합니다.
    </li>
  </ul>
</div>



<div class="service">
  <strong>1. 개인정보의 처리목적</strong>
  <p>
    노인생활과학연구소 대표홈페이지는 다음의 목적을 위하여 최소한의 개인정보를 수집하여 처리합니다. 처리하고 있는 개인정보는 다음 목적이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법
    제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
  </p>
  <ul>
    <li>
      가. 홈페이지 회원가입 및 관리
      <ul>
        <li>1) 홈페이지 회원제 서비스(진흥사업 접수, 전자민원, 뉴스레터 등)를 목적으로 개인정보를 처리합니다.</li>
      </ul>
    </li>
  </ul>
</div>


<div class="service">
  <strong>2. 개인정보의 처리 및 보유기간</strong>
  <ul>
    <li>가. 노인생활과학연구소 대표홈페이지는 법령에 따른 개인정보 보유기간 또는 정보주체로부터 개인정보 수집 시에 동의 받은 보유기간 내에서 개인정보를 처리하고 보유합니다.</li>
    <li>
      나. 개인정보 처리 및 보유 기간
      <ul>
        <li>- 보유기간 : 회원 탈퇴 시 까지</li>
      </ul>
    </li>
  </ul>
</div>


<div class="service">
  <strong>3. 처리하는 개인정보의 항목</strong>
  <ul>
    <li>
      가. 노인생활과학연구소 대표홈페이지는 행정안전부 개인정보보호 종합지원 포털을 이용하여 개인정보파일 등록사항을 공개하고 있습니다.
      <ul>
        <li>* 이용방법 : 개인정보보 종합지원 포털(www.privacy.go.kr) → 민원마당 → 개인정보파일 목록 검색 → 기관명 ‘노인생활과학연구소’ 검색 후 조회</li>
      </ul>
    </li>
    <li>
      나. 홈페이지 회원제 서비스를 위하여 수집하는 개인정보의 항목
      <ul>
        <li>1) 필수항목 : 성명, 연락처, 이메일</li>
        <li>2) 선택항목 : 생년월일, 주소</li>
      </ul>
    </li>
    <li>
      다. 인터넷 서비스 이용과정에서 자동으로 생성되어 수집하는 개인정보의 항목
      <ul>
        <li>1) 이용자의 인터넷서버 도메인명, 접속IP주소, 우리 홈페이지를 방문할 때 거친 웹사이트의 주소, 방문 일시, 쿠키, 우리 홈페이지 접근메뉴</li>
        <li>2) 이용자의 브라우저 종류 및 OS</li>
      </ul>
    </li>
  </ul>
</div>



<div class="service">
  <strong>4.개인정보의 제 3자 제공에 관한 사항</strong>
  <ul>
    <li>
      가. 노인생활과학연구소 대표홈페이지는 에서 수집․보유하고 있는 개인정보는 이용자의 동의 없이는 제3자에게 제공하지 않으며, 다음의 경우에는 개인정보를 제3자에게 제공할 수 있습니다.
      <ul>
        <li>1) 정보주체로부터 별도의 동의를 받은 경우</li>
        <li>2) 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</li>
        <li>3) 공공기관이 법령 등에서 정하는 소관 업무의 수행을 위하여 불가피한 경우</li>
        <li>4) 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우</li>
        <li>5) 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로써 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우</li>
      </ul>
    </li>
    <li>
      나. 노인생활과학연구소 대표홈페이지는 개인정보를 제3자에게 제공하는 경우 다음의 항목을 정보주체에게 알린 후 동의를 받겠습니다.
      <ul>
        <li>1) 필수항목 : 이름, 이메일, 휴대폰번호</li>
        <li>2) 선택항목 : 주소, 유선전화</li>
      </ul>
    </li>
    <li>
      다. 인터넷 서비스 이용과정에서 자동으로 생성되어 수집하는 개인정보의 항목
      <ul>
        <li>1) 제공받는 자의 성명(법인 또는 단체인 경우에는 그 명칭)과 연락처</li>
        <li>2) 제공받는 자의 개인정보 이용 목적</li>
        <li>3) 제공하는 개인정보의 항목</li>
        <li>4) 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간</li>
        <li>5) 동의를 거부할 권리가 있다는 사실 및 동의 거부에 따른 불이익이 있는 경우에는 그 불이익의 내용</li>
      </ul>
    </li>
  </ul>
</div>






<div class="service">
  <strong>5. 개인정보처리의 위탁에 관한 사항</strong>
  <p>
    노인생활과학연구소 대표홈페이지는 원활한 업무처리를 위하여 개인정보 처리업무를 위탁하고 있습니다. 위탁계약 체결 시 개인정보 보호법 제 26조에 따라 관련 내용이 담긴 문서에 의하여 하고 있으며, 위탁업무의 내용과
    수탁자를 홈페이지에 게시합니다.
  </p>
  <table class="tbc">
    <caption></caption>
    <thead>
      <tr>
        <th>수탁업체</th>
        <th>위탁항목</th>
        <th>위탁목적</th>
        <th>위탁기간</th>
        <th>연락처</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>㈜에스지씨앤씨</td>
        <td>홈페이지 회원정보</td>
        <td>홈페이지 관리</td>
        <td>2019.8.1. ~ 2020.8.30.</td>
        <td>051-751-5084</td>
      </tr>
    </tbody>
  </table>
</div>











<div class="service">
  <strong>6.정보주체와 법정대리인의 권리·의무 및 행사방법</strong>
  <p>정보주체는 노인생활과학연구소 대표홈페이지에 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</p>
  <ul>
    <li>
      가. 노인생활과학연구소 대표홈페이지는 에서 수집․보유하고 있는 개인정보는 이용자의 동의 없이는 제3자에게 제공하지 않으며, 다음의 경우에는 개인정보를 제3자에게 제공할 수 있습니다.
      <ul>
        <li>가. 개인정보 보호법 시행령 제 41조 1항에 서면, 전자우편, 모사전송(FAX) 등을 통하여 권리 행사를 하실 수 있습니다.</li>
        <li>나. 정보주체의 법정대리인이나 위임을 받을 자 등 대리인을 통해 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 [별지 제11호 서식 ‘위임장’]을 제출하셔야 합니다.</li>
        <li>다. 개인정보 열람 및 처리정지 요구는 개인정보 보호법 제 35조 제 5항, 제 37조 제 2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</li>
        <li>라. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 삭제 요구를 할 수 없습니다.</li>
        <li>마. 노인생활과학연구소는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구한 자가 본인 이거나 정당한 대리인인지를 확인합니다.</li>
      </ul>
    </li>
  </ul>
</div>





<div class="service">
  <strong>7. 개인정보 파기에 관한 사항</strong>
  <p>노인생활과학연구소 대표홈페이지는 정보주체의 개인정보를 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때, 지체 없이 해당 개인정보를 파기합니다. 정보주체로부터 동의받은 개인정보의 보유기간이
    경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보(또는 개인정보파일)을 별도의 데이터베이스로 옮기거나 보관 장소를 달리하여 보존합니다.
  </p>
  <ul>
    <li>
      가. 파기절차
      <ul>
        <li>노인생활과학연구소는 파기하여야 하는 개인정보(또는 개인정보파일)에 대해 개인정보 파기계획을 수립하여 파기합니다. 노인생활과학연구소는 파기사유가 발생한 개인정보(또는 개인정보파일)을 선정하고, 개인정보
          보호책임자의 승인을 받아 개인정보(또는 개인정보파일)을 파기합니다.</li>
      </ul>
    </li>
    <li>
      나. 파기방법
      <ul>
        <li>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</li>
        <li>전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</li>
      </ul>
    </li>
  </ul>
</div>





<div class="service">
  <strong>8. 개인정보의 안전성 확보조치에 관한 사항</strong>
  <p>노인생활과학연구소 대표홈페이지는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
  <ul>
    <li>가. 관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육 등</li>
    <li>나. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 개인정보의 암호화, 보안프로그램 설치 등</li>
    <li>다. 물리적 조치 : 개인정보처리시스템 접근통제 등</li>
  </ul>
</div>




<div class="service">
  <strong>9. 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항</strong>
  <p>
    노인생활과학연구소 대표홈페이지는 홈페이지 활용도 측정 등을 이유로 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터
    브라우저에게 보내는 소량의 정보이며 이용자의 PC 내의 하드디스크에 저장되기도 합니다.
  </p>
  <ul>
    <li>가. 쿠키의 사용목적 : 이용자가 방문한 각 서비스와 웹사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부 등을 파악하여 홈페이지 활용도 측정 등을 위해 사용됩니다.</li>
    <li>나. 쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구 > 인터넷옵션 > 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</li>
  </ul>
</div>



<div class="service">
  <strong>10. 권익침해 구제방법</strong>
  <p>정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 다만 노인생활과학연구소와는 별개의 기관으로서, 노인생활과학연구소의 자체적인 피해구제 결과에 만족하지 못하시거나
    2차적으로 보다 자세한 도움이 필요하시다면 문의할 수 있습니다.</p>
  <ul>
    <li>개인정보 침해신고센터(한국인터넷진흥원) : (국번없이) 118 (privacy.kisa.or.kr)</li>
    <li>개인정보 분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</li>
    <li>대검찰청 사이버범죄수사단 : 02-3480-2000 (www.spo.go.kr)</li>
    <li>경찰청 사이버안전국 : (국번없이) 182 (cyberbureau.police.go.kr)</li>
  </ul>
</div>





<div class="service">
  <strong>11. 개인정보 보호책임자에 관한 사항</strong>
  <p>노인생활과학연구소는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
  <ul class="bg">
    <li>개인정보 보호책임자 : 대표/소장 한동희</li>
    <li>개인정보 보호담당자 : 박지혜</li>
    <li>전화번호 : 051-624-5025, 팩스 : 051-751-5025</li>
    <li>이 메 일 : wellageing12@gmail.com</li>
  </ul>
</div>