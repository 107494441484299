<!-- 사이드 바 네비게이션 시작 -->
<div class="sidebar-title" [attr.data-title]="parentTitle">
  {{parentTitle}}
</div>
<div class="snb">
  <ul>
    <ng-container *ngFor="let twoMenu of subMenus;" >
    <li [ngClass]="menu.id == twoMenu.id || menu.upperId == twoMenu.id ? 'active' : ''" >
      <a *ngIf="twoMenu.isAnonymous == 'Y' || (twoMenu.isAnonymous == 'N' && user) || (twoMenu.isAnonymous == 'S' && !user)" (click)="clickMenu(twoMenu)"><span>{{twoMenu.name}}</span></a>
      <ul>
        <ng-container *ngFor="let thirdMenu of twoMenu.subMenus;" >
        <li [ngClass]="thirdMenu.id == menu.id ? 'active' : ''" >
          <button *ngIf="thirdMenu.menuType == 'F'"><span>{{subMenu.name}}</span></button>
          <a *ngIf="thirdMenu.menuType != 'F' && thirdMenu.target != 'N'" href="javascript:; " (click)="clickMenu(thirdMenu)"><span>{{thirdMenu.name}}</span></a>
        </li>
        </ng-container>
      </ul>
    </li>
    </ng-container>
  </ul>
</div>
<!-- 사이드 바 네비게이션 끝 -->